const globalMixin = {
  methods: {
    cleanSliderValue: function (e) {
			var returnVal = e.target.value;
			if (returnVal == "") {
				returnVal = 0;
			} else if (returnVal > 100) {
				returnVal = 100;
			} else if (returnVal < 0) {
				returnVal = 0;
			}
			e.target.value = parseInt(returnVal);
			return returnVal;
		},
		cleanNumberValue: function (val) {
			var returnVal = val;
			if (returnVal == "") {
				returnVal = 0;
			}	else if (returnVal < 0) {
				returnVal = 0;
			}
			return parseInt(returnVal);
		}
  }
}
export default globalMixin;