<template>
    <div class="header">
        <div class="logo-container">
					<div class="logo">
						<img class="icon" src="@/assets/logo.svg" @click="logoClick"/>
						<img width="182" height="22" src="@/assets/kodak_label.svg" />
					</div>
        </div>
        <div class="nav-container" v-bind:class="{results:(stepNum == 0 || stepNum > 10)}">
					<div v-if="stepNum == 0">
						<h1><b>Kodak Lens Profitability Impact Calculator</b></h1>
					</div>
					<div v-else-if="stepNum < 11">
            <h1><b>Kodak Lens Profitability Impact Calculator:</b> Inputs</h1>
						<div class="progress-bar">
							<ProgressStep :stepNum=stepNum :thisStep="1" :stepTitle=stepTitle />
							<ProgressStep :stepNum=stepNum :thisStep="2" :stepTitle=stepTitle />
							<ProgressStep :stepNum=stepNum :thisStep="3" :stepTitle=stepTitle />
							<ProgressStep :stepNum=stepNum :thisStep="4" :stepTitle=stepTitle />
							<ProgressStep :stepNum=stepNum :thisStep="5" :stepTitle=stepTitle />
							<ProgressStep :stepNum=stepNum :thisStep="6" :stepTitle=stepTitle />
							<ProgressStep :stepNum=stepNum :thisStep="7" :stepTitle=stepTitle />
							<ProgressStep :stepNum=stepNum :thisStep="8" :stepTitle=stepTitle />
							<ProgressStep :stepNum=stepNum :thisStep="9" :stepTitle=stepTitle />
							<ProgressStep :stepNum=stepNum :thisStep="10" :stepTitle=stepTitle isEnd />
						</div>
					</div>
					<div v-else-if="stepNum == 11">
            <h1><b>Kodak Lens Profitability Impact Calculator:</b> Results</h1>
						<div class="subheader">View your current Managed Vision Care business breakdown below</div>
					</div>
					<div v-else-if="stepNum == 12">
            <h1><b>Kodak Lens Profitability Impact Calculator:</b> Summary</h1>
						<div class="subheader">View a summary of your results below</div>
					</div>
					<div v-else-if="stepNum == 13">
						<h1><b>Kodak Lens Profitability Impact Calculator</b></h1>
						<div class="subheader">{{$store.state.inputs.practice_name}}</div>
					</div>
        </div>
    </div>
</template>

<script>
import ProgressStep from './ProgressStep.vue'

export default {
    name: 'Header',
    components: {
      ProgressStep
    },
    props: {
		stepNum: Number,
		stepTitle: String
    },
		methods: {
			logoClick: function () {
				if (this.stepNum > 0) {
					this.$parent.$parent.showReset = true
				}
			}
		}
}
</script>

<style scoped>
	.header {
		width: 100%;
		margin: 0 auto;
	}
	.logo-container {
		width: 100%;
		height: 80px;
		position: relative;
	}
	.logo-container::after {
		display: block;
		content: "\00a0";
		width: 100%;
		height: 5px;
		position: relative;
		bottom: 0;
		background: url(../assets/header_gradient.png) no-repeat center center;
		background-size: cover;
	}
	.logo {
		width: 1024px;
		margin: 0 auto;
		display: flex;
		align-items: center;
	}
	.logo img {
		display: inline-block;
		margin-right: 20px;
	}
	.icon {
		cursor: pointer;
	}
	.nav-container {
		width: 100%;
		background-color: black;
		color: white;
		height: 180px;
		text-align: center;
	}
	.nav-container.results {
		height: 150px;
	}
	h1 {
		font-weight: normal;
		font-size: 36px;
		margin: 0 0 10px 0;
		padding: 40px 0 0 0;
	}
	.subheader {
		color: white;
	}
	.progress-bar {
		display: flex;
		justify-content: center;
		margin-top: 25px;
	}
</style>
