<template>
	<div class="step">
		<Header 
			:stepNum="11"
		/>
		<div class="main-content">
			<ResultsTable :expanded="false"/>
			<GrandSummaryTable />
			<div class="note">The numbers above are estimates based off of the inputs from the previous questions. This may not be a 100% accurate depiction of current or potential profitability. The information on this page is confidential and is not intended to be shared or distributed.</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import sectionLabelling from '@/mixins/sectionLabelling.js'
import ResultsTable from '@/components/ResultsTable.vue'
import GrandSummaryTable from '@/components/GrandSummaryTable.vue'

export default {
	name: 'Step11',
	extends: sectionLabelling,
	components: {
			Header,
			ResultsTable,
			GrandSummaryTable
	},
	mounted: function () {
		window.scrollTo(0, 0)
	},
	beforeUnmount: function() {
		this.$store.commit('setJobNumbersChanged',false);
	}
}
</script>

<style scoped>
.main-content {
	width: 100%;
}
.note {
	width: 1016px;
	position: static;
	margin: 30px 0;
}
</style>
