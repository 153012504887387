<template>
  <div class="step">
    <Header 
      :stepNum="7"
      stepTitle="<b>Q7:</b> Spectera"
    />
    <div class="main-content">
      <h2>What is your Spectera reimbursement percentage?</h2>
      <div class="input-headers">
              <div class="pct-header">Percentage (%)</div>
      </div>
      <div class="input-group slider end">
          <RangeInput v-model.number="inputs.profit_pct_spectera" />
          <div class="number-input"><input v-model.number.lazy="inputs.profit_pct_spectera" @input="cleanSliderValue($event)" type="number" min="0" max="100" step="5"/></div>
      </div>
    </div>
    <div class="note"><span>Refer to the <b><i>Exhibit C Fee Schedule</i></b> in your Spectera contract for your specific reimbursement amount. The average ECP reimbursement for Spectera providers is 10% of the member out of pocket cost.</span></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import RangeInput from '@/components/RangeInput.vue'
import {mapState} from 'vuex'

export default {
  name: 'Step7',
  components: {
      Header,
      RangeInput
  },
  computed: {
      ...mapState(['inputs'])
  },
  mounted: function () {
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
	.input-headers::before {
		content: "\00a0";
		width: 674px;
	}
  .main-content {
    padding-bottom: 150px;
  }
  .note {
    width: 1016px;
  }
</style>