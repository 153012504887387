<template>
  <router-view />
</template>

<script>
import {version} from '@/../package'

export default {
  data: function() {
			return {
          appVersion:version
			}
	},
  created: function() {
    document.title = "Kodak Lens Profitability Impact Calculator";
    console.log("App version: " + this.appVersion);
  }
}
</script>
