<template>
	<div class="step">
		<Header 
		:stepNum="3"
		stepTitle="<b>Q3:</b> Managed Vision Care Breakdown"
		/>
		<div class="main-content">
			<h2>What percentage of your Managed Vision Care business is:</h2>
			<div class="input-headers">
				<div class="pct-header">Percentage (%)</div>
				<div class="lock-header">Lock Value?</div>
			</div>
			<div class="input-group slider">
				<div class="label">VSP</div>
				<RangeInput v-model.number="inputs.business_pct_mvc.vsp" @input="rebalanceInputs('vsp','lockVspPct',$event)"/>
				<div class="number-input"><input v-model.number.lazy="inputs.business_pct_mvc.vsp" type="number" min="0" max="100" step="5" @input="cleanSliderValue($event)" @change="rebalanceInputs('vsp','lockVspPct',$event)"/></div>
				<LockInput v-model="inputs.pct_is_locked.vsp" thisId="vspCheckbox" />
			</div>
			<div class="input-group slider">
				<div class="label">EyeMed</div>
				<RangeInput v-model.number="inputs.business_pct_mvc.eyemed" @input="rebalanceInputs('eyemed','lockEyemedPct',$event)"/>
				<div class="number-input"><input v-model.number.lazy="inputs.business_pct_mvc.eyemed" type="number" min="0" max="100" step="5" @input="cleanSliderValue($event)" @change="rebalanceInputs('eyemed','lockEyemedPct',$event)"/></div>
				<LockInput v-model="inputs.pct_is_locked.eyemed" thisId="eyemedCheckbox" />
			</div>
			<div class="input-group slider">
				<div class="label">Versant <span class="sublabel">(Davis Vision/Superior Vision)</span></div>
				<RangeInput v-model.number="inputs.business_pct_mvc.davis" @input="rebalanceInputs('versant','lockVersantPct',$event)"/>
				<div class="number-input"><input v-model.number.lazy="inputs.business_pct_mvc.davis" type="number" min="0" max="100" step="5" @input="cleanSliderValue($event)" @change="rebalanceInputs('versant','lockVersantPct',$event)"/></div>
				<LockInput v-model="inputs.pct_is_locked.davis" thisId="davisCheckbox" />
			</div>
			<div class="input-group slider">
				<div class="label">Spectera</div>
				<RangeInput v-model.number="inputs.business_pct_mvc.spectera" @input="rebalanceInputs('spectera','lockSpecteraPct',$event)"/>
				<div class="number-input"><input v-model.number.lazy="inputs.business_pct_mvc.spectera" type="number" min="0" max="100" step="5" @input="cleanSliderValue($event)" @change="rebalanceInputs('spectera','lockSpecteraPct',$event)"/></div>
				<LockInput v-model="inputs.pct_is_locked.spectera" thisId="specteraCheckbox" />
			</div>
			<div class="input-group slider end">
				<div class="label">Other</div>
				<RangeInput v-model.number="inputs.business_pct_mvc.other" @input="rebalanceInputs('other','lockOtherPct',$event)"/>
				<div class="number-input"><input v-model.number.lazy="inputs.business_pct_mvc.other" type="number" min="0" max="100" step="5" @input="cleanSliderValue($event)" @change="rebalanceInputs('other','lockOtherPct',$event)"/></div>
				<LockInput v-model="inputs.pct_is_locked.other" thisId="otherCheckbox" />
			</div>
		</div>
		<div class="note">The default percentages on this specific page are not indicative of market averages. Please adjust the values according to your practice's specific Managed Care business.</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import RangeInput from '@/components/RangeInput.vue'
import LockInput from '@/components/LockInput.vue'
import {mapState} from 'vuex'

export default {
	name: 'Step3',
	components: {
		Header,
		RangeInput,
		LockInput
	},
	computed: {
			...mapState(['inputs'])
	},
	data: function() {
			return {
					arrayOfInputs: []
			}
	},
	methods: {
		rebalanceInputs: function(brandEdited,lockMethod,e) {
			this.$store.commit(lockMethod);
			var vspVal = {
				'val' : this.inputs.business_pct_mvc.vsp
			}
			var eyemedVal = {
				'val' : this.inputs.business_pct_mvc.eyemed
			}
			var versantVal = {
				'val' : this.inputs.business_pct_mvc.davis
			}
			var specteraVal = {
				'val' : this.inputs.business_pct_mvc.spectera
			}
			var otherVal = {
				'val' : this.inputs.business_pct_mvc.other
			}
			var overage = {
				'val' : 0
			}
			var valueTotal = (vspVal.val + eyemedVal.val + versantVal.val + specteraVal.val + otherVal.val);
			overage.val = valueTotal - 100;
			this.rebalanceInput('vsp',brandEdited,this.inputs.business_pct_mvc.vsp,this.inputs.pct_is_locked.vsp,vspVal,overage);
			this.rebalanceInput('eyemed',brandEdited,this.inputs.business_pct_mvc.eyemed,this.inputs.pct_is_locked.eyemed,eyemedVal,overage);
			this.rebalanceInput('versant',brandEdited,this.inputs.business_pct_mvc.davis,this.inputs.pct_is_locked.davis,versantVal,overage);
			this.rebalanceInput('spectera',brandEdited,this.inputs.business_pct_mvc.spectera,this.inputs.pct_is_locked.spectera,specteraVal,overage);
			this.rebalanceInput('other',brandEdited,this.inputs.business_pct_mvc.other,this.inputs.pct_is_locked.other,otherVal,overage);
			this.$store.commit('rebalanceMvcPct',[vspVal.val,eyemedVal.val,versantVal.val,specteraVal.val,otherVal.val]);
			this.rebalanceSelf('vsp',brandEdited,vspVal,overage,e);
			this.rebalanceSelf('eyemed',brandEdited,eyemedVal,overage,e);
			this.rebalanceSelf('versant',brandEdited,versantVal,overage,e);
			this.rebalanceSelf('spectera',brandEdited,specteraVal,overage,e);
			this.rebalanceSelf('other',brandEdited,otherVal,overage,e);
			this.$store.commit('rebalanceMvcPct',[vspVal.val,eyemedVal.val,versantVal.val,specteraVal.val,otherVal.val]);
		},
		rebalanceInput: function(tgtBrand,brandEdited,brandPct,brandLock,valRef,overageRef) {
			// Rebalance this slider only if it wasn't the one edited
			if (brandEdited != tgtBrand) {
				if (!brandLock && overageRef.val  > 0) {
					if (brandPct > overageRef.val ) {
						valRef.val = brandPct - overageRef.val ;
						overageRef.val = 0;
					} else {
						valRef.val = 0;
						overageRef.val -= brandPct;
					}
				} else if (!brandLock && overageRef.val < 0) {
					if (brandPct < 100 + overageRef.val) {
						valRef.val = brandPct - overageRef.val;
						overageRef.val = 0;
					} else {
						valRef.val = 100;
						overageRef.val -= brandPct - 100
					}
				}
			}
		},
		rebalanceSelf: function (tgtBrand,brandEdited,valRef,overageRef,e) {
			if (brandEdited == tgtBrand){
				if (((overageRef.val > 0) && 
					(this.inputs.pct_is_locked.vsp || this.inputs.business_pct_mvc.vsp == 0) &&
					(this.inputs.pct_is_locked.eyemed || this.inputs.business_pct_mvc.eyemed == 0) &&
					(this.inputs.pct_is_locked.davis || this.inputs.business_pct_mvc.davis == 0) &&
					(this.inputs.pct_is_locked.spectera || this.inputs.business_pct_mvc.spectera == 0) &&
					(this.inputs.pct_is_locked.other || this.inputs.business_pct_mvc.other == 0))
					|| ((overageRef.val < 0) && 
					(this.inputs.pct_is_locked.vsp || this.inputs.business_pct_mvc.vsp == 100) &&
					(this.inputs.pct_is_locked.eyemed || this.inputs.business_pct_mvc.eyemed == 100) &&
					(this.inputs.pct_is_locked.davis || this.inputs.business_pct_mvc.davis == 100) &&
					(this.inputs.pct_is_locked.spectera || this.inputs.business_pct_mvc.spectera == 100) &&
					(this.inputs.pct_is_locked.other || this.inputs.business_pct_mvc.other == 100))) {
						valRef.val -= overageRef.val;
						e.target.value = valRef.val;
						overageRef.val = 0;
				}
			}
		}
	},
  mounted: function () {
    window.scrollTo(0, 0)
  },
  watch: {
    '$store.state.inputs.business_pct_mvc.vsp': function() {
      this.$store.commit('setDefaultVspPalJobs');
      this.$store.commit('setDefaultVspNGJobs');
      this.$store.commit('setJobNumbersChanged',true);
    },
    '$store.state.inputs.business_pct_mvc.eyemed': function() {
      this.$store.commit('setDefaultEyemedPalJobs');
      this.$store.commit('setDefaultEyemedNGJobs');
      this.$store.commit('setJobNumbersChanged',true);
    },
    '$store.state.inputs.business_pct_mvc.davis': function() {
      this.$store.commit('setDefaultVersantPalJobs');
      this.$store.commit('setDefaultVersantNGJobs');
      this.$store.commit('setJobNumbersChanged',true);
    },
    '$store.state.inputs.business_pct_mvc.spectera': function() {
      this.$store.commit('setDefaultSpecteraPalJobs');
      this.$store.commit('setDefaultSpecteraNGJobs');
      this.$store.commit('setJobNumbersChanged',true);
    }
  }
}
</script>

<style scoped>
  .main-content {
    padding-bottom: 150px;
  }
	.input-group {
		width: 826px;
	}
	.input-headers::before {
		content: "\00a0";
		width: 712px;
	}
	.pct-header {
		margin-right: 25px;
	}
	.sublabel {
		font-size: 12px;
		display: inline-block;
	}
  .note {
    width: 844px;
  }
</style>