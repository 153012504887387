<template>
  <!-- Grand Summary -->
  <div class="grand-summary-section">
    <div class="grand-summary-content">
      <div class="header">
        Grand Total For All MVC
      </div>
      <div class="totals">
        <div class="monthly">
          <div class="label">Increase in Profitability<br/>/ month ($)</div>
          <input class="dollar" type="text" :value="$store.getters.grandMonthlyIncrease.toLocaleString()" readonly/>
        </div>
        <div class="yearly">
          <div class="label">Increase in Profitability<br/>/ year ($)</div>
          <input class="dollar" type="text" :value="$store.getters.grandYearlyIncrease.toLocaleString()" readonly/>
        </div>
      </div>
    </div>
    <div class="watermark" v-if="watermarked">{{$store.state.user_email}}</div>
  </div>
</template>

<script>

export default {
	name: 'GrandSummaryTable',
  props: {
    watermarked: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style scoped>
.watermark {
	font-size: 50px;
	font-weight: bold;
	position: absolute;
	margin: 0 auto;
	color: #fff;
	pointer-events: none;
	opacity: 0.2;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(-45deg);
	-moz-transform: translate(-50%, -50%) rotate(-45deg);
}
.grand-summary-section {
	padding-top: 30px;
	margin-top: 25px;
	border-top: 2px solid #E4E4E4;
	width: 100%;;
	display: flex;
	justify-content: center;
	position: relative;
	overflow: hidden;
}
.grand-summary-content {
	width: 1016px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	border-radius: 5px;
	height: 154px;
	align-items: center;
	margin: 20px 0;
	box-shadow: 0px 3px 6px #00000029;
	box-sizing: border-box;
	background-color: #FFB000;
}
.grand-summary-content .header {
	color: white;
	font-size: 18px;
	font-weight: bold;
	width: 100%;
	height: 75px;
	background-color: black;
	outline:5px solid #333333;
	outline-offset: -5px;
	border-radius: 5px 5px 0 0;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 11px;
	box-sizing: border-box;
	position: relative;
}
.grand-summary-content .header::after {
	content: "\00a0";
	width: 100%;
	height: 5px;
	position: absolute;
	bottom: 0;
	background: url(../assets/header_gradient.png) no-repeat center center;
	background-size: cover;
}
.grand-summary-content .totals {
	display: flex;
	flex-direction: row;
}
.grand-summary-content .label {
	color: white;
	font-size: 18px;
	font-weight: bold;
	text-align: right;
	margin-right: 10px;
}
.grand-summary-content input[type=text].dollar{
	width: 164px;
	height: 58px;
	border: 4px solid #FFC93E;
	border-radius: 5px;
	font-weight: bold;
  margin: 0 10px;
}
.grand-summary-content .monthly,.grand-summary-content .yearly {
	display: flex;
	align-items: center;
	margin: 0 25px;
}
</style>
