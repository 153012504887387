<template>
  <div>
    <Step1 v-if="currentStep == 1"/>
    <Step2 v-if="currentStep == 2"/>
    <Step3 v-if="currentStep == 3"/>
    <Step4 v-if="currentStep == 4"/>
    <Step5 v-if="currentStep == 5"/>
    <Step6 v-if="currentStep == 6"/>
    <Step7 v-if="currentStep == 7"/>
    <Step8 v-if="currentStep == 8"/>
    <Step9 v-if="currentStep == 9"/>
    <Step10 v-if="currentStep == 10"/>
    <Step11 v-if="currentStep == 11"/>
    <Step12 v-if="currentStep == 12"/>
    <DownloadPDF ref="pdfDownloader" v-if="currentStep == 12"/>
    <div class="nav-button-container">
        <div class="inner-container">
            <button class="prev" v-on:click="prevPage" v-if="currentStep > 1 && currentStep <= 12">PREV STEP</button>
            <button class="next" v-on:click="nextPage" v-if="currentStep <= 12">{{nextBtnLabel}}</button>
            <!--<button class='download' v-if="currentStep == 12" :disabled="downloading" @click="downloadPDF"><span v-if="!downloading">DOWNLOAD AS PDF</span><span v-if="downloading">DOWNLOADING...</span> <img class="spinner" src="@/assets/loading.svg" /></button>-->
            <button class="top-button" v-on:click="toTop" v-if="currentStep == 8 || currentStep == 9 || currentStep == 11 || currentStep == 12">BACK TO TOP</button>
        </div>
    </div>
    <div class="popup" v-if="showReset">
      <div class="panel">
        <div class="header">Start Again?</div>
        <div class="message">Are you sure you want to start again? All inputs will be lost if you start over</div>
        <div class="button-container">
          <button class="cancel" @click="(showReset = false)">CANCEL</button>
          <button class="confirm" @click="resetInputs">I'M SURE</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import defaultInputs from '@/lib/defaultInputs.js'
import rebateCalculations from '@/lib/rebateCalculations.js'
import DownloadPDF from '@/components/DownloadPDF.vue'
import Step1 from '@/views/Step1.vue'
import Step2 from '@/views/Step2.vue'
import Step3 from '@/views/Step3.vue'
import Step4 from '@/views/Step4.vue'
import Step5 from '@/views/Step5.vue'
import Step6 from '@/views/Step6.vue'
import Step7 from '@/views/Step7.vue'
import Step8 from '@/views/Step8.vue'
import Step9 from '@/views/Step9.vue'
import Step10 from '@/views/Step10.vue'
import Step11 from '@/views/Step11.vue'
import Step12 from '@/views/Step12.vue'

export default {
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
    Step8,
    Step9,
    Step10,
    Step11,
    Step12,
    DownloadPDF
  },
  data: function() {
			return {
					currentStep: 1,
          showReset: false,
          downloading: false
			}
	},
  computed: {
    ...mapState(['inputs']),
    nextBtnLabel () {
      var label = "NEXT STEP";
      if (this.currentStep == 10) {
        label = "FINISH";
      } else if (this.currentStep == 11) {
        label = "VIEW SUMMARY";
      } else if (this.currentStep == 12) {
        label = "START AGAIN";
      }
      return label;
    }
  },
  created: function() {
    this.resetInputs();
  },
  methods: {
		toTop: function() {
			window.scrollTo(0, 0);
		},
    downloadPDF: function() {
      this.$refs.pdfDownloader.downloadPDF();
    },
    resetInputs: function () {
      this.$store.commit('restoreInputs',JSON.parse(JSON.stringify(defaultInputs)))
      this.$store.commit('setRebatesNoGroup',rebateCalculations)
      this.$store.commit('setDefaultVspPalJobs');
      this.$store.commit('setDefaultVspNGJobs');
      this.$store.commit('setDefaultEyemedPalJobs');
      this.$store.commit('setDefaultEyemedNGJobs');
      this.$store.commit('setDefaultVersantPalJobs');
      this.$store.commit('setDefaultVersantNGJobs');
      this.$store.commit('setDefaultSpecteraPalJobs');
      this.$store.commit('setDefaultSpecteraNGJobs');
      this.currentStep = 1;
      this.showReset = false;
    },
    nextPage: function() {
      if (this.currentStep == 12) {
        this.showReset = true;
      } else {
        this.currentStep++
      }
      if (this.currentStep == 4 && this.$store.state.inputs.business_pct_mvc.vsp <= 0) {
        this.currentStep++
      }
      if (this.currentStep == 5 && this.$store.state.inputs.business_pct_mvc.eyemed <= 0) {
        this.currentStep++
      }
      if (this.currentStep == 6 && this.$store.state.inputs.business_pct_mvc.davis <= 0) {
        this.currentStep++
      }
      if (this.currentStep == 7 && this.$store.state.inputs.business_pct_mvc.spectera <= 0) {
        this.currentStep++
      }
    },
    prevPage: function() {
      this.currentStep--
      if (this.currentStep == 7 && this.$store.state.inputs.business_pct_mvc.spectera <= 0) {
        this.currentStep--
      }
      if (this.currentStep == 6 && this.$store.state.inputs.business_pct_mvc.davis <= 0) {
        this.currentStep--
      }
      if (this.currentStep == 5 && this.$store.state.inputs.business_pct_mvc.eyemed <= 0) {
        this.currentStep--
      }
      if (this.currentStep == 4 && this.$store.state.inputs.business_pct_mvc.vsp <= 0) {
        this.currentStep--
      }
    }
  }
}
</script>

<style scoped>
  .download {
    color: white;
    font-size: 12px;
    background-color: #ED0000;
    min-width: 120px;
    padding: 0 25px;
    height: 40px;
    border-radius: 2px;
    text-decoration: none;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin: 0 10px;
    border: none;
    cursor: pointer;
  }
  .download > * {
    pointer-events: none;
  }
  .download .spinner {
    width: 20px;
    margin-left: 10px;
    -webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite;
    animation:spin 1s linear infinite;
    display: none;
  }
  @-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
  }
  @-webkit-keyframes spin { 
      100% { -webkit-transform: rotate(360deg); } 
  }
  @keyframes spin { 
      100% { 
          -webkit-transform: rotate(360deg); 
          transform:rotate(360deg); 
      } 
  }
  .download:disabled {
    pointer-events: none;
    background-color: #be0000;
  }
  .download:disabled .spinner {
    display: block;  
  }
  .download:hover {
    background-color: #be0000;
  }
</style>