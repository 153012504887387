<template>
  <div class="total-section">
    <div class="categories"></div>
    <div class="current"><div class="subheaders">
        <div class="subhead smaller">Total Jobs</div>
        <div class="subhead"></div>
      </div>
      <div class="total-inputs">
        <input type="number" class="smaller" :value="getCurrentTotalJobs" readonly />
        <input type="number" class="filler" />
      </div></div>
    <div class="new">
      <div class="subheaders">
        <div class="subhead">Total Jobs</div>
        <div class="subhead"><span v-if="newTotalProfits!=null">Total ($)</span></div>
        <div class="subhead"><span v-if="newTotalRebates!=null">Total ($)</span></div>
      </div>
      <div class="total-inputs">
        <input type="number" :value="getNewTotalJobs" readonly />
        <input class="dollar" type="text" :value="toLocaleStringIfExist(newTotalProfits)" v-bind:class="{filler:newTotalProfits==null}" readonly />
        <input class="dollar" type="text" :value="toLocaleStringIfExist(newTotalRebates)" v-bind:class="{filler:newTotalRebates==null}" readonly />
      </div>
    </div>
  </div>
</template>

<script>

export default {
	name: 'ResultsTotalsSection',
	props: {'currentTotalJobs':Array,'newTotalJobs':Array,'jobPct':Number,'newTotalProfits':Number,'newTotalRebates':Number},
	computed: {
		getCurrentTotalJobs () {
			var total = 0;
			for (var i = 0; i < this.currentTotalJobs.length; i++) {
				var addition = Math.ceil(this.currentTotalJobs[i] * this.jobPct/100);
				total += (addition != "") ? addition : 0;
			}
			return total;
		},
		getNewTotalJobs () {
			var total = 0;
			for (var i = 0; i < this.newTotalJobs.length; i++) {
				var addition = this.newTotalJobs[i];
				total += (addition != "") ? parseInt(addition) : 0;
			}
			return total;
		}
	},
	methods: {
		toLocaleStringIfExist: function (string) {
			var returnString = null;
			if (string != null) {
				returnString = string.toLocaleString()
			}
			return returnString;
		}
	}
}
</script>

<style scoped>
.total-section {
	display: flex;
	align-items: center;
	height: 109px;
}
.total-section .current, .total-section .new {
	background-color: #F7F7F7;
	flex-direction: column;
	justify-content: flex-start;
}
.total-section .subheaders {
	background-color: #E4E4E4;
	height: 39px;
	width: 100%;
	font-weight: bold;
	font-size: 12px;
	text-align: center;
	display: flex;
	justify-content: center;
	margin-bottom: 10px;
}
</style>