<template>
		<div class="lock-input">
			<input :ref="thisId" :id="thisId" :checked="modelValue" @change="this.doInput"  type="checkbox"/><label :for="thisId"><span class="yes">YES</span><span class="no">NO</span></label>
		</div>
</template>

<script>

export default {
    name: 'LockInput',
    props: ['modelValue','thisId'],
		emits: ['update:modelValue'],
		methods: {
			doInput: function(e) {
				this.$emit('update:modelValue', e.target.checked);
			}
		}
}
</script>

<style scoped>	
	input[type=checkbox] {
		display: none;
	}
	input[type=checkbox] + label {
		content: "NO";
		color: black;
		width: 80px;
		height: 40px;
		background-color: #E4E4E4;
		display: flex;
		font-size: 10px;
		font-weight: bold;
		transition: background-color 0.15s ease-in-out;
		justify-content: center;
		align-items: center;
		position: relative;
		cursor: pointer;
		border: 2px solid #DBDBDB;
		border-radius: 20px;
		box-sizing: border-box;
	}
	input[type=checkbox] + label:hover {
		border-color: #c7c7c7;
	}
	input[type=checkbox] + label::after {
		content: "\00a0";
		display: block;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		background-color: white;
		position: absolute;
		right: auto;
		left: 2px;
	}
	input[type=checkbox]:checked + label::after {
		left: auto;
		right: 2px;
	}
	input[type=checkbox] + label .yes {
		opacity: 0;
		position: absolute;
		left: 15px;
		transition: opacity 0.15s ease-in-out;
	}
	input[type=checkbox] + label .no {
		opacity: 1;
		position: absolute;
		right: 15px;
		transition: opacity 0.15s ease-in-out;
	}
	input[type=checkbox]:checked + label {
		content: "YES";
		color: white;
		background-color: #ED0000;
		border-color: #CC0000;
	}
	input[type=checkbox]:checked + label:hover {
		background-color: #CC0000;
	}
	input[type=checkbox]:checked + label .yes {
		opacity: 1;
	}
	input[type=checkbox]:checked + label .no {
		opacity: 0;
	}
</style>
