<template>
    <div class="range-input">
        <div class="range-slider">
            <input ref="input" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" type="range" min="0" max="100" step="5"/>
            <div ref="progress" class="progress"></div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'RangeInput',
    props: ['modelValue'],
		emits: ['update:modelValue'],
		methods: {
			adjustRange: function() {
				this.$refs.progress.style.width = this.modelValue/this.$refs.input.max*100 + "%";
			}
		},
		watch: { 
			modelValue: function() {
				this.adjustRange();
			}
		},
		mounted: function() {
			this.adjustRange();
		}
}
</script>

<style scoped>	
	.range-input {
		margin-right: 30px;
		height: 34px;
		width: 396px;
		text-align: center;
		position: relative;
		display: flex;
		align-items: flex-start;
	}
	.range-input::before {
		content: "0%";
		font-size: 12px;
		font-weight: bold;
		position: absolute;
		width: 36px;
		left: 0;
		bottom: 0;
	}
	.range-input::after {
		content: "100%";
		font-size: 12px;
		font-weight: bold;
		position: absolute;
		width: 36px;
		right: 0;
		bottom: 0;
	}
	.range-slider {
		position: relative;
		width: 360px;
		margin: 0 auto;
	}
	input[type=range] {
		width: 100%;
		display: inline-block;
		-webkit-appearance: none;
		position: absolute;
		cursor: pointer;
		left: 0;
		top: 0;
		margin: 0;
		border: none;
		padding-bottom: 5px;
	}
	.range-input .progress {
		content: "\00a0";
		display: inline-block;
		position: absolute;
		width: 50%;
		max-width: 100%;
		height: 4px;
		background-color: #FFB700;
		margin-top: 4px;
		border-radius: 2px;
		left: 0px;
		top: 0px;
		z-index: 1;
	}
	@supports (-moz-appearance:none) {
		.range-input .progress {
			display: none;
		}
	}
	input[type="range"]::-moz-range-progress {
		background-color: #FFB700;
		border-radius: 2px;
		height: 4px;
	}
	input[type=range]::-webkit-slider-runnable-track {
		height: 4px;
		background-color: #C5C5C5;
		margin-top: 4px;
		border-radius: 2px;
	}
	input[type=range]::-moz-range-track {
		height: 4px;
		background-color: #C5C5C5;
		margin-top: 4px;
		border-radius: 2px;
	}
	input[type=range]::-webkit-slider-thumb {
		-webkit-appearance: none;
		position: relative;
		z-index: 2;
		width: 12px;
		height: 12px;
		box-sizing: border-box;
		border: 2px solid #C20000;
		background-color: #ED0000;
		border-radius: 50%;
		margin-top: -4px;
	}
	input[type=range]::-moz-range-thumb {
		position: relative;
		z-index: 2;
		width: 12px;
		height: 12px;
		box-sizing: border-box;
		border: 2px solid #C20000;
		background-color: #ED0000;
		border-radius: 50%;
		margin-top: -4px;
	}
	input[type=range]::-ms-track {
		width: 100%;
		height: 4px;
		background: transparent;
		border-color: transparent;
		color: transparent;
		margin-top: 4px;
	}
	input[type=range]::-ms-fill-lower {
		background: #FFB700;
		border-radius: 2px;
	}
	input[type=range]::-ms-fill-upper {
		background: #C5C5C5;
		border-radius: 2px;
	}
	input[type=range]::-ms-thumb {
		position: relative;
		z-index: 2;
		width: 12px;
		height: 12px;
		box-sizing: border-box;
		border: 2px solid #C20000;
		background-color: #ED0000;
		border-radius: 50%;
		margin-top: -4px;
	}
</style>
