<template>
	<div class="input-group">
		<div class="categories">
			<div class="label" v-bind:class="{alternate:alternateLabel}">
				<div class="title">{{categoryTitle}}</div>
				<button class="toggle" v-if="productList != ''" @click="toggleProducts($event,this.$refs.products)"><span class="show">Show</span><span class="hide">Hide</span></button>
			</div>
		</div>
		<div class="current">
			<input class="smaller" :value="computeCurrentJobs" v-bind:class="{filler:currentTotalJobs==null}" type="number" readonly />
			<input class="dollar" type="text" :value="computeCurrentProfits.toLocaleString()" v-bind:class="{filler:currentEcpProfit==null}" readonly />
		</div>
		<div class="new">
			<input class="editable" ref="newJobsInput" :value="newJobsState" v-bind:class="{filler:newJobsState==null,edited:(newJobsState!=computeCurrentJobs)}" @input="parseNumber($event)" type="number" />
			<input class="dollar" type="text" :value="computeNewProfits.toLocaleString()" v-bind:class="{filler:newEcpProfit==null}" readonly />
			<input class="dollar" type="text" :value="computeRebateProfits.toLocaleString()" v-bind:class="{filler:rebate==null}" readonly />
		</div>
	</div>
	<div ref=products class="products" v-bind:class="{open:$parent.expanded}" v-if="productList != ''">
		<div class="product-list"><b>Products:</b>&nbsp;{{productList}}</div>
		<div class="section-filler"><div class="categories"></div><div class="current"></div><div class="new"></div></div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
	name: 'ResultsInputGroup',
	props: ['alternateLabel','categoryTitle','productList','currentTotalJobs','brandPercentage','currentEcpProfit','newJobsState','newEcpProfit','rebate'],
	emits: ['update:newJobsState'],
	methods: {
		toggleProducts: function(e,tgt){
			e.target.classList.toggle("open");
			tgt.classList.toggle("open");
		},
		parseNumber: function(e) {
			var val = this.cleanNumberValue(e.target.value);
			e.target.value = val;
			this.$emit('update:newJobsState', val);
		}
	},
	computed: {
    ...mapGetters(['getJobs','getProfits']),
		computeCurrentJobs() {
      return (this.currentTotalJobs != null) ? this.getJobs(this.currentTotalJobs, this.brandPercentage) : 0;
    },
		computeCurrentProfits() {
      return this.getProfits(this.computeCurrentJobs, this.currentEcpProfit);
		},
		computeNewProfits() {
      return this.getProfits(this.newJobsState, this.newEcpProfit);
		},
		computeRebateProfits() {
      return this.getProfits(this.newJobsState, this.rebate);
		}
	},
	mounted: function () {
		if (this.$store.state.inputs.jobNumbersChanged) {
			this.$refs.newJobsInput.value = this.computeCurrentJobs;
			this.$refs.newJobsInput.dispatchEvent(new Event('input'));
		}
	}
}
</script>
