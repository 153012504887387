<template>
  <div class="summary-section">
    <div class="monthly">
      <div class="label">Increase in Profitability<br/>/ month ($)</div>
      <input class="dollar" type="text" :value="totalMonthlyIncrease.toLocaleString()" readonly/>
    </div>
    <div class="yearly">
      <div class="label">Increase in Profitability<br/>/ year ($)</div>
      <input class="dollar" type="text" :value="totalYearlyIncrease.toLocaleString()" readonly/>
    </div>
  </div>
</template>

<script>

export default {
	name: 'ResultsSummarySection',
	props: ['totalMonthlyIncrease','totalYearlyIncrease']
}
</script>

<style scoped>
.summary-section {
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: #0075BF;
	border: 5px solid #009CFF;
	border-radius: 5px;
	height: 80px;
	align-items: center;
	margin: 20px 0;
	box-shadow: 0px 3px 6px #00000029;
	box-sizing: border-box;
}
.summary-section .label {
	color: white;
	font-size: 18px;
	font-weight: bold;
	text-align: right;
	margin-right: 10px;
}
.summary-section input[type=text].dollar {
	width: 164px;
	height: 58px;
	border: 4px solid #9BD8FF;
	border-radius: 5px;
	font-weight: bold;
}
.summary-section .monthly, .summary-section .yearly {
	display: flex;
	align-items: center;
	margin: 0 25px;
}
</style>