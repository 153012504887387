<template>
  <div class="step">
    <Header 
      :stepNum="1"
      stepTitle="<b>Q1:</b> Practice Name"
    />
    <div class="main-content">
        <h2>What is your Practice Name?</h2>
				<div class="input-group">
					<div class="text-input"><input v-model="inputs.practice_name" type="text" placeholder="Enter name here" /></div>
				</div>
    </div>
    <div class="note">The Kodak logo, trademark and trade dress are used under license from Kodak. All other brands mentioned are property of their respective owners. © 2021 ELOA</div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import {mapState} from 'vuex'

export default {
  name: 'Step1',
  components: {
      Header
  },
  computed: {
      ...mapState(['inputs'])
  },
  mounted: function () {
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
  .main-content {
    padding-bottom: 150px;
  }
  .note {
    width: 844px;
  }
</style>