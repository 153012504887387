const rebateCalculations = {
    vsp: {
        no_group: {
            pal_cat_n: 10.00, pal_cat_o: 10.00, pal_cat_f: 10.00, pal_cat_j: 10.00, pal_cat_k: 5.00
        },
        no_vs: {
            pal_cat_n: 15.00, pal_cat_o: 15.00, pal_cat_f: 12.00, pal_cat_j: 12.00, pal_cat_k: 5.00
        },
        with_vs: {
            pal_cat_n: 15.00, pal_cat_o: 15.00, pal_cat_f: 15.00, pal_cat_j: 15.00, pal_cat_k: 5.00
        }
    },
    eyemed: {
        no_group: {
            pal_tier_4: 10.00, pal_tier_3: 10.00, pal_tier_2: 10.00, pal_tier_1: 10.00, pal_tier_standard: 5.00
        },
        no_vs: {
            pal_tier_4: 15.00, pal_tier_3: 15.00, pal_tier_2: 12.00, pal_tier_1: 12.00, pal_tier_standard: 5.00
        },
        with_vs: {
            pal_tier_4: 15.00, pal_tier_3: 15.00, pal_tier_2: 15.00, pal_tier_1: 15.00, pal_tier_standard: 5.00
        }
    },
    davis: {
        no_group: {
            pal_cat_ultimate: 0, pal_cat_ultra: 10.00, pal_cat_premium: 10.00, pal_cat_standard: 0
        },
        no_vs: {
            pal_cat_ultimate: 0, pal_cat_ultra: 15.00, pal_cat_premium: 12.00, pal_cat_standard: 15.00
        },
        with_vs: {
            pal_cat_ultimate: 0, pal_cat_ultra: 10.00, pal_cat_premium: 12.00, pal_cat_standard: 15.00
        }
    },
    spectera: {
        no_group: {
            pal_tier_5: 0, pal_tier_4: 0, pal_tier_3: 10.00, pal_tier_2: 0, pal_tier_1: 10.00
        },
        no_vs: {
            pal_tier_5: 0, pal_tier_4: 0, pal_tier_3: 15.00, pal_tier_2: 0, pal_tier_1: 12.00
        },
        with_vs: {
            pal_tier_5: 0, pal_tier_4: 0, pal_tier_3: 15.00, pal_tier_2: 0, pal_tier_1: 15.00
        }
    }
}
export default rebateCalculations
