<template>
  <div id="pdf-container">
    <Header :stepNum="13" />
    <ResultsTable :expanded="true" :watermarked="true"/>
    <div class="section">
      <SummaryTable :watermarked="true"/>
      <GrandSummaryTable :watermarked="true"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import ResultsTable from '@/components/ResultsTable.vue'
import GrandSummaryTable from '@/components/GrandSummaryTable.vue'
import SummaryTable from '@/components/SummaryTable.vue'

export default {
	name: 'DownloadPDF',
	components: {
		Header,
    GrandSummaryTable,
    SummaryTable,
    ResultsTable
	},
  mounted() {
    this.$parent.downloading = false;
  },
	methods: {
    downloadPDF: function () {
      //Google analytics event
      window.gtag('event', 'summary_pdf_download');
      this.$parent.downloading = true;
      //Chains sections.length amount of promises to generate canvas images sequentially for jsPDF
      var imgWidth;
      var imgHeight;
      var pageHeights = [];
      var canvases = [];
      //index starts at 1 to skip first section, as that is done outside of the loop
      var index = 1;
      var sections = Array.prototype.slice.call(document.getElementById('pdf-container').getElementsByClassName("section"));
      //first call of html2canvas is different as it has to include the 'header' element
      window.html2canvas(document.getElementById('pdf-container'), {
        useCORS: false,
        scale: 1.5,
        onclone: function(clone) {
          imgWidth = clone.querySelector('#pdf-container').offsetWidth;
          imgHeight = clone.querySelector('#pdf-container').offsetHeight;
          //make clone elements visible for html2canvas to capture
          clone.querySelector('#pdf-container').getElementsByClassName("section")[0].classList.add("active");
          clone.querySelector('#pdf-container').getElementsByClassName("header")[0].classList.add("active");
          //push element height to pageHeights array for jsPDF
          pageHeights.push(clone.querySelector(".section.active").offsetHeight + 230);
        }}).then(canvas => {
          //push first image to canvases array
          canvases.push(canvas.toDataURL('image/png'));
          //remove first entry of sections array as it was done differently in the first step
          sections.shift();
          console.log(sections);
          //subsequent calls of html2canvas are chained together sequentially based on sections.length
          var result = sections.reduce( (previousPromise, section) => {
            return previousPromise.then(() => {
              return (window.html2canvas(section, {
                useCORS: false,
                scale: 1.5,
                onclone: function(clone) {
                  //make clone elements visible for html2canvas to capture
                  //using an index because of some weirdness with how the clones and selectors work
                  clone.getElementsByClassName("section")[index].classList.add("active");
                  //push element height to pageHeights array for jsPDF
                  pageHeights.push(clone.getElementsByClassName("section")[index].offsetHeight);
                }}).then(canvas => {
                  index ++;
                  console.log('pushing canvas ' + canvas.toDataURL('image/png'))
                  //push image to canvases array
                  canvases.push(canvas.toDataURL('image/png'))
              }));
            });
          }, Promise.resolve());
          result.then(err => {
            console.log('done: ' + err);
            var doc = new window.jspdf.jsPDF('p', 'px', [imgWidth,pageHeights[0]]);
            doc.addImage(canvases[0], 'PNG', 0, 0, imgWidth, imgHeight);
            for (var i = 1; i < canvases.length; i++) {
              //if page height is smaller than width, then need to pad height up to width size to preserve portrait orientation
              var pageHeight = (pageHeights[i] > imgWidth) ? pageHeights[i] : imgWidth;
              doc.addPage([imgWidth,pageHeight]);
              doc.addImage(canvases[i], 'PNG', 0, 0, imgWidth, pageHeights[i]);
            }
            console.log('complete')
            var fileName = "";
            if (this.$store.state.inputs.practice_name.length != "") {
              fileName = this.$store.state.inputs.practice_name + " - ";
            }
            this.$parent.downloading = false;
            // doc.save(fileName + "Kodak Lens Profitability Impact Summary.pdf");
            // window.open(doc.output('bloburl'), '_blank');
            
            var downloadLink = document.createElement("a");
            downloadLink.href = doc.output('bloburl');
            downloadLink.download = fileName + "Kodak Lens Profitability Impact Summary.pdf";
            downloadLink.target = "_blank";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            console.log('downloading file');
          })
      });
    }
	}
}
</script>

<style scoped>
  #pdf-container {
    width: 1064px;
    margin: 0 auto;
  }
  #pdf-container .header {
    display: none;
  }
  #pdf-container ::v-deep(.section) {
    display: none;
  }
  #pdf-container .header.active {
    display: block;
  }
  #pdf-container ::v-deep(.section.active) {
    display: flex;
  }
</style>