<template>
    <div class="number-input"><input :value="modelValue" type="number" min="0" @input="parseNumber($event)"/></div>
</template>

<script>

export default {
    name: 'RangeInput',
    props: ['modelValue'],
		emits: ['update:modelValue'],
		methods: {
			parseNumber: function(e) {
        var val = this.cleanNumberValue(e.target.value);
        e.target.value = val;
        this.$emit('update:modelValue', val);
			}
		}
}
</script>