<template>
  <div class="step">
    <Header 
      :stepNum="4"
      stepTitle="<b>Q4:</b> VSP"
    />
    <div class="main-content">
        <h2>What percentage of your VSP jobs are:</h2>
        <div class="input-headers">
                <div class="pct-header">Percentage (%)</div>
        </div>
        <div class="input-group slider">
            <div class="label">Choice</div>
            <RangeInput v-model.number="inputs.job_pct_vsp.choice" @input="rebalanceInputs('choice')"/>
            <div class="number-input"><input v-model.number.lazy="inputs.job_pct_vsp.choice" type="number" min="0" max="100" step="5" @input="cleanSliderValue($event)" @change="rebalanceInputs('choice')"/></div>
        </div>
        <div class="input-group slider end">
            <div class="label">Signature</div>
            <RangeInput v-model.number="inputs.job_pct_vsp.signature" @input="rebalanceInputs('signature')"/>
            <div class="number-input"><input v-model.number.lazy="inputs.job_pct_vsp.signature" type="number" min="0" max="100" step="5" @input="cleanSliderValue($event)" @change="rebalanceInputs('signature')"/></div>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import RangeInput from '@/components/RangeInput.vue'
import {mapState} from 'vuex'

export default {
	name: 'Step4',
	components: {
			Header,
			RangeInput
	},
  methods: {
    rebalanceInputs: function(brandEdited) {
      var choiceVal = {
        'val' : this.inputs.job_pct_vsp.choice
      }
      var signatureVal = {
        'val' : this.inputs.job_pct_vsp.signature
      }
      var overage = {
        'val' : 0
      }
      var valueTotal = (choiceVal.val + signatureVal.val);
      overage.val = valueTotal - 100;
      this.rebalanceInput('choice',brandEdited,this.inputs.job_pct_vsp.choice,choiceVal,overage);
      this.rebalanceInput('signature',brandEdited,this.inputs.job_pct_vsp.signature,signatureVal,overage);
      this.$store.commit('rebalanceVspPct',[choiceVal.val,signatureVal.val])
    },
    rebalanceInput: function(tgtBrand,brandEdited,brandPct,valRef,overageRef) {
      if (brandEdited != tgtBrand) {
        valRef.val -= overageRef.val;
        overageRef.val = 0;
      }
    }
  },
	computed: {
			...mapState(['inputs'])
	},
  mounted: function () {
    window.scrollTo(0, 0)
  },
  watch: {
    '$store.state.inputs.job_pct_vsp.choice': function() {
      this.$store.commit('setJobNumbersChanged',true);
    }
  }
}
</script>

<style scoped>
	.input-group {
		width: 720px;
	}
	.input-headers::before {
		content: "\00a0";
		width: 767px;
	}
</style>