const ecpProfits = {
    current: {
        vsp_choice: {
            pal_cat_n: 80.00, pal_cat_o: 71.00, pal_cat_f: 51.00, pal_cat_j: 49.00, pal_cat_k: 27.00,
            ng_cat_d_back: 0, ng_cat_d: 33.00, ng_cat_c_back: 0, ng_cat_c: 28.00, ng_cat_b: 24.00, ng_cat_a: 20.00
        },
        vsp_signature: {
            pal_cat_n: 65.00, pal_cat_o: 45.00, pal_cat_f: 36.00, pal_cat_j: 34.00, pal_cat_k: 20.00,
            ng_cat_d_back: 0, ng_cat_d: 23.00, ng_cat_c_back: 0, ng_cat_c: 20.00, ng_cat_b: 17.00, ng_cat_a: 16.00
        },
        eyemed_access: {
            pal_tier_4: 200.00, pal_tier_3: 192.00, pal_tier_2: 149.20, pal_tier_1: 108.00, pal_tier_standard: 50.00,
            ng_lab_h: 57.00, ng_lab_k: 45.00, ng_tier_2_back: 0, ng_tier_2: 33.00, ng_tier_1_back: 0, ng_tier_1: 31.00, ng_tier_standard: 20.00
        },
        eyemed_insight: {
            pal_tier_4: 200.00, pal_tier_3: 57.00, pal_tier_2: 60.00, pal_tier_1: 60.00, pal_tier_standard: 50.00,
            ng_lab_h: 57.00, ng_lab_k: 45.00, ng_tier_2_back: 0, ng_tier_2: 21.00, ng_tier_1_back: 0, ng_tier_1: 20.00, ng_tier_standard: 20.00
        },
        davis: {
            pal_cat_ultimate: 60.00, pal_cat_ultra: 55.00, pal_cat_premium: 30.00, pal_cat_standard: 30.00,
            ng_cat_ultimate: 17.00, ng_cat_ultra: 10.00, ng_cat_premium: 7.00, ng_cat_standard: 7.00
        },
        superior: {
            pal_cat_ultimate: 110.00, pal_cat_ultra: 65.00, pal_cat_premium: 50.00, pal_cat_standard: 35.00,
            ng_cat_ultimate: 52.00, ng_cat_ultra: 35.00, ng_cat_premium: 29.00, ng_cat_standard: 22.00
        },
        spectera: {
            pal_tier_5: 250.00, pal_tier_4: 200.00, pal_tier_3: 150.00, pal_tier_2: 100.00, pal_tier_1: 55.00,
            ng_tier_4: 95.00, ng_tier_3: 75.00, ng_tier_2: 50.00, ng_tier_1: 30.00
        }
    },
    new: {
        vsp_choice: {
            pal_cat_n: 80.00, pal_cat_o: 71.00, pal_cat_f: 51.00, pal_cat_j: 49.00, pal_cat_k: 27.00,
            ng_cat_d_back: 36.00, ng_cat_d: 33.00, ng_cat_c_back: 31.00, ng_cat_c: 28.00, ng_cat_b: 27.00, ng_cat_a: 20.00
        },
        vsp_signature: {
            pal_cat_n: 65.00, pal_cat_o: 45.00, pal_cat_f: 36.00, pal_cat_j: 34.00, pal_cat_k: 20.00,
            ng_cat_d_back: 26.00, ng_cat_d: 23.00, ng_cat_c_back: 23.00, ng_cat_c: 20.00, ng_cat_b: 20.00, ng_cat_a: 16.00
        },
        eyemed_access: {
            pal_tier_4: 200.00, pal_tier_3: 192.00, pal_tier_2: 149.20, pal_tier_1: 108.00, pal_tier_standard: 50.00,
            ng_lab_h: 53.00, ng_lab_k: 41.00, ng_tier_2_back: 29.00, ng_tier_2: 33.00, ng_tier_1_back: 27.00, ng_tier_1: 31.00, ng_tier_standard: 20.00
        },
        eyemed_insight: {
            pal_tier_4: 200.00, pal_tier_3: 57.00, pal_tier_2: 60.00, pal_tier_1: 60.00, pal_tier_standard: 50.00,
            ng_lab_h: 53.00, ng_lab_k: 41.00, ng_tier_2_back: 0, ng_tier_2: 21.00, ng_tier_1_back: 0, ng_tier_1: 20.00, ng_tier_standard: 20.00
        },
        davis: {
            pal_cat_ultimate: 60.00, pal_cat_ultra: 55.00, pal_cat_premium: 30.00, pal_cat_standard: 30.00,
            ng_cat_ultimate: 17.00, ng_cat_ultra: 10.00, ng_cat_premium: 7.00, ng_cat_standard: 7.00
        },
        superior: {
            pal_cat_ultimate: 110.00, pal_cat_ultra: 65.00, pal_cat_premium: 50.00, pal_cat_standard: 35.00,
            ng_cat_ultimate: 52.00, ng_cat_ultra: 35.00, ng_cat_premium: 29.00, ng_cat_standard: 22.00
        },
        spectera: {
            pal_tier_5: 250.00, pal_tier_4: 200.00, pal_tier_3: 150.00, pal_tier_2: 100.00, pal_tier_1: 55.00,
            ng_tier_4: 95.00, ng_tier_3: 75.00, ng_tier_2: 50.00, ng_tier_1: 30.00
        }
    }
}
export default ecpProfits