<template>
  <div class="step">
    <Header 
      :stepNum="2"
      stepTitle="<b>Q2:</b> Managed Vision Care Business"
    />
    <div class="main-content">
        <h2>What percentage of your business is Managed Vision Care?</h2>
        <div class="input-group">
            <div class="label">Enter percentage here:</div>
            <div class="number-input"><input v-model.number="inputs.pct_mvc" type="number" @input="cleanSliderValue($event)"/></div>
            <div class="pct-label">%</div>
        </div>
    </div>
  <div class="note">Calculator is populated with default values based on the national averages for ECPs. In the absence of practice specific information, calculations will be based off of these default values if no other value is input to override them.</div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import {mapState} from 'vuex'

export default {
  name: 'Step2',
  components: {
      Header
  },
  computed: {
      ...mapState(['inputs'])
  },
  mounted: function () {
    window.scrollTo(0, 0)
  },
  watch: {
    '$store.state.inputs.pct_mvc': function() {
      this.$store.commit('setJobNumbersChanged',true);
      this.$store.commit('setDefaultVspPalJobs');
      this.$store.commit('setDefaultVspNGJobs');
      this.$store.commit('setDefaultEyemedPalJobs');
      this.$store.commit('setDefaultEyemedNGJobs');
      this.$store.commit('setDefaultVersantPalJobs');
      this.$store.commit('setDefaultVersantNGJobs');
      this.$store.commit('setDefaultSpecteraPalJobs');
      this.$store.commit('setDefaultSpecteraNGJobs');
    }
  }
}
</script>

<style scoped>
  .main-content {
    padding-bottom: 150px;
  }
  .pct-label {
    margin-left: 10px;
    font-size: 12px;
    font-weight: bold;
  }
  .number-input input {
    font-weight: bold;
  }
  .note {
    width: 844px;
  }
</style>
