<template>
  <div class="step">
    <Header 
      :stepNum="0"
    />
    <div class="main-content">
      <h2>Enter your login credentials below</h2>
      <form @submit.prevent='ajaxLogin(loginSuccess)'>
        <input v-model="user" type=text placeholder="Enter username here"/>
        <input v-model="password" type=password placeholder="Enter password here"/>
        <div class="message">{{message}}</div>
        <div class="nav-button-container">
          <div class="button-container">
            <a href="https://api.klprofitabilitycalculator.com/user/forgot" target="_blank">FORGOT PASSWORD?</a>
            <button type="submit" class="login">LOGIN</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import {mapState} from 'vuex'

export default {
  name: 'Login',
  components: {
    Header
  },
  data: function() {
    return {
      user : "",
      password : "",
      message: ""
    }
  },
  computed: {
      ...mapState(['inputs'])
  },
  methods: {
    ajaxLogin: function(callback) {
      var xmlhttp = new XMLHttpRequest();
      var ref = this;
      var response;
      xmlhttp.onreadystatechange = function() {
          if (xmlhttp.readyState == XMLHttpRequest.DONE) {   // XMLHttpRequest.DONE == 4
            if (xmlhttp.status == 200) {
              response = JSON.parse(xmlhttp.responseText);
              callback(response);
            } else if (xmlhttp.status == 401) {
              ref.message = "Username or password is invalid";
            } else {
              ref.message = "Authentication failed. Error Code: " + xmlhttp.status;
            }
          }
      }; 
      xmlhttp.open("POST", "https://api.klprofitabilitycalculator.com/api/login");
      xmlhttp.setRequestHeader("Content-type", "application/json");
      var data = JSON.stringify({"Email": this.user, "Password": this.password});
      xmlhttp.send(data);
    },
    loginSuccess: function(e) {
      if (e.user != null) {
        this.message = "";
        this.$store.commit('login', e.user.email);
        sessionStorage.setItem('userId', e.user.id);
        sessionStorage.setItem('token', e.user.token);
        this.$router.replace("/");
      } else if (e.error) {
        this.message = e.message;
      } else {
        this.message = "Login failed";
      }
    }
  }
}
</script>

<style scoped>
  .main-content {
    width: 100%;
  }
  h2 {
    width: 1016px;
  }
  input {
    display: block;
    font-size: 14px;
    font-family: Arial, sans-serif;
    color: black;
    padding: 17px 20px;
    box-sizing: border-box;
    border: 2px solid #444444;
    border-radius: 5px;
    height: 50px;
    width: 350px;
    margin-bottom: 20px;
  }
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .nav-button-container {
    position: absolute;
    bottom: -82px;
  }
  .button-container {
    margin: 20px 0;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .button-container a {
    color: #ED0000;
    font-size: 12px;
    font-weight: bold;
  }
  .button-container button {
    color: white;
    font-size: 12px;
    background-color: #ED0000;
    min-width: 150px;
    padding: 0 25px;
    height: 40px;
    border-radius: 2px;
    text-decoration: none;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: none;
    cursor: pointer;
  }
  .button-container button:hover {
    background-color: #be0000;
  }
  .message {
    height: 25px;
    width: 650px;
    padding: 20px 0;
    text-align: center;
    font-weight: bold;
    color: #ED0000;
  }
  
</style>
