<template>
  <div class="step">
    <Header 
      :stepNum="6"
      stepTitle="<b>Q6:</b> Versant"
    />
    <div class="main-content">
        <h2>What percentage of your Versant jobs are:</h2>
        <div class="input-headers">
                <div class="pct-header">Percentage (%)</div>
        </div>
        <div class="input-group slider">
            <div class="label">Davis Vision</div>
            <RangeInput v-model.number="inputs.job_pct_versant.davis" @input="rebalanceInputs('davis')"/>
            <div class="number-input"><input v-model.number.lazy="inputs.job_pct_versant.davis" type="number" min="0" max="100" step="5" @input="cleanSliderValue($event)" @change="rebalanceInputs('davis')"/></div>
        </div>
        <div class="input-group slider end">
            <div class="label">Superior Vision</div>
            <RangeInput v-model.number="inputs.job_pct_versant.superior" @input="rebalanceInputs('superior')"/>
            <div class="number-input"><input v-model.number.lazy="inputs.job_pct_versant.superior" type="number" min="0" max="100" step="5" @input="cleanSliderValue($event)" @change="rebalanceInputs('superior')"/></div>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import RangeInput from '@/components/RangeInput.vue'
import {mapState} from 'vuex'

export default {
	name: 'Step6',
	components: {
			Header,
			RangeInput
	},
  methods: {
    rebalanceInputs: function(brandEdited) {
      var davisVal = {
        'val' : this.inputs.job_pct_versant.davis
      }
      var superiorVal = {
        'val' : this.inputs.job_pct_versant.superior
      }
      var overage = {
        'val' : 0
      }
      var valueTotal = (davisVal.val + superiorVal.val);
      overage.val = valueTotal - 100;
      this.rebalanceInput('davis',brandEdited,this.inputs.job_pct_versant.davis,davisVal,overage);
      this.rebalanceInput('superior',brandEdited,this.inputs.job_pct_versant.superior,superiorVal,overage);
      this.$store.commit('rebalanceVersantPct',[davisVal.val,superiorVal.val])
    },
    rebalanceInput: function(tgtBrand,brandEdited,brandPct,valRef,overageRef) {
      if (brandEdited != tgtBrand) {
        valRef.val -= overageRef.val;
        overageRef.val = 0;
      }
    }
  },
	computed: {
			...mapState(['inputs'])
	},
  mounted: function () {
    window.scrollTo(0, 0)
  },
  watch: {
    '$store.state.inputs.job_pct_versant.davis': function() {
      this.$store.commit('setJobNumbersChanged',true);
    }
  }
}
</script>

<style scoped>
	.input-group {
		width: 720px;
	}
	.input-headers::before {
		content: "\00a0";
		width: 767px;
	}
</style>