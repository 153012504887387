<template>
  <div class="input-subheaders">
    <div class="categories">
      <div class="title">{{categoryTitle}}</div>
      <div class="subtitle">Products</div>
    </div>
    <div class="current">
      <div class="subhead smaller">Total Jobs /&nbsp;month</div>
      <div class="subhead">Current Total Profitability ($)</div>
    </div>
    <div class="new">
      <div class="subhead editable">Total Jobs /&nbsp;month</div>
      <div class="subhead">New Total Profitability ($)</div>
      <div class="subhead">New Total Rebates ($)</div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'ResultsSubheaders',
    props: ['categoryTitle']
  }
</script>