<template>
  <div class="step">
    <Header 
      :stepNum="10"
      stepTitle="<b>Q10:</b> Buying & Alliance Groups"
    />
    <div class="main-content">
        <h2>What buying group / Alliance group are you part of?</h2>
        <div class="input-group">
            <div class="label">Select a group below:</div>
            <div class="row">
              <div class="radio-input"><input id="bg-radio-ce" v-model="inputs.buying_groups" value="ce" type="radio" name="buying-groups"/><label for="bg-radio-ce">C&E</label></div>
              <div class="radio-input"><input id="bg-radio-cecop" v-model="inputs.buying_groups" value="cecop" type="radio" name="buying-groups"/><label for="bg-radio-cecop">CECOP</label></div>
              <div class="radio-input"><input id="bg-radio-club_zero" v-model="inputs.buying_groups" value="club_zero" type="radio" name="buying-groups"/><label for="bg-radio-club_zero">Club Zero</label></div>
            </div>
            <div class="row">
              <div class="radio-input"><input id="bg-radio-eyetopia" v-model="inputs.buying_groups" value="eyetopia" type="radio" name="buying-groups"/><label for="bg-radio-eyetopia">Eyetopia</label></div>
              <div class="radio-input"><input id="bg-radio-idoc" v-model="inputs.buying_groups" value="idoc" type="radio" name="buying-groups"/><label for="bg-radio-idoc">IDOC</label></div>
              <div class="radio-input"><input id="bg-radio-odx" v-model="inputs.buying_groups" value="odx" type="radio" name="buying-groups"/><label for="bg-radio-odx">ODX</label></div>
            </div>
            <div class="row">
              <div class="radio-input"><input id="bg-radio-perc" v-model="inputs.buying_groups" value="perc" type="radio" name="buying-groups"/><label for="bg-radio-perc">PERC</label></div>
              <div class="radio-input"><input id="bg-radio-vision_source" v-model="inputs.buying_groups" value="vision_source" type="radio" name="buying-groups"/><label for="bg-radio-vision_source">Vision Source</label></div>
              <div class="radio-input"><input id="bg-radio-vision_trends" v-model="inputs.buying_groups" value="vision_trends" type="radio" name="buying-groups"/><label for="bg-radio-vision_trends">Vision Trends</label></div>
            </div>
            <div class="row">
              <div class="radio-input"><input id="bg-radio-none" v-model="inputs.buying_groups" value="none" type="radio" name="buying-groups"/><label for="bg-radio-none">None</label></div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import rebateCalculations from '@/lib/rebateCalculations.js'
import {mapState} from 'vuex'

export default {
  name: 'Step10',
  components: {
      Header
  },
  computed: {
      ...mapState(['inputs'])
  },
  mounted: function () {
    window.scrollTo(0, 0)
  },
  watch: {
    '$store.state.inputs.buying_groups': function() {
      if (this.$store.state.inputs.buying_groups == 'vision_source') {
        this.$store.commit('setRebatesWithVS',rebateCalculations)
      } else if (this.$store.state.inputs.buying_groups == 'none') {
        this.$store.commit('setRebatesNoGroup',rebateCalculations)
      } else {
        this.$store.commit('setRebatesWithoutVS',rebateCalculations)
      }
    }
  }
}
</script>

<style scoped>
  .input-group {
    width: 674px;
    display: block;
  }
  .input-group .label {
    width: 100%;
    margin-bottom: 25px;
    font-weight: bold;
  }
  .row {
    display: flex;
    justify-content: space-between;
  }
  .radio-input {
    width: 145px;
    margin-bottom: 30px;
    font-weight: bold;
  }
</style>
