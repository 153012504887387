<template>
	<div class="step">
		<Header 
			:stepNum="12"
		/>
		<div class="main-content">
			<SummaryTable />
			<GrandSummaryTable />
			<div class="note">The numbers above are estimates based off of the inputs from the previous questions. This may not be a 100% accurate depiction of current or potential profitability. The information on this page is confidential and is not intended to be shared or distributed.</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import GrandSummaryTable from '@/components/GrandSummaryTable.vue'
import SummaryTable from '@/components/SummaryTable.vue'

export default {
	name: 'Step12',
	components: {
			Header,
      GrandSummaryTable,
      SummaryTable
	},
	mounted: function () {
		window.scrollTo(0, 0)
		var labels = document.getElementsByClassName('product-label');
		for (var i = 0; i < labels.length; i++) {
			if (i % 2 == 0) {
				labels[i].classList.add('alternate');
			}
		}
	},
	methods: {
		toggleProducts: function(e,tgt){
			e.target.classList.toggle("open");
			tgt.classList.toggle("open");
		},
		toTop: function() {
			window.scrollTo(0, 0);
    }
	}
}
</script>

<style scoped>
  .main-content {
    width: 100%;
  }
  .note {
    width: 1016px;
    position: static;
    margin: 30px 0;
  }
</style>