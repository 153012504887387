import { createStore } from 'vuex'
import ecpProfits from '@/lib/ecpProfits.js'

export default createStore({
  state: {
    inputs : {
    },
    user_email: ""
  },
  getters: {
    getJobs: () => (totalJobs, jobPercentage) => {
      return Math.ceil(totalJobs * jobPercentage/100)
    },
    getProfits: () => (totalJobs, profitPerDesign) => {
      return Math.round(totalJobs * profitPerDesign)
    },
    hasVspChoice (state) {
			return (state.inputs.business_pct_mvc.vsp > 0 && state.inputs.job_pct_vsp.choice > 0)
		},
		hasVspSignature (state) {
			return (state.inputs.business_pct_mvc.vsp > 0 && state.inputs.job_pct_vsp.signature > 0)
		},
		hasEyemedAccess (state) {
			return (state.inputs.business_pct_mvc.eyemed > 0 && state.inputs.job_pct_eyemed.access > 0)
		},
		hasEyemedInsight (state) {
			return (state.inputs.business_pct_mvc.eyemed > 0 && state.inputs.job_pct_eyemed.insight > 0)
		},
		hasVersantDavis (state) {
			return (state.inputs.business_pct_mvc.davis > 0 && state.inputs.job_pct_versant.davis > 0)
		},
		hasVersantSuperior (state) {
			return (state.inputs.business_pct_mvc.davis > 0 && state.inputs.job_pct_versant.superior > 0)
		},
		hasSpectera (state) {
			return (state.inputs.business_pct_mvc.spectera > 0)
		},
    vspChoice_newTotalProfits (state, getters) {
			return (
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_choice.pal_cat_n,ecpProfits.new.vsp_choice.pal_cat_n) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_choice.pal_cat_o,ecpProfits.new.vsp_choice.pal_cat_o) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_choice.pal_cat_f,ecpProfits.new.vsp_choice.pal_cat_f) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_choice.pal_cat_j,ecpProfits.new.vsp_choice.pal_cat_j) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_choice.pal_cat_k,ecpProfits.new.vsp_choice.pal_cat_k) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_choice.ng_cat_d_back,ecpProfits.new.vsp_choice.ng_cat_d_back) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_choice.ng_cat_d,ecpProfits.new.vsp_choice.ng_cat_d) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_choice.ng_cat_c_back,ecpProfits.new.vsp_choice.ng_cat_c_back) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_choice.ng_cat_c,ecpProfits.new.vsp_choice.ng_cat_c) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_choice.ng_cat_b,ecpProfits.new.vsp_choice.ng_cat_b) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_choice.ng_cat_a,ecpProfits.new.vsp_choice.ng_cat_a)
			)
		},
    vspSignature_newTotalProfits (state, getters) {
			return (
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_signature.pal_cat_n,ecpProfits.new.vsp_signature.pal_cat_n) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_signature.pal_cat_o,ecpProfits.new.vsp_signature.pal_cat_o) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_signature.pal_cat_f,ecpProfits.new.vsp_signature.pal_cat_f) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_signature.pal_cat_j,ecpProfits.new.vsp_signature.pal_cat_j) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_signature.pal_cat_k,ecpProfits.new.vsp_signature.pal_cat_k) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_signature.ng_cat_d_back,ecpProfits.new.vsp_signature.ng_cat_d_back) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_signature.ng_cat_d,ecpProfits.new.vsp_signature.ng_cat_d) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_signature.ng_cat_c_back,ecpProfits.new.vsp_signature.ng_cat_c_back) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_signature.ng_cat_c,ecpProfits.new.vsp_signature.ng_cat_c) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_signature.ng_cat_b,ecpProfits.new.vsp_signature.ng_cat_b) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_signature.ng_cat_a,ecpProfits.new.vsp_signature.ng_cat_a)
			)
		},
		eyemedAccess_newTotalProfits (state, getters) {
			return (
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_access.pal_tier_4,getters.eyemedAccess_pal_tier4_new_price) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_access.pal_tier_3,getters.eyemedAccess_pal_tier3_new_price) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_access.pal_tier_2,getters.eyemedAccess_pal_tier2_new_price) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_access.pal_tier_1,getters.eyemedAccess_pal_tier1_new_price) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_access.pal_tier_standard,ecpProfits.new.eyemed_access.pal_tier_standard) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_access.ng_lab_h,getters.eyemedAccess_ng_labh_new_price) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_access.ng_lab_k,getters.eyemedAccess_ng_labk_new_price) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_access.ng_tier_2_back,getters.eyemedAccess_ng_tier2back_new_price) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_access.ng_tier_2,getters.eyemedAccess_ng_tier2_new_price) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_access.ng_tier_1_back,getters.eyemedAccess_ng_tier1back_new_price) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_access.ng_tier_1,getters.eyemedAccess_ng_tier1_new_price) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_access.ng_tier_standard,ecpProfits.new.eyemed_access.ng_tier_standard)
			)
		},
		eyemedAccess_pal_tier4_new_price (state) {
			return (state.inputs.pal_table_eyemed.tier_4_price*0.8-140);
		},
		eyemedAccess_pal_tier3_new_price (state) {
			return (state.inputs.pal_table_eyemed.tier_3_price*0.8-108);
		},
		eyemedAccess_pal_tier2_new_price (state) {
			return (state.inputs.pal_table_eyemed.tier_2_price*0.8-90);
		},
		eyemedAccess_pal_tier1_new_price (state) {
			return (state.inputs.pal_table_eyemed.tier_1_price*0.8-80);
		},
		eyemedAccess_ng_labh_new_price (state) {
			return (((state.inputs.ng_table_eyemed.lab_h_price-15)*0.8)-63+(15-7));
		},
		eyemedAccess_ng_labk_new_price (state) {
			return (((state.inputs.ng_table_eyemed.lab_k_price-15)*0.8)-55+(15-7));
		},
		eyemedAccess_ng_tier2back_new_price (state) {
			return (((state.inputs.ng_table_eyemed.tier_2_price-15)*0.8)-47+(15-7));
		},
		eyemedAccess_ng_tier2_new_price (state) {
			return (state.inputs.ng_table_eyemed.tier_2_price*0.8-47);
		},
		eyemedAccess_ng_tier1back_new_price (state) {
			return (((state.inputs.ng_table_eyemed.tier_1_price-15)*0.8)-37+(15-7));
		},
		eyemedAccess_ng_tier1_new_price (state) {
			return (state.inputs.ng_table_eyemed.tier_1_price*0.8-37);
		},
		eyemedInsight_newTotalProfits (state, getters) {
			return (
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_insight.pal_tier_4,getters.eyemedInsight_pal_tier4_new_price) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_insight.pal_tier_3,ecpProfits.new.eyemed_insight.pal_tier_3) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_insight.pal_tier_2,ecpProfits.new.eyemed_insight.pal_tier_2) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_insight.pal_tier_1,ecpProfits.new.eyemed_insight.pal_tier_1) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_insight.pal_tier_standard,ecpProfits.new.eyemed_insight.pal_tier_standard) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_insight.ng_lab_h,getters.eyemedInsight_ng_labh_new_price) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_insight.ng_lab_k,getters.eyemedInsight_ng_labk_new_price) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_insight.ng_tier_2,ecpProfits.new.eyemed_insight.ng_tier_2) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_insight.ng_tier_1,ecpProfits.new.eyemed_insight.ng_tier_1) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_insight.ng_tier_standard,ecpProfits.new.eyemed_insight.ng_tier_standard)
			)
		},
		eyemedInsight_pal_tier4_new_price (state) {
			return (state.inputs.pal_table_eyemed.tier_4_price*0.8-140);
		},
		eyemedInsight_ng_labh_new_price (state) {
			return (((state.inputs.ng_table_eyemed.lab_h_price-15)*0.8)-63+(15-7));
		},
		eyemedInsight_ng_labk_new_price (state) {
			return (((state.inputs.ng_table_eyemed.lab_k_price-15)*0.8)-55+(15-7));
		},
		versantDavis_newTotalProfits (state, getters) {
			return (
				getters.getProfits(state.inputs.new_monthly_jobs.davis.pal_cat_ultimate,ecpProfits.new.davis.pal_cat_ultimate) +
				getters.getProfits(state.inputs.new_monthly_jobs.davis.pal_cat_ultra,ecpProfits.new.davis.pal_cat_ultra) +
				getters.getProfits(state.inputs.new_monthly_jobs.davis.pal_cat_premium,ecpProfits.new.davis.pal_cat_premium) +
				getters.getProfits(state.inputs.new_monthly_jobs.davis.pal_cat_standard,ecpProfits.new.davis.pal_cat_standard) +
				getters.getProfits(state.inputs.new_monthly_jobs.davis.ng_cat_ultimate,ecpProfits.new.davis.ng_cat_ultimate) +
				getters.getProfits(state.inputs.new_monthly_jobs.davis.ng_cat_ultra,ecpProfits.new.davis.ng_cat_ultra) +
				getters.getProfits(state.inputs.new_monthly_jobs.davis.ng_cat_premium,ecpProfits.new.davis.ng_cat_premium) +
				getters.getProfits(state.inputs.new_monthly_jobs.davis.ng_cat_standard,ecpProfits.new.davis.ng_cat_standard)
			)
		},
		versantSuperior_newTotalProfits (state, getters) {
			return (
				getters.getProfits(state.inputs.new_monthly_jobs.superior.pal_cat_ultimate,ecpProfits.new.superior.pal_cat_ultimate) +
				getters.getProfits(state.inputs.new_monthly_jobs.superior.pal_cat_ultra,ecpProfits.new.superior.pal_cat_ultra) +
				getters.getProfits(state.inputs.new_monthly_jobs.superior.pal_cat_premium,ecpProfits.new.superior.pal_cat_premium) +
				getters.getProfits(state.inputs.new_monthly_jobs.superior.pal_cat_standard,ecpProfits.new.superior.pal_cat_standard) +
				getters.getProfits(state.inputs.new_monthly_jobs.superior.ng_cat_ultimate,ecpProfits.new.superior.ng_cat_ultimate) +
				getters.getProfits(state.inputs.new_monthly_jobs.superior.ng_cat_ultra,ecpProfits.new.superior.ng_cat_ultra) +
				getters.getProfits(state.inputs.new_monthly_jobs.superior.ng_cat_premium,ecpProfits.new.superior.ng_cat_premium) +
				getters.getProfits(state.inputs.new_monthly_jobs.superior.ng_cat_standard,ecpProfits.new.superior.ng_cat_standard)
			)
		},
		spectera_newTotalProfits (state, getters) {
			return (
				getters.getProfits(state.inputs.new_monthly_jobs.spectera.pal_tier_5,ecpProfits.new.spectera.pal_tier_5*(state.inputs.profit_pct_spectera/100)) +
				getters.getProfits(state.inputs.new_monthly_jobs.spectera.pal_tier_4,ecpProfits.new.spectera.pal_tier_4*(state.inputs.profit_pct_spectera/100)) +
				getters.getProfits(state.inputs.new_monthly_jobs.spectera.pal_tier_3,ecpProfits.new.spectera.pal_tier_3*(state.inputs.profit_pct_spectera/100)) +
				getters.getProfits(state.inputs.new_monthly_jobs.spectera.pal_tier_2,ecpProfits.new.spectera.pal_tier_2*(state.inputs.profit_pct_spectera/100)) +
				getters.getProfits(state.inputs.new_monthly_jobs.spectera.pal_tier_1,ecpProfits.new.spectera.pal_tier_1*(state.inputs.profit_pct_spectera/100)) +
				getters.getProfits(state.inputs.new_monthly_jobs.spectera.ng_tier_4,ecpProfits.new.spectera.ng_tier_4*(state.inputs.profit_pct_spectera/100)) +
				getters.getProfits(state.inputs.new_monthly_jobs.spectera.ng_tier_3,ecpProfits.new.spectera.ng_tier_3*(state.inputs.profit_pct_spectera/100)) +
				getters.getProfits(state.inputs.new_monthly_jobs.spectera.ng_tier_2,ecpProfits.new.spectera.ng_tier_2*(state.inputs.profit_pct_spectera/100)) +
				getters.getProfits(state.inputs.new_monthly_jobs.spectera.ng_tier_1,ecpProfits.new.spectera.ng_tier_1*(state.inputs.profit_pct_spectera/100))
			)
		},
		vspChoice_newTotalRebates (state, getters) {
			return (
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_choice.pal_cat_n,state.inputs.rebates.vsp.pal_cat_n) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_choice.pal_cat_o,state.inputs.rebates.vsp.pal_cat_o) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_choice.pal_cat_f,state.inputs.rebates.vsp.pal_cat_f) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_choice.pal_cat_j,state.inputs.rebates.vsp.pal_cat_j) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_choice.pal_cat_k,state.inputs.rebates.vsp.pal_cat_k)
			)
		},
		vspSignature_newTotalRebates (state, getters) {
			return (
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_signature.pal_cat_n,state.inputs.rebates.vsp.pal_cat_n) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_signature.pal_cat_o,state.inputs.rebates.vsp.pal_cat_o) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_signature.pal_cat_f,state.inputs.rebates.vsp.pal_cat_f) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_signature.pal_cat_j,state.inputs.rebates.vsp.pal_cat_j) +
				getters.getProfits(state.inputs.new_monthly_jobs.vsp_signature.pal_cat_k,state.inputs.rebates.vsp.pal_cat_k)
			)
		},
		eyemedAccess_newTotalRebates (state, getters) {
			return (
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_access.pal_tier_4,state.inputs.rebates.eyemed.pal_tier_4) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_access.pal_tier_3,state.inputs.rebates.eyemed.pal_tier_3) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_access.pal_tier_2,state.inputs.rebates.eyemed.pal_tier_2) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_access.pal_tier_1,state.inputs.rebates.eyemed.pal_tier_1) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_access.pal_tier_standard,state.inputs.rebates.eyemed.pal_tier_standard)
			)
		},
		eyemedInsight_newTotalRebates (state, getters) {
			return (
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_insight.pal_tier_4,state.inputs.rebates.eyemed.pal_tier_4) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_insight.pal_tier_3,state.inputs.rebates.eyemed.pal_tier_3) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_insight.pal_tier_2,state.inputs.rebates.eyemed.pal_tier_2) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_insight.pal_tier_1,state.inputs.rebates.eyemed.pal_tier_1) +
				getters.getProfits(state.inputs.new_monthly_jobs.eyemed_insight.pal_tier_standard,state.inputs.rebates.eyemed.pal_tier_standard)
			)
		},
		versantDavis_newTotalRebates (state, getters) {
			return (
				getters.getProfits(state.inputs.new_monthly_jobs.davis.pal_cat_ultimate,state.inputs.rebates.davis.pal_cat_ultimate) +
				getters.getProfits(state.inputs.new_monthly_jobs.davis.pal_cat_ultra,state.inputs.rebates.davis.pal_cat_ultra) +
				getters.getProfits(state.inputs.new_monthly_jobs.davis.pal_cat_premium,state.inputs.rebates.davis.pal_cat_premium) +
				getters.getProfits(state.inputs.new_monthly_jobs.davis.pal_cat_standard,state.inputs.rebates.davis.pal_cat_standard)
			)
		},
		versantSuperior_newTotalRebates (state, getters) {
			return (
				getters.getProfits(state.inputs.new_monthly_jobs.superior.pal_cat_ultimate,state.inputs.rebates.davis.pal_cat_ultimate) +
				getters.getProfits(state.inputs.new_monthly_jobs.superior.pal_cat_ultra,state.inputs.rebates.davis.pal_cat_ultra) +
				getters.getProfits(state.inputs.new_monthly_jobs.superior.pal_cat_premium,state.inputs.rebates.davis.pal_cat_premium) +
				getters.getProfits(state.inputs.new_monthly_jobs.superior.pal_cat_standard,state.inputs.rebates.davis.pal_cat_standard)
			)
		},
		spectera_newTotalRebates (state, getters) {
			return (
				getters.getProfits(state.inputs.new_monthly_jobs.spectera.pal_tier_5,state.inputs.rebates.spectera.pal_tier_5) +
				getters.getProfits(state.inputs.new_monthly_jobs.spectera.pal_tier_4,state.inputs.rebates.spectera.pal_tier_4) +
				getters.getProfits(state.inputs.new_monthly_jobs.spectera.pal_tier_3,state.inputs.rebates.spectera.pal_tier_3) +
				getters.getProfits(state.inputs.new_monthly_jobs.spectera.pal_tier_2,state.inputs.rebates.spectera.pal_tier_2) +
				getters.getProfits(state.inputs.new_monthly_jobs.spectera.pal_tier_1,state.inputs.rebates.spectera.pal_tier_1)
			)
		},
		vspChoice_currentGrandTotalProfits (state, getters) {
			return (
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_vsp.cat_n_jobs,state.inputs.job_pct_vsp.choice),
					ecpProfits.current.vsp_choice.pal_cat_n
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_vsp.cat_o_jobs,state.inputs.job_pct_vsp.choice),
					ecpProfits.current.vsp_choice.pal_cat_o
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_vsp.cat_f_jobs,state.inputs.job_pct_vsp.choice),
					ecpProfits.current.vsp_choice.pal_cat_f
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_vsp.cat_j_jobs,state.inputs.job_pct_vsp.choice),
					ecpProfits.current.vsp_choice.pal_cat_j
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_vsp.cat_k_jobs,state.inputs.job_pct_vsp.choice),
					ecpProfits.current.vsp_choice.pal_cat_k
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_vsp.cat_d_jobs,state.inputs.job_pct_vsp.choice),
					ecpProfits.current.vsp_choice.ng_cat_d
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_vsp.cat_c_jobs,state.inputs.job_pct_vsp.choice),
					ecpProfits.current.vsp_choice.ng_cat_c
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_vsp.cat_b_jobs,state.inputs.job_pct_vsp.choice),
					ecpProfits.current.vsp_choice.ng_cat_b
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_vsp.cat_a_jobs,state.inputs.job_pct_vsp.choice),
					ecpProfits.current.vsp_choice.ng_cat_a
				)
			)
		},
		vspSignature_currentGrandTotalProfits (state, getters) {
			return (
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_vsp.cat_n_jobs,state.inputs.job_pct_vsp.signature),
					ecpProfits.current.vsp_signature.pal_cat_n
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_vsp.cat_o_jobs,state.inputs.job_pct_vsp.signature),
					ecpProfits.current.vsp_signature.pal_cat_o
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_vsp.cat_f_jobs,state.inputs.job_pct_vsp.signature),
					ecpProfits.current.vsp_signature.pal_cat_f
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_vsp.cat_j_jobs,state.inputs.job_pct_vsp.signature),
					ecpProfits.current.vsp_signature.pal_cat_j
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_vsp.cat_k_jobs,state.inputs.job_pct_vsp.signature),
					ecpProfits.current.vsp_signature.pal_cat_k
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_vsp.cat_d_jobs,state.inputs.job_pct_vsp.signature),
					ecpProfits.current.vsp_signature.ng_cat_d
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_vsp.cat_c_jobs,state.inputs.job_pct_vsp.signature),
					ecpProfits.current.vsp_signature.ng_cat_c
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_vsp.cat_b_jobs,state.inputs.job_pct_vsp.signature),
					ecpProfits.current.vsp_signature.ng_cat_b
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_vsp.cat_a_jobs,state.inputs.job_pct_vsp.signature),
					ecpProfits.current.vsp_signature.ng_cat_a
				)
			)
		},
		eyemedAccess_currentGrandTotalProfits (state, getters) {
			return (
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_eyemed.tier_4_jobs,state.inputs.job_pct_eyemed.access),
					getters.eyemedAccess_pal_tier4_cur_price
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_eyemed.tier_3_jobs,state.inputs.job_pct_eyemed.access),
					getters.eyemedAccess_pal_tier3_cur_price
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_eyemed.tier_2_jobs,state.inputs.job_pct_eyemed.access),
					getters.eyemedAccess_pal_tier2_cur_price
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_eyemed.tier_1_jobs,state.inputs.job_pct_eyemed.access),
					getters.eyemedAccess_pal_tier1_cur_price
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_eyemed.standard_jobs,state.inputs.job_pct_eyemed.access),
					ecpProfits.current.eyemed_access.pal_tier_standard
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_eyemed.lab_h_jobs,state.inputs.job_pct_eyemed.access),
					getters.eyemedAccess_ng_labh_cur_price
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_eyemed.lab_k_jobs,state.inputs.job_pct_eyemed.access),
					getters.eyemedAccess_ng_labk_cur_price
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_eyemed.tier_2_jobs,state.inputs.job_pct_eyemed.access),
					getters.eyemedAccess_ng_tier2_cur_price
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_eyemed.tier_1_jobs,state.inputs.job_pct_eyemed.access),
					getters.eyemedAccess_ng_tier1_cur_price
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_eyemed.standard_jobs,state.inputs.job_pct_eyemed.access),
					ecpProfits.current.eyemed_access.ng_tier_standard
				)
			)
		},
		eyemedAccess_pal_tier4_cur_price (state) {
			return (state.inputs.pal_table_eyemed.tier_4_price*0.8-140);
		},
		eyemedAccess_pal_tier3_cur_price (state) {
			return (state.inputs.pal_table_eyemed.tier_3_price*0.8-108);
		},
		eyemedAccess_pal_tier2_cur_price (state) {
			return (state.inputs.pal_table_eyemed.tier_2_price*0.8-90);
		},
		eyemedAccess_pal_tier1_cur_price (state) {
			return (state.inputs.pal_table_eyemed.tier_1_price*0.8-80);
		},
		eyemedAccess_ng_labh_cur_price (state) {
			return (state.inputs.ng_table_eyemed.lab_h_price*0.8-63);
		},
		eyemedAccess_ng_labk_cur_price (state) {
			return (state.inputs.ng_table_eyemed.lab_k_price*0.8-55);
		},
		eyemedAccess_ng_tier2_cur_price (state) {
			return (state.inputs.ng_table_eyemed.tier_2_price*0.8-47);
		},
		eyemedAccess_ng_tier1_cur_price (state) {
			return (state.inputs.ng_table_eyemed.tier_1_price*0.8-37);
		},
		eyemedInsight_currentGrandTotalProfits (state, getters) {
			return (
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_eyemed.tier_4_jobs,state.inputs.job_pct_eyemed.insight),
					state.inputs.pal_table_eyemed.tier_4_price*0.8-140
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_eyemed.tier_3_jobs,state.inputs.job_pct_eyemed.insight),
					ecpProfits.current.eyemed_insight.pal_tier_3
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_eyemed.tier_2_jobs,state.inputs.job_pct_eyemed.insight),
					ecpProfits.current.eyemed_insight.pal_tier_2
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_eyemed.tier_1_jobs,state.inputs.job_pct_eyemed.insight),
					ecpProfits.current.eyemed_insight.pal_tier_1
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_eyemed.standard_jobs,state.inputs.job_pct_eyemed.insight),
					ecpProfits.current.eyemed_insight.pal_tier_standard
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_eyemed.lab_h_jobs,state.inputs.job_pct_eyemed.insight),
					state.inputs.ng_table_eyemed.lab_h_price*0.8-63
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_eyemed.lab_k_jobs,state.inputs.job_pct_eyemed.insight),
					state.inputs.ng_table_eyemed.lab_k_price*0.8-55
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_eyemed.tier_2_jobs,state.inputs.job_pct_eyemed.insight),
					ecpProfits.current.eyemed_insight.ng_tier_2
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_eyemed.tier_1_jobs,state.inputs.job_pct_eyemed.insight),
					ecpProfits.current.eyemed_insight.ng_tier_1
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_eyemed.standard_jobs,state.inputs.job_pct_eyemed.insight),
					ecpProfits.current.eyemed_insight.ng_tier_standard
				)
			)
		},
		eyemedInsight_pal_tier4_cur_price (state) {
			return (state.inputs.pal_table_eyemed.tier_4_price*0.8-140);
		},
		eyemedInsight_ng_labh_cur_price (state) {
			return (state.inputs.ng_table_eyemed.lab_h_price*0.8-63);
		},
		eyemedInsight_ng_labk_cur_price (state) {
			return (state.inputs.ng_table_eyemed.lab_k_price*0.8-55);
		},
		versantDavis_currentGrandTotalProfits (state, getters) {
			return (
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_davis.ultimate_jobs,state.inputs.job_pct_versant.davis),
					ecpProfits.current.davis.pal_cat_ultimate
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_davis.ultra_jobs,state.inputs.job_pct_versant.davis),
					ecpProfits.current.davis.pal_cat_ultra
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_davis.premium_jobs,state.inputs.job_pct_versant.davis),
					ecpProfits.current.davis.pal_cat_premium
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_davis.standard_jobs,state.inputs.job_pct_versant.davis),
					ecpProfits.current.davis.pal_cat_standard
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_davis.ultimate_jobs,state.inputs.job_pct_versant.davis),
					ecpProfits.current.davis.ng_cat_ultimate
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_davis.ultra_jobs,state.inputs.job_pct_versant.davis),
					ecpProfits.current.davis.ng_cat_ultra
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_davis.premium_jobs,state.inputs.job_pct_versant.davis),
					ecpProfits.current.davis.ng_cat_premium
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_davis.standard_jobs,state.inputs.job_pct_versant.davis),
					ecpProfits.current.davis.ng_cat_standard
				)
			)
		},
		versantSuperior_currentGrandTotalProfits (state, getters) {
			return (
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_davis.ultimate_jobs,state.inputs.job_pct_versant.superior),
					ecpProfits.current.superior.pal_cat_ultimate
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_davis.ultra_jobs,state.inputs.job_pct_versant.superior),
					ecpProfits.current.superior.pal_cat_ultra
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_davis.premium_jobs,state.inputs.job_pct_versant.superior),
					ecpProfits.current.superior.pal_cat_premium
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.pal_table_davis.standard_jobs,state.inputs.job_pct_versant.superior),
					ecpProfits.current.superior.pal_cat_standard
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_davis.ultimate_jobs,state.inputs.job_pct_versant.superior),
					ecpProfits.current.superior.ng_cat_ultimate
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_davis.ultra_jobs,state.inputs.job_pct_versant.superior),
					ecpProfits.current.superior.ng_cat_ultra
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_davis.premium_jobs,state.inputs.job_pct_versant.superior),
					ecpProfits.current.superior.ng_cat_premium
				) +
				getters.getProfits(
					getters.getJobs(state.inputs.ng_table_davis.standard_jobs,state.inputs.job_pct_versant.superior),
					ecpProfits.current.superior.ng_cat_standard
				)
			)
		},
		spectera_currentGrandTotalProfits (state, getters) {
			return (
				getters.getProfits(
					state.inputs.pal_table_spectera.tier_5_jobs,
					ecpProfits.current.spectera.pal_tier_5*(state.inputs.profit_pct_spectera/100)
				) +
				getters.getProfits(
					state.inputs.pal_table_spectera.tier_4_jobs,
					ecpProfits.current.spectera.pal_tier_4*(state.inputs.profit_pct_spectera/100)
				) +
				getters.getProfits(
					state.inputs.pal_table_spectera.tier_3_jobs,
					ecpProfits.current.spectera.pal_tier_3*(state.inputs.profit_pct_spectera/100)
				) +
				getters.getProfits(
					state.inputs.pal_table_spectera.tier_2_jobs,
					ecpProfits.current.spectera.pal_tier_2*(state.inputs.profit_pct_spectera/100)
				) +
				getters.getProfits(
					state.inputs.pal_table_spectera.tier_1_jobs,
					ecpProfits.current.spectera.pal_tier_1*(state.inputs.profit_pct_spectera/100)
				) +
				getters.getProfits(
					state.inputs.ng_table_spectera.tier_4_jobs,
					ecpProfits.current.spectera.ng_tier_4*(state.inputs.profit_pct_spectera/100)
				) +
				getters.getProfits(
					state.inputs.ng_table_spectera.tier_3_jobs,
					ecpProfits.current.spectera.ng_tier_3*(state.inputs.profit_pct_spectera/100)
				) +
				getters.getProfits(
					state.inputs.ng_table_spectera.tier_2_jobs,
					ecpProfits.current.spectera.ng_tier_2*(state.inputs.profit_pct_spectera/100)
				) +
				getters.getProfits(
					state.inputs.ng_table_spectera.tier_1_jobs,
					ecpProfits.current.spectera.ng_tier_1*(state.inputs.profit_pct_spectera/100)
				)
			)
		},
		vspChoice_newGrandTotalProfits (state, getters) {
			return (getters.vspChoice_newTotalProfits + getters.vspChoice_newTotalRebates)
		},
		vspChoice_totalMonthlyIncrease (state, getters) {
			return ((getters.hasVspChoice) ? getters.vspChoice_newGrandTotalProfits - getters.vspChoice_currentGrandTotalProfits : 0)
		},
		vspChoice_totalYearlyIncrease (state, getters) {
			return ((getters.hasVspChoice) ? getters.vspChoice_totalMonthlyIncrease*12 : 0)
		},
		vspSignature_newGrandTotalProfits (state, getters) {
			return (getters.vspSignature_newTotalProfits + getters.vspSignature_newTotalRebates)
		},
		vspSignature_totalMonthlyIncrease (state, getters) {
			return ((getters.hasVspSignature) ? getters.vspSignature_newGrandTotalProfits - getters.vspSignature_currentGrandTotalProfits : 0)
		},
		vspSignature_totalYearlyIncrease (state, getters) {
			return ((getters.hasVspSignature) ? getters.vspSignature_totalMonthlyIncrease*12 : 0)
		},
		eyemedAccess_newGrandTotalProfits (state, getters) {
			return (getters.eyemedAccess_newTotalProfits + getters.eyemedAccess_newTotalRebates)
		},
		eyemedAccess_totalMonthlyIncrease (state, getters) {
			return ((getters.hasEyemedAccess) ? getters.eyemedAccess_newGrandTotalProfits - getters.eyemedAccess_currentGrandTotalProfits : 0)
		},
		eyemedAccess_totalYearlyIncrease (state, getters) {
			return ((getters.hasEyemedAccess) ? getters.eyemedAccess_totalMonthlyIncrease*12 : 0)
		},
		eyemedInsight_newGrandTotalProfits (state, getters) {
			return (getters.eyemedInsight_newTotalProfits + getters.eyemedInsight_newTotalRebates)
		},
		eyemedInsight_totalMonthlyIncrease (state, getters) {
			return ((getters.hasEyemedInsight) ? getters.eyemedInsight_newGrandTotalProfits - getters.eyemedInsight_currentGrandTotalProfits : 0)
		},
		eyemedInsight_totalYearlyIncrease (state, getters) {
			return ((getters.hasEyemedInsight) ? getters.eyemedInsight_totalMonthlyIncrease*12 : 0)
		},
		versantDavis_newGrandTotalProfits (state, getters) {
			return (getters.versantDavis_newTotalProfits + getters.versantDavis_newTotalRebates)
		},
		versantDavis_totalMonthlyIncrease (state, getters) {
			return ((getters.hasVersantDavis) ? getters.versantDavis_newGrandTotalProfits - getters.versantDavis_currentGrandTotalProfits : 0)
		},
		versantDavis_totalYearlyIncrease (state, getters) {
			return ((getters.hasVersantDavis) ? getters.versantDavis_totalMonthlyIncrease*12 : 0)
		},
		versantSuperior_newGrandTotalProfits (state, getters) {
			return (getters.versantSuperior_newTotalProfits + getters.versantSuperior_newTotalRebates)
		},
		versantSuperior_totalMonthlyIncrease (state, getters) {
			return ((getters.hasVersantSuperior) ? getters.versantSuperior_newGrandTotalProfits - getters.versantSuperior_currentGrandTotalProfits : 0)
		},
		versantSuperior_totalYearlyIncrease (state, getters) {
			return ((getters.hasVersantSuperior) ? getters.versantSuperior_totalMonthlyIncrease*12 : 0)
		},
		spectera_newGrandTotalProfits (state, getters) {
			return (getters.spectera_newTotalProfits + getters.spectera_newTotalRebates)
		},
		spectera_totalMonthlyIncrease (state, getters) {
			return ((getters.hasSpectera) ? getters.spectera_newGrandTotalProfits - getters.spectera_currentGrandTotalProfits : 0)
		},
		spectera_totalYearlyIncrease (state, getters) {
			return ((getters.hasSpectera) ? getters.spectera_totalMonthlyIncrease*12 : 0)
		},
		grandMonthlyIncrease (state, getters) {
			return (
				getters.vspChoice_totalMonthlyIncrease +
				getters.vspSignature_totalMonthlyIncrease +
				getters.eyemedAccess_totalMonthlyIncrease +
				getters.eyemedInsight_totalMonthlyIncrease +
				getters.versantDavis_totalMonthlyIncrease +
				getters.versantSuperior_totalMonthlyIncrease +
				getters.spectera_totalMonthlyIncrease
			)
		},
		grandYearlyIncrease (state, getters) {
			return (
				getters.vspChoice_totalYearlyIncrease +
				getters.vspSignature_totalYearlyIncrease +
				getters.eyemedAccess_totalYearlyIncrease +
				getters.eyemedInsight_totalYearlyIncrease +
				getters.versantDavis_totalYearlyIncrease +
				getters.versantSuperior_totalYearlyIncrease +
				getters.spectera_totalYearlyIncrease
			)
		}
  },
  mutations: {
    login (state, email) {
			console.log('commiting ' + email)
			state.user_email = email
    },
    restoreInputs (state, newState) {
      state.inputs = newState
    },
    setRebatesNoGroup (state, rebateStates) {
      state.inputs.rebates.vsp = rebateStates.vsp.no_group
      state.inputs.rebates.eyemed = rebateStates.eyemed.no_group
      state.inputs.rebates.davis = rebateStates.davis.no_group
      state.inputs.rebates.spectera = rebateStates.spectera.no_group
    },
    setRebatesWithVS (state, rebateStates) {
      state.inputs.rebates.vsp = rebateStates.vsp.with_vs
      state.inputs.rebates.eyemed = rebateStates.eyemed.with_vs
      state.inputs.rebates.davis = rebateStates.davis.with_vs
      state.inputs.rebates.spectera = rebateStates.spectera.with_vs
    },
    setRebatesWithoutVS (state, rebateStates) {
      state.inputs.rebates.vsp = rebateStates.vsp.no_vs
      state.inputs.rebates.eyemed = rebateStates.eyemed.no_vs
      state.inputs.rebates.davis = rebateStates.davis.no_vs
      state.inputs.rebates.spectera = rebateStates.spectera.no_vs
    },
    setDefaultVspPalJobs (state) {
      state.inputs.pal_table_vsp.cat_o_jobs = Math.ceil(state.inputs.business_pct_mvc.vsp/100 * (state.inputs.monthly_progressives * state.inputs.pct_mvc/100));
    },
    setDefaultVspNGJobs (state) {
      state.inputs.ng_table_vsp.cat_c_jobs = Math.ceil(state.inputs.business_pct_mvc.vsp/100 * ((state.inputs.monthly_progressives + state.inputs.monthly_singlevision + state.inputs.monthly_bfft) * state.inputs.monthly_pct_ng/100 * state.inputs.pct_mvc/100));
    },
    setDefaultEyemedPalJobs (state) {
      state.inputs.pal_table_eyemed.tier_3_jobs = Math.ceil(state.inputs.business_pct_mvc.eyemed/100 * (state.inputs.monthly_progressives * state.inputs.pct_mvc/100));
    },
    setDefaultEyemedNGJobs (state) {
      state.inputs.ng_table_eyemed.tier_2_jobs = Math.ceil(state.inputs.business_pct_mvc.eyemed/100 * ((state.inputs.monthly_progressives + state.inputs.monthly_singlevision + state.inputs.monthly_bfft) * state.inputs.monthly_pct_ng/100 * state.inputs.pct_mvc/100));
    },
    setDefaultVersantPalJobs (state) {
      state.inputs.pal_table_davis.ultra_jobs = Math.ceil(state.inputs.business_pct_mvc.davis/100 * (state.inputs.monthly_progressives * state.inputs.pct_mvc/100));
    },
    setDefaultVersantNGJobs (state) {
      state.inputs.ng_table_davis.ultra_jobs = Math.ceil(state.inputs.business_pct_mvc.davis/100 * ((state.inputs.monthly_progressives + state.inputs.monthly_singlevision + state.inputs.monthly_bfft) * state.inputs.monthly_pct_ng/100 * state.inputs.pct_mvc/100));
    },
    setDefaultSpecteraPalJobs (state) {
      state.inputs.pal_table_spectera.tier_3_jobs = Math.ceil(state.inputs.business_pct_mvc.spectera/100 * (state.inputs.monthly_progressives * state.inputs.pct_mvc/100));
    },
    setDefaultSpecteraNGJobs (state) {
      state.inputs.ng_table_spectera.tier_3_jobs = Math.ceil(state.inputs.business_pct_mvc.spectera/100 * ((state.inputs.monthly_progressives + state.inputs.monthly_singlevision + state.inputs.monthly_bfft) * state.inputs.monthly_pct_ng/100 * state.inputs.pct_mvc/100));
    },
    lockVspPct (state) {
      state.inputs.pct_is_locked.vsp = true;
    },
    lockEyemedPct (state) {
      state.inputs.pct_is_locked.eyemed = true;
    },
    lockVersantPct (state) {
      state.inputs.pct_is_locked.davis = true;
    },
    lockSpecteraPct (state) {
      state.inputs.pct_is_locked.spectera = true;
    },
    lockOtherPct (state) {
      state.inputs.pct_is_locked.other = true;
    },
    rebalanceMvcPct (state, [ vspPct, eyemedPct, versantPct, specteraPct, otherPct ]) {
      state.inputs.business_pct_mvc.vsp = vspPct;
      state.inputs.business_pct_mvc.eyemed = eyemedPct;
      state.inputs.business_pct_mvc.davis = versantPct;
      state.inputs.business_pct_mvc.spectera = specteraPct;
      state.inputs.business_pct_mvc.other = otherPct;
    },
    rebalanceVspPct (state, [choice, signature]) {
      state.inputs.job_pct_vsp.choice = choice;
      state.inputs.job_pct_vsp.signature = signature;
    },
    rebalanceEyemedPct (state, [access, insight]) {
      state.inputs.job_pct_eyemed.access = access;
      state.inputs.job_pct_eyemed.insight = insight;
    },
    rebalanceVersantPct (state, [davis, superior]) {
      state.inputs.job_pct_versant.davis = davis;
      state.inputs.job_pct_versant.superior = superior;
    },
    setJobNumbersChanged (state, setTo) {
      state.inputs.jobNumbersChanged = setTo;
    }
  }
})
