<template>
  <div class="step">
    <Header 
      :stepNum="8"
      stepTitle="<b>Q8:</b> Lens Totals"
    />
    <div class="main-content">
        <div class="section s1" @click="setActiveSection($refs.s1Label)">
            <div class="section-header">
                <div class="section-number" ref="s1Label">1 / 2</div>
                <h2>Please fill in the totals of each lens type per month:</h2>
            </div>
            <div class="input-headers">
                    <div class="number-header">Total / month</div>
            </div>
            <div class="input-group">
                <div class="label"><span class="title">How many total progressive jobs do you do in one month?</span><span class="subtitle">(Private Pay + MVC)</span></div>
                <NumberInput v-model.number="inputs.monthly_progressives" />
            </div>
            <div class="input-group">
                <div class="label"><span class="title">How many total single vision jobs do you do in one month?</span><span class="subtitle">(Private Pay + MVC)</span></div>
                <NumberInput v-model.number="inputs.monthly_singlevision" />
            </div>
            <div class="input-group">
                <div class="label"><span class="title">How many total bifocal and flat top jobs do you do in one month?</span><span class="subtitle">(Private Pay + MVC)</span></div>
                <NumberInput v-model.number="inputs.monthly_bfft" />
            </div>
            <div class="summary">
                <div class="label">Total Jobs</div>
                <div class="number-input"><input type="number" :value="(inputs.monthly_progressives + inputs.monthly_singlevision + inputs.monthly_bfft)" readonly/></div>
            </div>
        </div>

        <div class="section s2" @click="setActiveSection($refs.s2Label)">
          <div class="section-header">
            <div class="section-number" ref="s2Label">2 / 2</div>
            <h2>What percentage of your total jobs have a no-glare treatment?</h2>
          </div>
          <div class="input-headers">
            <div class="pct-header">Percentage (%)</div>
          </div>
          <div class="input-group">
            <RangeInput v-model.number="inputs.monthly_pct_ng"/>
            <div class="number-input"><input v-model.number.lazy="inputs.monthly_pct_ng" @input="cleanSliderValue($event)" type="number" min="0" max="100" step="5"/></div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import RangeInput from '@/components/RangeInput.vue'
import NumberInput from '@/components/NumberInput.vue'
import sectionLabelling from '@/mixins/sectionLabelling.js'
import {mapState} from 'vuex'

export default {
	name: 'Step8',
  extends: sectionLabelling,
	components: {
			Header,
			RangeInput,
      NumberInput
	},
	computed: {
			...mapState(['inputs'])
	},
  mounted: function () {
    window.scrollTo(0, 0)
  },
	methods: {
		toTop: function() {
			window.scrollTo(0, 0);
		}
	},
  watch: {
    '$store.state.inputs.monthly_progressives': function() {
      this.$store.commit('setDefaultVspPalJobs');
      this.$store.commit('setDefaultVspNGJobs');
      this.$store.commit('setDefaultEyemedPalJobs');
      this.$store.commit('setDefaultEyemedNGJobs');
      this.$store.commit('setDefaultVersantPalJobs');
      this.$store.commit('setDefaultVersantNGJobs');
      this.$store.commit('setDefaultSpecteraPalJobs');
      this.$store.commit('setDefaultSpecteraNGJobs');
      this.$store.commit('setJobNumbersChanged',true);
    },
    '$store.state.inputs.monthly_singlevision': function() {
      this.$store.commit('setDefaultVspNGJobs');
      this.$store.commit('setDefaultEyemedNGJobs');
      this.$store.commit('setDefaultVersantNGJobs');
      this.$store.commit('setDefaultSpecteraNGJobs');
      this.$store.commit('setJobNumbersChanged',true);
    },
    '$store.state.inputs.monthly_bfft': function() {
      this.$store.commit('setDefaultVspNGJobs');
      this.$store.commit('setDefaultEyemedNGJobs');
      this.$store.commit('setDefaultVersantNGJobs');
      this.$store.commit('setDefaultSpecteraNGJobs');
      this.$store.commit('setJobNumbersChanged',true);
    },
    '$store.state.inputs.monthly_pct_ng': function() {
      this.$store.commit('setDefaultVspNGJobs');
      this.$store.commit('setDefaultEyemedNGJobs');
      this.$store.commit('setDefaultVersantNGJobs');
      this.$store.commit('setDefaultSpecteraNGJobs');
      this.$store.commit('setJobNumbersChanged',true);
    }
  }
}
</script>

<style scoped>
    .s1 .input-group {
      height: 80px;
      width: 777px;
      margin-bottom: 5px;
    }
    .s1 .input-group .label {
      width: 672px;
      background-color: #F7F7F7;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 16px;
      position: relative;
      margin-right: 5px;
    }
    .s1 .input-group .label .title {
      font-weight: bold;
      margin-left: 30px;
      max-width: 400px;
    }
    .s1 .input-group .label .subtitle {
      font-style: italic;
      position: absolute;
      right: 40px;
    }
    .s1 .input-group .number-input {
      width: 100px;
      height: 100%;
      background-color:  #F7F7F7;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0;
    }
    .s1 .input-headers::before {
			content: "\00a0";
			width: 809px;
		}
    .summary {
      width: 767px;
      height: 70px;
      margin-top: 15px;
      margin-right: 10px;
      border-top: 1px solid #E4E4E4;
      display: flex;
      justify-content: flex-end;
			padding-bottom: 30px;
    }
    .summary .label {
      height: 100%;
      font-weight: bold;
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-right: 20px;
    }
    .summary .number-input {
      margin-right: 0;
      margin-top: 10px;
    }
    .summary input[type=number] {
			background-color: #F3F3F3;
			border-color: #E4E4E4;
    }
    .s2 .input-headers::before {
			content: "\00a0";
			width: 670px;
		}
</style>
