<template>
	<div class="section s1" @click="setActiveSection($refs.s1Label)" v-if="$store.getters.hasVspChoice">
		<!-- VSP Choice -->
		<div class="section-header">
			<div class="inner-container">
				<div class="section-number" ref="s1Label"></div>
				<h2>VSP Choice</h2>
				<button class="toggle" v-bind:class="{open:expanded}" @click="toggleProducts($event,this.$refs.vspChoiceContent)"><span class="show">Maximize VSP Choice</span><span class="hide">Minimize VSP Choice</span></button>
			</div>
		</div>
		<div class="section-content" v-bind:class="{open:expanded}" ref="vspChoiceContent">
			<div class="input-headers">
				<div class="categories"></div>
				<div class="current">Current</div>
				<div class="new">New</div>
			</div>
			<ResultsSubheaders categoryTitle="PAL Categories:" />
			<ResultsInputGroup 
				categoryTitle="Category N" 
				productList="Kodak Unique DRO HD" 
				:currentTotalJobs="inputs.pal_table_vsp.cat_n_jobs" 
				:brandPercentage="inputs.job_pct_vsp.choice" 
				:currentEcpProfit="ecpProfits.current.vsp_choice.pal_cat_n" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_choice.pal_cat_n"
				:newEcpProfit="ecpProfits.new.vsp_choice.pal_cat_n"
				:rebate="this.inputs.rebates.vsp.pal_cat_n"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Category O" 
				productList="Kodak Unique DRO, Unique/ HD" 
				:currentTotalJobs="inputs.pal_table_vsp.cat_o_jobs" 
				:brandPercentage="inputs.job_pct_vsp.choice" 
				:currentEcpProfit="ecpProfits.current.vsp_choice.pal_cat_o" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_choice.pal_cat_o"
				:newEcpProfit="ecpProfits.new.vsp_choice.pal_cat_o"
				:rebate="this.inputs.rebates.vsp.pal_cat_o"
			/>
			<ResultsInputGroup 
				categoryTitle="Category F" 
				productList="Kodak Precise Plus / Short Plus" 
				:currentTotalJobs="inputs.pal_table_vsp.cat_f_jobs" 
				:brandPercentage="inputs.job_pct_vsp.choice" 
				:currentEcpProfit="ecpProfits.current.vsp_choice.pal_cat_f" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_choice.pal_cat_f"
				:newEcpProfit="ecpProfits.new.vsp_choice.pal_cat_f"
				:rebate="this.inputs.rebates.vsp.pal_cat_f"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Category J" 
				productList="Kodak Precise PB / Short PB, Kodak Precise / Short" 
				:currentTotalJobs="inputs.pal_table_vsp.cat_j_jobs" 
				:brandPercentage="inputs.job_pct_vsp.choice" 
				:currentEcpProfit="ecpProfits.current.vsp_choice.pal_cat_j" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_choice.pal_cat_j"
				:newEcpProfit="ecpProfits.new.vsp_choice.pal_cat_j"
				:rebate="this.inputs.rebates.vsp.pal_cat_j"
			/>
			<ResultsInputGroup 
				categoryTitle="Category K" 
				productList="Kodak Concise, Kodak Easy" 
				:currentTotalJobs="inputs.pal_table_vsp.cat_k_jobs" 
				:brandPercentage="inputs.job_pct_vsp.choice" 
				:currentEcpProfit="ecpProfits.current.vsp_choice.pal_cat_k" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_choice.pal_cat_k"
				:newEcpProfit="ecpProfits.new.vsp_choice.pal_cat_k"
				:rebate="this.inputs.rebates.vsp.pal_cat_k"
			/>
			<ResultsTotalsSection 
				:currentTotalJobs="[inputs.pal_table_vsp.cat_n_jobs,inputs.pal_table_vsp.cat_o_jobs,inputs.pal_table_vsp.cat_f_jobs,inputs.pal_table_vsp.cat_j_jobs,inputs.pal_table_vsp.cat_k_jobs]"
				:newTotalJobs="[inputs.new_monthly_jobs.vsp_choice.pal_cat_n,inputs.new_monthly_jobs.vsp_choice.pal_cat_o,inputs.new_monthly_jobs.vsp_choice.pal_cat_f,inputs.new_monthly_jobs.vsp_choice.pal_cat_j,inputs.new_monthly_jobs.vsp_choice.pal_cat_k]"
				:jobPct="inputs.job_pct_vsp.choice" />
			<!-- <div class="section-filler x2"><div class="categories"></div><div class="current"></div><div class="new"></div></div> -->
			<ResultsSubheaders categoryTitle="No-Glare Categories:" />
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Category D (backside UV)" 
				productList="Crizal Sapphire 360 UV, Crizal Prevencia, Crizal Rock, Crizal Avance UV, Kodak Clean&amp;CleAR UV / with Silk" 
				:brandPercentage="inputs.job_pct_vsp.choice" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_choice.ng_cat_d_back"
				:newEcpProfit="ecpProfits.new.vsp_choice.ng_cat_d_back"
			/>
			<ResultsInputGroup 
				categoryTitle="Category D" 
				productList="Kodak Clean &amp; CleAR / with Silk, Total Blue" 
				:currentTotalJobs="inputs.ng_table_vsp.cat_d_jobs" 
				:brandPercentage="inputs.job_pct_vsp.choice" 
				:currentEcpProfit="ecpProfits.current.vsp_choice.ng_cat_d" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_choice.ng_cat_d"
				:newEcpProfit="ecpProfits.new.vsp_choice.ng_cat_d"
				:rebate="this.inputs.rebates.vsp.ng_cat_d"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Category C (backside UV)" 
				productList="Crizal Alize UV" 
				:brandPercentage="inputs.job_pct_vsp.choice" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_choice.ng_cat_c_back"
				:newEcpProfit="ecpProfits.new.vsp_choice.ng_cat_c_back"
			/>
			<ResultsInputGroup 
				categoryTitle="Category C" 
				productList="Kodak CleAR" 
				:currentTotalJobs="inputs.ng_table_vsp.cat_c_jobs" 
				:brandPercentage="inputs.job_pct_vsp.choice" 
				:currentEcpProfit="ecpProfits.current.vsp_choice.ng_cat_c" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_choice.ng_cat_c"
				:newEcpProfit="ecpProfits.new.vsp_choice.ng_cat_c"
				:rebate="this.inputs.rebates.vsp.ng_cat_c"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Category B" 
				productList="Crizal Easy UV" 
				:currentTotalJobs="inputs.ng_table_vsp.cat_b_jobs" 
				:brandPercentage="inputs.job_pct_vsp.choice" 
				:currentEcpProfit="ecpProfits.current.vsp_choice.ng_cat_b" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_choice.ng_cat_b"
				:newEcpProfit="ecpProfits.new.vsp_choice.ng_cat_b"
				:rebate="this.inputs.rebates.vsp.ng_cat_b"
			/>
			<ResultsInputGroup 
				categoryTitle="Category A" 
				productList="BluCrystal" 
				:currentTotalJobs="inputs.ng_table_vsp.cat_a_jobs" 
				:brandPercentage="inputs.job_pct_vsp.choice" 
				:currentEcpProfit="ecpProfits.current.vsp_choice.ng_cat_a" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_choice.ng_cat_a"
				:newEcpProfit="ecpProfits.new.vsp_choice.ng_cat_a"
				:rebate="this.inputs.rebates.vsp.ng_cat_a"
			/>
			<ResultsTotalsSection 
				:currentTotalJobs="[inputs.ng_table_vsp.cat_d_jobs,inputs.ng_table_vsp.cat_c_jobs,inputs.ng_table_vsp.cat_b_jobs,inputs.ng_table_vsp.cat_a_jobs]"
				:newTotalJobs="[inputs.new_monthly_jobs.vsp_choice.ng_cat_d_back,inputs.new_monthly_jobs.vsp_choice.ng_cat_d,inputs.new_monthly_jobs.vsp_choice.ng_cat_c_back,inputs.new_monthly_jobs.vsp_choice.ng_cat_c,inputs.new_monthly_jobs.vsp_choice.ng_cat_b,inputs.new_monthly_jobs.vsp_choice.ng_cat_a]"
				:jobPct="inputs.job_pct_vsp.choice"
				:newTotalProfits="$store.getters.vspChoice_newTotalProfits" 
				:newTotalRebates="$store.getters.vspChoice_newTotalRebates" />
			<ResultsGrandTotalsSection :currentGrandTotalProfits="$store.getters.vspChoice_currentGrandTotalProfits" :newGrandTotalProfits="$store.getters.vspChoice_newGrandTotalProfits" />
			<ResultsSummarySection :totalMonthlyIncrease="$store.getters.vspChoice_totalMonthlyIncrease" :totalYearlyIncrease="$store.getters.vspChoice_totalYearlyIncrease" />
		</div>
    <div class="watermark" v-if="watermarked">{{$store.state.user_email}}</div>
	</div>
	<!-- VSP Signature -->
	<div class="section s2" @click="setActiveSection($refs.s2Label)" v-if="$store.getters.hasVspSignature">
		<div class="section-header">
			<div class="inner-container">
				<div class="section-number" ref="s2Label"></div>
				<h2>VSP Signature</h2>
				<button class="toggle" v-bind:class="{open:expanded}" @click="toggleProducts($event,this.$refs.vspSignatureContent)"><span class="show">Maximize VSP Signature</span><span class="hide">Minimize VSP Signature</span></button>
			</div>
		</div>
		<div class="section-content" v-bind:class="{open:expanded}" ref="vspSignatureContent">
			<div class="input-headers">
				<div class="categories"></div>
				<div class="current">Current</div>
				<div class="new">New</div>
			</div>
			<ResultsSubheaders categoryTitle="PAL Categories:" />
			<ResultsInputGroup 
				categoryTitle="Category N" 
				productList="Kodak Unique DRO HD" 
				:currentTotalJobs="inputs.pal_table_vsp.cat_n_jobs" 
				:brandPercentage="inputs.job_pct_vsp.signature" 
				:currentEcpProfit="ecpProfits.current.vsp_signature.pal_cat_n" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_signature.pal_cat_n"
				:newEcpProfit="ecpProfits.new.vsp_signature.pal_cat_n"
				:rebate="this.inputs.rebates.vsp.pal_cat_n"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Category O" 
				productList="Kodak Unique DRO, Unique/ HD" 
				:currentTotalJobs="inputs.pal_table_vsp.cat_o_jobs" 
				:brandPercentage="inputs.job_pct_vsp.signature" 
				:currentEcpProfit="ecpProfits.current.vsp_signature.pal_cat_o" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_signature.pal_cat_o"
				:newEcpProfit="ecpProfits.new.vsp_signature.pal_cat_o"
				:rebate="this.inputs.rebates.vsp.pal_cat_o"
			/>
			<ResultsInputGroup 
				categoryTitle="Category F" 
				productList="Kodak Precise Plus / Short Plus" 
				:currentTotalJobs="inputs.pal_table_vsp.cat_f_jobs" 
				:brandPercentage="inputs.job_pct_vsp.signature" 
				:currentEcpProfit="ecpProfits.current.vsp_signature.pal_cat_f" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_signature.pal_cat_f"
				:newEcpProfit="ecpProfits.new.vsp_signature.pal_cat_f"
				:rebate="this.inputs.rebates.vsp.pal_cat_f"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Category J" 
				productList="Kodak Precise PB / Short PB, Kodak Precise / Short" 
				:currentTotalJobs="inputs.pal_table_vsp.cat_j_jobs" 
				:brandPercentage="inputs.job_pct_vsp.signature" 
				:currentEcpProfit="ecpProfits.current.vsp_signature.pal_cat_j" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_signature.pal_cat_j"
				:newEcpProfit="ecpProfits.new.vsp_signature.pal_cat_j"
				:rebate="this.inputs.rebates.vsp.pal_cat_j"
			/>
			<ResultsInputGroup 
				categoryTitle="Category K" 
				productList="Kodak Concise, Kodak Easy" 
				:currentTotalJobs="inputs.pal_table_vsp.cat_k_jobs" 
				:brandPercentage="inputs.job_pct_vsp.signature" 
				:currentEcpProfit="ecpProfits.current.vsp_signature.pal_cat_k" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_signature.pal_cat_k"
				:newEcpProfit="ecpProfits.new.vsp_signature.pal_cat_k"
				:rebate="this.inputs.rebates.vsp.pal_cat_k"
			/>
			<ResultsTotalsSection 
				:currentTotalJobs="[inputs.pal_table_vsp.cat_n_jobs,inputs.pal_table_vsp.cat_o_jobs,inputs.pal_table_vsp.cat_f_jobs,inputs.pal_table_vsp.cat_j_jobs,inputs.pal_table_vsp.cat_k_jobs]"
				:newTotalJobs="[inputs.new_monthly_jobs.vsp_signature.pal_cat_n,inputs.new_monthly_jobs.vsp_signature.pal_cat_o,inputs.new_monthly_jobs.vsp_signature.pal_cat_f,inputs.new_monthly_jobs.vsp_signature.pal_cat_j,inputs.new_monthly_jobs.vsp_signature.pal_cat_k]"
				:jobPct="inputs.job_pct_vsp.signature"/>
			<ResultsSubheaders categoryTitle="No-Glare Categories:" />
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Category D (backside UV)" 
				productList="Crizal Sapphire 360 UV, Crizal Prevencia, Crizal Rock, Crizal Avance UV, Kodak Clean&amp;CleAR UV / with Silk" 
				:brandPercentage="inputs.job_pct_vsp.signature" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_signature.ng_cat_d_back"
				:newEcpProfit="ecpProfits.new.vsp_signature.ng_cat_d_back"
			/>
			<ResultsInputGroup 
				categoryTitle="Category D" 
				productList="Kodak Clean &amp; CleAR / with Silk, Total Blue" 
				:currentTotalJobs="inputs.ng_table_vsp.cat_d_jobs" 
				:brandPercentage="inputs.job_pct_vsp.signature" 
				:currentEcpProfit="ecpProfits.current.vsp_signature.ng_cat_d" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_signature.ng_cat_d"
				:newEcpProfit="ecpProfits.new.vsp_signature.ng_cat_d"
				:rebate="this.inputs.rebates.vsp.ng_cat_d"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Category C (backside UV)" 
				productList="Crizal Alize UV" 
				:brandPercentage="inputs.job_pct_vsp.signature" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_signature.ng_cat_c_back"
				:newEcpProfit="ecpProfits.new.vsp_signature.ng_cat_c_back"
			/>
			<ResultsInputGroup 
				categoryTitle="Category C" 
				productList="Kodak CleAR" 
				:currentTotalJobs="inputs.ng_table_vsp.cat_c_jobs" 
				:brandPercentage="inputs.job_pct_vsp.signature" 
				:currentEcpProfit="ecpProfits.current.vsp_signature.ng_cat_c" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_signature.ng_cat_c"
				:newEcpProfit="ecpProfits.new.vsp_signature.ng_cat_c"
				:rebate="this.inputs.rebates.vsp.ng_cat_c"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Category B" 
				productList="Crizal Easy UV" 
				:currentTotalJobs="inputs.ng_table_vsp.cat_b_jobs" 
				:brandPercentage="inputs.job_pct_vsp.signature" 
				:currentEcpProfit="ecpProfits.current.vsp_signature.ng_cat_b" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_signature.ng_cat_b"
				:newEcpProfit="ecpProfits.new.vsp_signature.ng_cat_b"
				:rebate="this.inputs.rebates.vsp.ng_cat_b"
			/>
			<ResultsInputGroup 
				categoryTitle="Category A" 
				productList="BluCrystal" 
				:currentTotalJobs="inputs.ng_table_vsp.cat_a_jobs" 
				:brandPercentage="inputs.job_pct_vsp.signature" 
				:currentEcpProfit="ecpProfits.current.vsp_signature.ng_cat_a" 
				v-model:newJobsState="inputs.new_monthly_jobs.vsp_signature.ng_cat_a"
				:newEcpProfit="ecpProfits.new.vsp_signature.ng_cat_a"
				:rebate="this.inputs.rebates.vsp.ng_cat_a"
			/>
			<ResultsTotalsSection 
				:currentTotalJobs="[inputs.ng_table_vsp.cat_d_jobs,inputs.ng_table_vsp.cat_c_jobs,inputs.ng_table_vsp.cat_b_jobs,inputs.ng_table_vsp.cat_a_jobs]"
				:newTotalJobs="[inputs.new_monthly_jobs.vsp_signature.ng_cat_d_back,inputs.new_monthly_jobs.vsp_signature.ng_cat_d,inputs.new_monthly_jobs.vsp_signature.ng_cat_c_back,inputs.new_monthly_jobs.vsp_signature.ng_cat_c,inputs.new_monthly_jobs.vsp_signature.ng_cat_b,inputs.new_monthly_jobs.vsp_signature.ng_cat_a]"
				:jobPct="inputs.job_pct_vsp.signature"
				:newTotalProfits="$store.getters.vspSignature_newTotalProfits" 
				:newTotalRebates="$store.getters.vspSignature_newTotalRebates" />
			<ResultsGrandTotalsSection :currentGrandTotalProfits="$store.getters.vspSignature_currentGrandTotalProfits" :newGrandTotalProfits="$store.getters.vspSignature_newGrandTotalProfits" />
			<ResultsSummarySection :totalMonthlyIncrease="$store.getters.vspSignature_totalMonthlyIncrease" :totalYearlyIncrease="$store.getters.vspSignature_totalYearlyIncrease" />
		</div>
    <div class="watermark" v-if="watermarked">{{$store.state.user_email}}</div>
	</div>
	<!-- EyeMed Access -->
	<div class="section s3" @click="setActiveSection($refs.s3Label)" v-if="$store.getters.hasEyemedAccess">
		<div class="section-header">
			<div class="inner-container">
				<div class="section-number" ref="s3Label"></div>
				<h2>EyeMed Access</h2>
				<button class="toggle" v-bind:class="{open:expanded}" @click="toggleProducts($event,this.$refs.eyemedAccessContent)"><span class="show">Maximize EyeMed Access</span><span class="hide">Minimize EyeMed Access</span></button>
			</div>
		</div>
		<div class="section-content" v-bind:class="{open:expanded}" ref="eyemedAccessContent">
			<div class="input-headers">
				<div class="categories"></div>
				<div class="current">Current</div>
				<div class="new">New</div>
			</div>
			<ResultsSubheaders categoryTitle="PAL Categories:" />
			<ResultsInputGroup 
				categoryTitle="Tier 4" 
				productList="Kodak Unique DRO HD, Unique HD" 
				:currentTotalJobs="inputs.pal_table_eyemed.tier_4_jobs" 
				:brandPercentage="inputs.job_pct_eyemed.access" 
				:currentEcpProfit="$store.getters.eyemedAccess_pal_tier4_cur_price" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_access.pal_tier_4"
				:newEcpProfit="$store.getters.eyemedAccess_pal_tier4_new_price"
				:rebate="this.inputs.rebates.eyemed.pal_tier_4"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Tier 3" 
				productList="Kodak Unique DRO / Unique, Precise Digital/Short" 
				:currentTotalJobs="inputs.pal_table_eyemed.tier_3_jobs" 
				:brandPercentage="inputs.job_pct_eyemed.access" 
				:currentEcpProfit="$store.getters.eyemedAccess_pal_tier3_cur_price" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_access.pal_tier_3"
				:newEcpProfit="$store.getters.eyemedAccess_pal_tier3_new_price"
				:rebate="this.inputs.rebates.eyemed.pal_tier_3"
			/>
			<ResultsInputGroup 
				categoryTitle="Tier 2" 
				productList="Kodak Precise PB / Short PB" 
				:currentTotalJobs="inputs.pal_table_eyemed.tier_2_jobs" 
				:brandPercentage="inputs.job_pct_eyemed.access" 
				:currentEcpProfit="$store.getters.eyemedAccess_pal_tier2_cur_price" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_access.pal_tier_2"
				:newEcpProfit="$store.getters.eyemedAccess_pal_tier2_new_price"
				:rebate="this.inputs.rebates.eyemed.pal_tier_2"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Tier 1" 
				productList="Kodak Precise / Short, Concise" 
				:currentTotalJobs="inputs.pal_table_eyemed.tier_1_jobs" 
				:brandPercentage="inputs.job_pct_eyemed.access" 
				:currentEcpProfit="$store.getters.eyemedAccess_pal_tier1_cur_price" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_access.pal_tier_1"
				:newEcpProfit="$store.getters.eyemedAccess_pal_tier1_new_price"
				:rebate="this.inputs.rebates.eyemed.pal_tier_1"
			/>
			<ResultsInputGroup 
				categoryTitle="Standard" 
				productList="Kodak Easy, Kodak SoftWear, Signet Navigator/Short" 
				:currentTotalJobs="inputs.pal_table_eyemed.standard_jobs" 
				:brandPercentage="inputs.job_pct_eyemed.access" 
				:currentEcpProfit="ecpProfits.current.eyemed_access.pal_tier_standard" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_access.pal_tier_standard"
				:newEcpProfit="ecpProfits.new.eyemed_access.pal_tier_standard"
				:rebate="this.inputs.rebates.eyemed.pal_tier_standard"
			/>
			<ResultsTotalsSection 
				:currentTotalJobs="[inputs.pal_table_eyemed.tier_4_jobs,inputs.pal_table_eyemed.tier_3_jobs,inputs.pal_table_eyemed.tier_2_jobs,inputs.pal_table_eyemed.tier_1_jobs,inputs.pal_table_eyemed.standard_jobs]"
				:newTotalJobs="[inputs.new_monthly_jobs.eyemed_access.pal_tier_4,inputs.new_monthly_jobs.eyemed_access.pal_tier_3,inputs.new_monthly_jobs.eyemed_access.pal_tier_2,inputs.new_monthly_jobs.eyemed_access.pal_tier_1,inputs.new_monthly_jobs.eyemed_access.pal_tier_standard]"
				:jobPct="inputs.job_pct_eyemed.access"/>
			<ResultsSubheaders categoryTitle="No-Glare Categories:" />
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Tier 3 (Lab Group H)" 
				productList="Crizal Sapphire 360 UV, Crizal Prevencia"
				:currentTotalJobs="inputs.ng_table_eyemed.lab_h_jobs" 
				:brandPercentage="inputs.job_pct_eyemed.access" 
				:currentEcpProfit="$store.getters.eyemedAccess_ng_labh_cur_price" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_access.ng_lab_h"
				:newEcpProfit="$store.getters.eyemedAccess_ng_labh_new_price"
				:rebate="this.inputs.rebates.eyemed.ng_lab_h"
			/>
			<ResultsInputGroup 
				categoryTitle="Tier 3 (Lab Group K)" 
				productList="Crizal Rock, Crizal Avance UV, Kodak Clean&amp;CleAR with Silk / UV with Silk"
				:currentTotalJobs="inputs.ng_table_eyemed.lab_k_jobs" 
				:brandPercentage="inputs.job_pct_eyemed.access" 
				:currentEcpProfit="$store.getters.eyemedAccess_ng_labk_cur_price" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_access.ng_lab_k"
				:newEcpProfit="$store.getters.eyemedAccess_ng_labk_new_price"
				:rebate="this.inputs.rebates.eyemed.ng_lab_k"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Tier 2 (backside UV)"
				productList="Crizal Alize UV, Kodak Clean&amp;CleAR" 
				:brandPercentage="inputs.job_pct_eyemed.access" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_access.ng_tier_2_back"
				:newEcpProfit="$store.getters.eyemedAccess_ng_tier2back_new_price"
			/>
			<ResultsInputGroup 
				categoryTitle="Tier 2" 
				productList="Kodak Clean&amp;CleAR, TotalBlue with Silk" 
				:currentTotalJobs="inputs.ng_table_eyemed.tier_2_jobs" 
				:brandPercentage="inputs.job_pct_eyemed.access" 
				:currentEcpProfit="$store.getters.eyemedAccess_ng_tier2_cur_price" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_access.ng_tier_2"
				:newEcpProfit="$store.getters.eyemedAccess_ng_tier2_new_price"
				:rebate="this.inputs.rebates.eyemed.ng_tier_2"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Tier 1 (backside UV)"
				productList="Crizal Easy UV" 
				:brandPercentage="inputs.job_pct_eyemed.access" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_access.ng_tier_1_back"
				:newEcpProfit="$store.getters.eyemedAccess_ng_tier1back_new_price"
			/>
			<ResultsInputGroup 
				categoryTitle="Tier 1" 
				productList="Kodak CleAR" 
				:currentTotalJobs="inputs.ng_table_eyemed.tier_1_jobs" 
				:brandPercentage="inputs.job_pct_eyemed.access" 
				:currentEcpProfit="$store.getters.eyemedAccess_ng_tier1_cur_price" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_access.ng_tier_1"
				:newEcpProfit="$store.getters.eyemedAccess_ng_tier1_new_price"
				:rebate="this.inputs.rebates.eyemed.ng_tier_1"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Standard" 
				productList="Sharpview" 
				:currentTotalJobs="inputs.ng_table_eyemed.standard_jobs" 
				:brandPercentage="inputs.job_pct_eyemed.access" 
				:currentEcpProfit="ecpProfits.current.eyemed_access.ng_tier_standard" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_access.ng_tier_standard"
				:newEcpProfit="ecpProfits.new.eyemed_access.ng_tier_standard"
				:rebate="this.inputs.rebates.eyemed.ng_tier_standard"
			/>
			<ResultsTotalsSection 
				:currentTotalJobs="[inputs.ng_table_eyemed.lab_h_jobs,inputs.ng_table_eyemed.lab_k_jobs,inputs.ng_table_eyemed.tier_2_jobs,inputs.ng_table_eyemed.tier_1_jobs,inputs.ng_table_eyemed.standard_jobs]"
				:newTotalJobs="[inputs.new_monthly_jobs.eyemed_access.ng_lab_h,inputs.new_monthly_jobs.eyemed_access.ng_lab_k,inputs.new_monthly_jobs.eyemed_access.ng_tier_2_back,inputs.new_monthly_jobs.eyemed_access.ng_tier_2,inputs.new_monthly_jobs.eyemed_access.ng_tier_1_back,inputs.new_monthly_jobs.eyemed_access.ng_tier_1,inputs.new_monthly_jobs.eyemed_access.ng_tier_standard]"
				:jobPct="inputs.job_pct_eyemed.access"
				:newTotalProfits="$store.getters.eyemedAccess_newTotalProfits" 
				:newTotalRebates="$store.getters.eyemedAccess_newTotalRebates" />
			<ResultsGrandTotalsSection :currentGrandTotalProfits="$store.getters.eyemedAccess_currentGrandTotalProfits" :newGrandTotalProfits="$store.getters.eyemedAccess_newGrandTotalProfits" />
			<ResultsSummarySection :totalMonthlyIncrease="$store.getters.eyemedAccess_totalMonthlyIncrease" :totalYearlyIncrease="$store.getters.eyemedAccess_totalYearlyIncrease" />
		</div>
    <div class="watermark" v-if="watermarked">{{$store.state.user_email}}</div>
	</div>
	<!-- Eyemed Insight -->
	<div class="section s4" @click="setActiveSection($refs.s4Label)" v-if="$store.getters.hasEyemedInsight">
		<div class="section-header">
			<div class="inner-container">
				<div class="section-number" ref="s4Label"></div>
				<h2>EyeMed Insight</h2>
				<button class="toggle" v-bind:class="{open:expanded}" @click="toggleProducts($event,this.$refs.eyemedInsightContent)"><span class="show">Maximize EyeMed Insight</span><span class="hide">Minimize EyeMed Insight</span></button>
			</div>
		</div>
		<div class="section-content" v-bind:class="{open:expanded}" ref="eyemedInsightContent">
			<div class="input-headers">
				<div class="categories"></div>
				<div class="current">Current</div>
				<div class="new">New</div>
			</div>
			<ResultsSubheaders categoryTitle="PAL Categories:" />
			<ResultsInputGroup 
				categoryTitle="Tier 4" 
				productList="Kodak Unique DRO HD, Unique HD" 
				:currentTotalJobs="inputs.pal_table_eyemed.tier_4_jobs" 
				:brandPercentage="inputs.job_pct_eyemed.insight" 
				:currentEcpProfit="$store.getters.eyemedInsight_pal_tier4_cur_price" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_insight.pal_tier_4"
				:newEcpProfit="$store.getters.eyemedInsight_pal_tier4_new_price"
				:rebate="this.inputs.rebates.eyemed.pal_tier_4"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Tier 3" 
				productList="Kodak Unique DRO / Unique, Precise Digital/Short" 
				:currentTotalJobs="inputs.pal_table_eyemed.tier_3_jobs" 
				:brandPercentage="inputs.job_pct_eyemed.insight" 
				:currentEcpProfit="ecpProfits.current.eyemed_insight.pal_tier_3" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_insight.pal_tier_3"
				:newEcpProfit="ecpProfits.new.eyemed_insight.pal_tier_3"
				:rebate="this.inputs.rebates.eyemed.pal_tier_3"
			/>
			<ResultsInputGroup 
				categoryTitle="Tier 2" 
				productList="Kodak Precise PB / Short PB" 
				:currentTotalJobs="inputs.pal_table_eyemed.tier_2_jobs" 
				:brandPercentage="inputs.job_pct_eyemed.insight" 
				:currentEcpProfit="ecpProfits.current.eyemed_insight.pal_tier_2" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_insight.pal_tier_2"
				:newEcpProfit="ecpProfits.new.eyemed_insight.pal_tier_2"
				:rebate="this.inputs.rebates.eyemed.pal_tier_2"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Tier 1" 
				productList="Kodak Precise / Short, Concise" 
				:currentTotalJobs="inputs.pal_table_eyemed.tier_1_jobs" 
				:brandPercentage="inputs.job_pct_eyemed.insight" 
				:currentEcpProfit="ecpProfits.current.eyemed_insight.pal_tier_1" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_insight.pal_tier_1"
				:newEcpProfit="ecpProfits.new.eyemed_insight.pal_tier_1"
				:rebate="this.inputs.rebates.eyemed.pal_tier_1"
			/>
			<ResultsInputGroup 
				categoryTitle="Standard" 
				productList="Kodak Easy, Kodak SoftWear, Signet Navigator/Short" 
				:currentTotalJobs="inputs.pal_table_eyemed.standard_jobs" 
				:brandPercentage="inputs.job_pct_eyemed.insight" 
				:currentEcpProfit="ecpProfits.current.eyemed_insight.pal_tier_standard" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_insight.pal_tier_standard"
				:newEcpProfit="ecpProfits.new.eyemed_insight.pal_tier_standard"
				:rebate="this.inputs.rebates.eyemed.pal_tier_standard"
			/>
			<ResultsTotalsSection 
				:currentTotalJobs="[inputs.pal_table_eyemed.tier_4_jobs,inputs.pal_table_eyemed.tier_3_jobs,inputs.pal_table_eyemed.tier_2_jobs,inputs.pal_table_eyemed.tier_1_jobs,inputs.pal_table_eyemed.standard_jobs]"
				:newTotalJobs="[inputs.new_monthly_jobs.eyemed_insight.pal_tier_4,inputs.new_monthly_jobs.eyemed_insight.pal_tier_3,inputs.new_monthly_jobs.eyemed_insight.pal_tier_2,inputs.new_monthly_jobs.eyemed_insight.pal_tier_1,inputs.new_monthly_jobs.eyemed_insight.pal_tier_standard]"
				:jobPct="inputs.job_pct_eyemed.insight" />
			<ResultsSubheaders categoryTitle="No-Glare Categories:" />
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Tier 3 (Lab Group H)" 
				productList="Crizal Sapphire 360 UV, Crizal Prevencia"
				:currentTotalJobs="inputs.ng_table_eyemed.lab_h_jobs" 
				:brandPercentage="inputs.job_pct_eyemed.insight" 
				:currentEcpProfit="$store.getters.eyemedInsight_ng_labh_cur_price3" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_insight.ng_lab_h"
				:newEcpProfit="$store.getters.eyemedInsight_ng_labh_new_price"
				:rebate="this.inputs.rebates.eyemed.ng_lab_h"
			/>
			<ResultsInputGroup 
				categoryTitle="Tier 3 (Lab Group K)" 
				productList="Crizal Rock, Crizal Avance UV, Kodak Clean&amp;CleAR with Silk / UV with Silk"
				:currentTotalJobs="inputs.ng_table_eyemed.lab_k_jobs" 
				:brandPercentage="inputs.job_pct_eyemed.insight" 
				:currentEcpProfit="$store.getters.eyemedInsight_ng_labk_cur_price" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_insight.ng_lab_k"
				:newEcpProfit="$store.getters.eyemedInsight_ng_labk_new_price"
				:rebate="this.inputs.rebates.eyemed.ng_lab_k"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Tier 2" 
				productList="Kodak Clean&amp;CleAR, TotalBlue with Silk" 
				:currentTotalJobs="inputs.ng_table_eyemed.tier_2_jobs" 
				:brandPercentage="inputs.job_pct_eyemed.insight" 
				:currentEcpProfit="ecpProfits.current.eyemed_insight.ng_tier_2" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_insight.ng_tier_2"
				:newEcpProfit="ecpProfits.new.eyemed_insight.ng_tier_2"
				:rebate="this.inputs.rebates.eyemed.ng_tier_2"
			/>
			<ResultsInputGroup 
				categoryTitle="Tier 1" 
				productList="Kodak CleAR" 
				:currentTotalJobs="inputs.ng_table_eyemed.tier_1_jobs" 
				:brandPercentage="inputs.job_pct_eyemed.insight" 
				:currentEcpProfit="ecpProfits.current.eyemed_insight.ng_tier_1" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_insight.ng_tier_1"
				:newEcpProfit="ecpProfits.new.eyemed_insight.ng_tier_1"
				:rebate="this.inputs.rebates.eyemed.ng_tier_1"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Standard" 
				productList="Sharpview" 
				:currentTotalJobs="inputs.ng_table_eyemed.standard_jobs" 
				:brandPercentage="inputs.job_pct_eyemed.insight" 
				:currentEcpProfit="ecpProfits.current.eyemed_insight.ng_tier_standard" 
				v-model:newJobsState="inputs.new_monthly_jobs.eyemed_insight.ng_tier_standard"
				:newEcpProfit="ecpProfits.new.eyemed_insight.ng_tier_standard"
				:rebate="this.inputs.rebates.eyemed.ng_tier_standard"
			/>
			<ResultsTotalsSection 
				:currentTotalJobs="[inputs.ng_table_eyemed.lab_h_jobs,inputs.ng_table_eyemed.lab_k_jobs,inputs.ng_table_eyemed.tier_2_jobs,inputs.ng_table_eyemed.tier_1_jobs,inputs.ng_table_eyemed.standard_jobs]"
				:newTotalJobs="[inputs.new_monthly_jobs.eyemed_insight.ng_lab_h,inputs.new_monthly_jobs.eyemed_insight.ng_lab_k,inputs.new_monthly_jobs.eyemed_insight.ng_tier_2,inputs.new_monthly_jobs.eyemed_insight.ng_tier_1,inputs.new_monthly_jobs.eyemed_insight.ng_tier_standard]"
				:jobPct="inputs.job_pct_eyemed.insight"
				:newTotalProfits="$store.getters.eyemedInsight_newTotalProfits" 
				:newTotalRebates="$store.getters.eyemedInsight_newTotalRebates" />
			<ResultsGrandTotalsSection :currentGrandTotalProfits="$store.getters.eyemedInsight_currentGrandTotalProfits" :newGrandTotalProfits="$store.getters.eyemedInsight_newGrandTotalProfits" />
			<ResultsSummarySection :totalMonthlyIncrease="$store.getters.eyemedInsight_totalMonthlyIncrease" :totalYearlyIncrease="$store.getters.eyemedInsight_totalYearlyIncrease" />
		</div>
    <div class="watermark" v-if="watermarked">{{$store.state.user_email}}</div>
	</div>
	<!-- Versant Davis -->
	<div class="section s5" @click="setActiveSection($refs.s5Label)" v-if="$store.getters.hasVersantDavis">
		<div class="section-header">
			<div class="inner-container">
				<div class="section-number" ref="s5Label"></div>
				<h2>Davis Vision</h2>
				<button class="toggle" v-bind:class="{open:expanded}" @click="toggleProducts($event,this.$refs.versantDavisContent)"><span class="show">Maximize Davis Vision</span><span class="hide">Minimize Davis Vision</span></button>
			</div>
		</div>
		<div class="section-content" v-bind:class="{open:expanded}" ref="versantDavisContent">
			<div class="input-headers">
				<div class="categories"></div>
				<div class="current">Current</div>
				<div class="new">New</div>
			</div>
			<ResultsSubheaders categoryTitle="PAL Categories:" />
			<ResultsInputGroup 
				categoryTitle="Ultimate" 
				productList="" 
				:currentTotalJobs="inputs.pal_table_davis.ultimate_jobs" 
				:brandPercentage="inputs.job_pct_versant.davis" 
				:currentEcpProfit="ecpProfits.current.davis.pal_cat_ultimate" 
				v-model:newJobsState="inputs.new_monthly_jobs.davis.pal_cat_ultimate"
				:newEcpProfit="ecpProfits.new.davis.pal_cat_ultimate"
				:rebate="this.inputs.rebates.davis.pal_cat_ultimate"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Ultra" 
				productList="Kodak Unique" 
				:currentTotalJobs="inputs.pal_table_davis.ultra_jobs" 
				:brandPercentage="inputs.job_pct_versant.davis" 
				:currentEcpProfit="ecpProfits.current.davis.pal_cat_ultra" 
				v-model:newJobsState="inputs.new_monthly_jobs.davis.pal_cat_ultra"
				:newEcpProfit="ecpProfits.new.davis.pal_cat_ultra"
				:rebate="this.inputs.rebates.davis.pal_cat_ultra"
			/>
			<ResultsInputGroup 
				categoryTitle="Premium" 
				productList="Kodak Precise PB/Short PB, Kodak Precise" 
				:currentTotalJobs="inputs.pal_table_davis.premium_jobs" 
				:brandPercentage="inputs.job_pct_versant.davis" 
				:currentEcpProfit="ecpProfits.current.davis.pal_cat_premium" 
				v-model:newJobsState="inputs.new_monthly_jobs.davis.pal_cat_premium"
				:newEcpProfit="ecpProfits.new.davis.pal_cat_premium"
				:rebate="this.inputs.rebates.davis.pal_cat_premium"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Standard" 
				productList="Signet Navigator/Short" 
				:currentTotalJobs="inputs.pal_table_davis.standard_jobs" 
				:brandPercentage="inputs.job_pct_versant.davis" 
				:currentEcpProfit="ecpProfits.current.davis.pal_cat_standard" 
				v-model:newJobsState="inputs.new_monthly_jobs.davis.pal_cat_standard"
				:newEcpProfit="ecpProfits.new.davis.pal_cat_standard"
				:rebate="this.inputs.rebates.davis.pal_cat_standard"
			/>
			<ResultsTotalsSection 
				:currentTotalJobs="[inputs.pal_table_davis.ultimate_jobs,inputs.pal_table_davis.ultra_jobs,inputs.pal_table_davis.premium_jobs,inputs.pal_table_davis.standard_jobs]"
				:newTotalJobs="[inputs.new_monthly_jobs.davis.pal_cat_ultimate,inputs.new_monthly_jobs.davis.pal_cat_ultra,inputs.new_monthly_jobs.davis.pal_cat_premium,inputs.new_monthly_jobs.davis.pal_cat_standard]"
				:jobPct="inputs.job_pct_versant.davis" />
			<ResultsSubheaders categoryTitle="No-Glare Categories:" />
			<ResultsInputGroup 
				categoryTitle="Ultimate" 
				productList="Crizal Sapphire 360 UV, Crizal Prevencia, Crizal Avance UV" 
				:currentTotalJobs="inputs.ng_table_davis.ultimate_jobs" 
				:brandPercentage="inputs.job_pct_versant.davis" 
				:currentEcpProfit="ecpProfits.current.davis.ng_cat_ultimate" 
				v-model:newJobsState="inputs.new_monthly_jobs.davis.ng_cat_ultimate"
				:newEcpProfit="ecpProfits.new.davis.ng_cat_ultimate"
				:rebate="this.inputs.rebates.davis.ng_cat_ultimate"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Ultra" 
				productList="Crizal Alize UV, Kodak TotalBlue with Silk, Kodak Clean&amp;CleAR" 
				:currentTotalJobs="inputs.ng_table_davis.ultra_jobs" 
				:brandPercentage="inputs.job_pct_versant.davis" 
				:currentEcpProfit="ecpProfits.current.davis.ng_cat_ultra" 
				v-model:newJobsState="inputs.new_monthly_jobs.davis.ng_cat_ultra"
				:newEcpProfit="ecpProfits.new.davis.ng_cat_ultra"
				:rebate="this.inputs.rebates.davis.ng_cat_ultra"
			/>
			<ResultsInputGroup 
				categoryTitle="Premium" 
				productList="Crizal Easy UV, Kodak CleAR" 
				:currentTotalJobs="inputs.ng_table_davis.premium_jobs" 
				:brandPercentage="inputs.job_pct_versant.davis" 
				:currentEcpProfit="ecpProfits.current.davis.ng_cat_premium" 
				v-model:newJobsState="inputs.new_monthly_jobs.davis.ng_cat_premium"
				:newEcpProfit="ecpProfits.new.davis.ng_cat_premium"
				:rebate="this.inputs.rebates.davis.ng_cat_premium"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Standard" 
				productList="Sharpview" 
				:currentTotalJobs="inputs.ng_table_davis.standard_jobs" 
				:brandPercentage="inputs.job_pct_versant.davis" 
				:currentEcpProfit="ecpProfits.current.davis.ng_cat_standard" 
				v-model:newJobsState="inputs.new_monthly_jobs.davis.ng_cat_standard"
				:newEcpProfit="ecpProfits.new.davis.ng_cat_standard"
				:rebate="this.inputs.rebates.davis.ng_cat_standard"
			/>
			<ResultsTotalsSection 
				:currentTotalJobs="[inputs.ng_table_davis.ultimate_jobs,inputs.ng_table_davis.ultra_jobs,inputs.ng_table_davis.premium_jobs,inputs.ng_table_davis.standard_jobs]"
				:newTotalJobs="[inputs.new_monthly_jobs.davis.ng_cat_ultimate,inputs.new_monthly_jobs.davis.ng_cat_ultra,inputs.new_monthly_jobs.davis.ng_cat_premium,inputs.new_monthly_jobs.davis.ng_cat_standard]"
				:jobPct="inputs.job_pct_versant.davis"
				:newTotalProfits="$store.getters.versantDavis_newTotalProfits" 
				:newTotalRebates="$store.getters.versantDavis_newTotalRebates" />
			<ResultsGrandTotalsSection :currentGrandTotalProfits="$store.getters.versantDavis_currentGrandTotalProfits" :newGrandTotalProfits="$store.getters.versantDavis_newGrandTotalProfits" />
			<ResultsSummarySection :totalMonthlyIncrease="$store.getters.versantDavis_totalMonthlyIncrease" :totalYearlyIncrease="$store.getters.versantDavis_totalYearlyIncrease" />
		</div>
    <div class="watermark" v-if="watermarked">{{$store.state.user_email}}</div>
	</div>
	<!-- Versant Superior -->
	<div class="section s6" @click="setActiveSection($refs.s6Label)" v-if="$store.getters.hasVersantSuperior">
		<div class="section-header">
			<div class="inner-container">
				<div class="section-number" ref="s6Label"></div>
				<h2>Superior Vision</h2>
				<button class="toggle" v-bind:class="{open:expanded}" @click="toggleProducts($event,this.$refs.versantSuperiorContent)"><span class="show">Maximize Superior Vision</span><span class="hide">Minimize Superior Vision</span></button>
			</div>
		</div>
		<div class="section-content" v-bind:class="{open:expanded}" ref="versantSuperiorContent">
			<div class="input-headers">
				<div class="categories"></div>
				<div class="current">Current</div>
				<div class="new">New</div>
			</div>
			<ResultsSubheaders categoryTitle="PAL Categories:" />
			<ResultsInputGroup 
				categoryTitle="Ultimate" 
				productList="" 
				:currentTotalJobs="inputs.pal_table_davis.ultimate_jobs" 
				:brandPercentage="inputs.job_pct_versant.superior" 
				:currentEcpProfit="ecpProfits.current.superior.pal_cat_ultimate" 
				v-model:newJobsState="inputs.new_monthly_jobs.superior.pal_cat_ultimate"
				:newEcpProfit="ecpProfits.new.superior.pal_cat_ultimate"
				:rebate="this.inputs.rebates.davis.pal_cat_ultimate"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Ultra" 
				productList="Kodak Unique" 
				:currentTotalJobs="inputs.pal_table_davis.ultra_jobs" 
				:brandPercentage="inputs.job_pct_versant.superior" 
				:currentEcpProfit="ecpProfits.current.superior.pal_cat_ultra" 
				v-model:newJobsState="inputs.new_monthly_jobs.superior.pal_cat_ultra"
				:newEcpProfit="ecpProfits.new.superior.pal_cat_ultra"
				:rebate="this.inputs.rebates.davis.pal_cat_ultra"
			/>
			<ResultsInputGroup 
				categoryTitle="Premium" 
				productList="Kodak Precise PB/Short PB, Kodak Precise" 
				:currentTotalJobs="inputs.pal_table_davis.premium_jobs" 
				:brandPercentage="inputs.job_pct_versant.superior" 
				:currentEcpProfit="ecpProfits.current.superior.pal_cat_premium" 
				v-model:newJobsState="inputs.new_monthly_jobs.superior.pal_cat_premium"
				:newEcpProfit="ecpProfits.new.superior.pal_cat_premium"
				:rebate="this.inputs.rebates.davis.pal_cat_premium"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Standard" 
				productList="Signet Navigator/Short" 
				:currentTotalJobs="inputs.pal_table_davis.standard_jobs" 
				:brandPercentage="inputs.job_pct_versant.superior" 
				:currentEcpProfit="ecpProfits.current.superior.pal_cat_standard" 
				v-model:newJobsState="inputs.new_monthly_jobs.superior.pal_cat_standard"
				:newEcpProfit="ecpProfits.new.superior.pal_cat_standard"
				:rebate="this.inputs.rebates.davis.pal_cat_standard"
			/>
			<ResultsTotalsSection 
				:currentTotalJobs="[inputs.pal_table_davis.ultimate_jobs,inputs.pal_table_davis.ultra_jobs,inputs.pal_table_davis.premium_jobs,inputs.pal_table_davis.standard_jobs]"
				:newTotalJobs="[inputs.new_monthly_jobs.superior.pal_cat_ultimate,inputs.new_monthly_jobs.superior.pal_cat_ultra,inputs.new_monthly_jobs.superior.pal_cat_premium,inputs.new_monthly_jobs.superior.pal_cat_standard]"
				:jobPct="inputs.job_pct_versant.superior"/>
			<ResultsSubheaders categoryTitle="No-Glare Categories:" />
			<ResultsInputGroup 
				categoryTitle="Ultimate" 
				productList="Crizal Sapphire 360 UV, Crizal Prevencia, Crizal Avance UV" 
				:currentTotalJobs="inputs.ng_table_davis.ultimate_jobs" 
				:brandPercentage="inputs.job_pct_versant.superior" 
				:currentEcpProfit="ecpProfits.current.superior.ng_cat_ultimate" 
				v-model:newJobsState="inputs.new_monthly_jobs.superior.ng_cat_ultimate"
				:newEcpProfit="ecpProfits.new.superior.ng_cat_ultimate"
				:rebate="this.inputs.rebates.davis.ng_cat_ultimate"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Ultra" 
				productList="Crizal Alize UV, Kodak TotalBlue with Silk, Kodak Clean&amp;CleAR" 
				:currentTotalJobs="inputs.ng_table_davis.ultra_jobs" 
				:brandPercentage="inputs.job_pct_versant.superior" 
				:currentEcpProfit="ecpProfits.current.superior.ng_cat_ultra" 
				v-model:newJobsState="inputs.new_monthly_jobs.superior.ng_cat_ultra"
				:newEcpProfit="ecpProfits.new.superior.ng_cat_ultra"
				:rebate="this.inputs.rebates.davis.ng_cat_ultra"
			/>
			<ResultsInputGroup 
				categoryTitle="Premium" 
				productList="Crizal Easy UV, Kodak CleAR" 
				:currentTotalJobs="inputs.ng_table_davis.premium_jobs" 
				:brandPercentage="inputs.job_pct_versant.superior" 
				:currentEcpProfit="ecpProfits.current.superior.ng_cat_premium" 
				v-model:newJobsState="inputs.new_monthly_jobs.superior.ng_cat_premium"
				:newEcpProfit="ecpProfits.new.superior.ng_cat_premium"
				:rebate="this.inputs.rebates.davis.ng_cat_premium"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Standard" 
				productList="Sharpview" 
				:currentTotalJobs="inputs.ng_table_davis.standard_jobs" 
				:brandPercentage="inputs.job_pct_versant.superior" 
				:currentEcpProfit="ecpProfits.current.superior.ng_cat_standard" 
				v-model:newJobsState="inputs.new_monthly_jobs.superior.ng_cat_standard"
				:newEcpProfit="ecpProfits.new.superior.ng_cat_standard"
				:rebate="this.inputs.rebates.davis.ng_cat_standard"
			/>
			<ResultsTotalsSection 
				:currentTotalJobs="[inputs.ng_table_davis.ultimate_jobs,inputs.ng_table_davis.ultra_jobs,inputs.ng_table_davis.premium_jobs,inputs.ng_table_davis.standard_jobs]"
				:newTotalJobs="[inputs.new_monthly_jobs.superior.ng_cat_ultimate,inputs.new_monthly_jobs.superior.ng_cat_ultra,inputs.new_monthly_jobs.superior.ng_cat_premium,inputs.new_monthly_jobs.superior.ng_cat_standard]"
				:jobPct="inputs.job_pct_versant.superior"
				:newTotalProfits="$store.getters.versantSuperior_newTotalProfits" 
				:newTotalRebates="$store.getters.versantSuperior_newTotalRebates" />
			<ResultsGrandTotalsSection :currentGrandTotalProfits="$store.getters.versantSuperior_currentGrandTotalProfits" :newGrandTotalProfits="$store.getters.versantSuperior_newGrandTotalProfits" />
			<ResultsSummarySection :totalMonthlyIncrease="$store.getters.versantSuperior_totalMonthlyIncrease" :totalYearlyIncrease="$store.getters.versantSuperior_totalYearlyIncrease" />
		</div>
    <div class="watermark" v-if="watermarked">{{$store.state.user_email}}</div>
	</div>
	<!-- Spectera -->
	<div class="section s7" @click="setActiveSection($refs.s7Label)" v-if="$store.getters.hasSpectera">
		<div class="section-header">
			<div class="inner-container">
				<div class="section-number" ref="s7Label"></div>
				<h2>Spectera</h2>
				<button class="toggle" v-bind:class="{open:expanded}" @click="toggleProducts($event,this.$refs.specteraContent)"><span class="show">Maximize Spectera</span><span class="hide">Minimize Spectera</span></button>
			</div>
		</div>
		<div class="section-content" v-bind:class="{open:expanded}" ref="specteraContent">
			<div class="input-headers">
				<div class="categories"></div>
				<div class="current">Current</div>
				<div class="new">New</div>
			</div>
			<ResultsSubheaders categoryTitle="PAL Categories:" />
			<ResultsInputGroup 
				categoryTitle="Tier V" 
				productList="" 
				:currentTotalJobs="inputs.pal_table_spectera.tier_5_jobs" 
				brandPercentage="100" 
				:currentEcpProfit="ecpProfits.current.spectera.pal_tier_5*(this.inputs.profit_pct_spectera/100)" 
				v-model:newJobsState="inputs.new_monthly_jobs.spectera.pal_tier_5"
				:newEcpProfit="ecpProfits.new.spectera.pal_tier_5*(this.inputs.profit_pct_spectera/100)"
				:rebate="this.inputs.rebates.spectera.pal_tier_5"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Tier IV" 
				productList="" 
				:currentTotalJobs="inputs.pal_table_spectera.tier_4_jobs" 
				brandPercentage="100" 
				:currentEcpProfit="ecpProfits.current.spectera.pal_tier_4*(this.inputs.profit_pct_spectera/100)" 
				v-model:newJobsState="inputs.new_monthly_jobs.spectera.pal_tier_4"
				:newEcpProfit="ecpProfits.new.spectera.pal_tier_4*(this.inputs.profit_pct_spectera/100)"
				:rebate="this.inputs.rebates.spectera.pal_tier_4"
			/>
			<ResultsInputGroup 
				categoryTitle="Tier III" 
				productList="Kodak Unique" 
				:currentTotalJobs="inputs.pal_table_spectera.tier_3_jobs" 
				brandPercentage="100" 
				:currentEcpProfit="ecpProfits.current.spectera.pal_tier_3*(this.inputs.profit_pct_spectera/100)" 
				v-model:newJobsState="inputs.new_monthly_jobs.spectera.pal_tier_3"
				:newEcpProfit="ecpProfits.new.spectera.pal_tier_3*(this.inputs.profit_pct_spectera/100)"
				:rebate="this.inputs.rebates.spectera.pal_tier_3"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="Tier II" 
				productList="" 
				:currentTotalJobs="inputs.pal_table_spectera.tier_2_jobs" 
				brandPercentage="100" 
				:currentEcpProfit="ecpProfits.current.spectera.pal_tier_2*(this.inputs.profit_pct_spectera/100)" 
				v-model:newJobsState="inputs.new_monthly_jobs.spectera.pal_tier_2"
				:newEcpProfit="ecpProfits.new.spectera.pal_tier_2*(this.inputs.profit_pct_spectera/100)"
				:rebate="this.inputs.rebates.spectera.pal_tier_2"
			/>
			<ResultsInputGroup 
				categoryTitle="Tier I" 
				productList="Kodak Precise PB/Short, Precise/Short" 
				:currentTotalJobs="inputs.pal_table_spectera.tier_1_jobs" 
				brandPercentage="100" 
				:currentEcpProfit="ecpProfits.current.spectera.pal_tier_1*(this.inputs.profit_pct_spectera/100)" 
				v-model:newJobsState="inputs.new_monthly_jobs.spectera.pal_tier_1"
				:newEcpProfit="ecpProfits.new.spectera.pal_tier_1*(this.inputs.profit_pct_spectera/100)"
				:rebate="this.inputs.rebates.spectera.pal_tier_1"
			/>
			<ResultsTotalsSection 
				:currentTotalJobs="[inputs.pal_table_spectera.tier_5_jobs,inputs.pal_table_spectera.tier_4_jobs,inputs.pal_table_spectera.tier_3_jobs,inputs.pal_table_spectera.tier_2_jobs,inputs.pal_table_spectera.tier_1_jobs]"
				:newTotalJobs="[inputs.new_monthly_jobs.spectera.pal_tier_5,inputs.new_monthly_jobs.spectera.pal_tier_4,inputs.new_monthly_jobs.spectera.pal_tier_3,inputs.new_monthly_jobs.spectera.pal_tier_2,inputs.new_monthly_jobs.spectera.pal_tier_1]"
				:jobPct="100" />
			<ResultsSubheaders categoryTitle="No-Glare Categories:" />
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="AR Tier IV" 
				productList="Crizal Sapphire 360 UV, Crizal Prevencia, Crizal Rock, Crizal Avance UV, Kodak Clean&amp;CleAR" 
				:currentTotalJobs="inputs.ng_table_spectera.tier_4_jobs" 
				brandPercentage="100" 
				:currentEcpProfit="ecpProfits.current.spectera.ng_tier_4*(this.inputs.profit_pct_spectera/100)" 
				v-model:newJobsState="inputs.new_monthly_jobs.spectera.ng_tier_4"
				:newEcpProfit="ecpProfits.new.spectera.ng_tier_4*(this.inputs.profit_pct_spectera/100)"
				:rebate="this.inputs.rebates.spectera.ng_tier_4"
			/>
			<ResultsInputGroup 
				categoryTitle="AR Tier III" 
				productList="Crizal Alize UV" 
				:currentTotalJobs="inputs.ng_table_spectera.tier_3_jobs" 
				brandPercentage="100" 
				:currentEcpProfit="ecpProfits.current.spectera.ng_tier_3*(this.inputs.profit_pct_spectera/100)" 
				v-model:newJobsState="inputs.new_monthly_jobs.spectera.ng_tier_3"
				:newEcpProfit="ecpProfits.new.spectera.ng_tier_3*(this.inputs.profit_pct_spectera/100)"
				:rebate="this.inputs.rebates.spectera.ng_tier_3"
			/>
			<ResultsInputGroup 
				alternateLabel="true"
				categoryTitle="AR Tier II" 
				productList="Crizal Easy UV, Kodak CleAR" 
				:currentTotalJobs="inputs.ng_table_spectera.tier_2_jobs" 
				brandPercentage="100" 
				:currentEcpProfit="ecpProfits.current.spectera.ng_tier_2*(this.inputs.profit_pct_spectera/100)" 
				v-model:newJobsState="inputs.new_monthly_jobs.spectera.ng_tier_2"
				:newEcpProfit="ecpProfits.new.spectera.ng_tier_2*(this.inputs.profit_pct_spectera/100)"
				:rebate="this.inputs.rebates.spectera.ng_tier_2"
			/>
			<ResultsInputGroup 
				categoryTitle="AR Tier I" 
				productList="Sharpview" 
				:currentTotalJobs="inputs.ng_table_spectera.tier_1_jobs" 
				brandPercentage="100" 
				:currentEcpProfit="ecpProfits.current.spectera.ng_tier_1*(this.inputs.profit_pct_spectera/100)" 
				v-model:newJobsState="inputs.new_monthly_jobs.spectera.ng_tier_1"
				:newEcpProfit="ecpProfits.new.spectera.ng_tier_1*(this.inputs.profit_pct_spectera/100)"
				:rebate="this.inputs.rebates.spectera.ng_tier_1"
			/>
			<ResultsTotalsSection 
				:currentTotalJobs="[inputs.ng_table_spectera.tier_4_jobs,inputs.ng_table_spectera.tier_3_jobs,inputs.ng_table_spectera.tier_2_jobs,inputs.ng_table_spectera.tier_1_jobs]"
				:newTotalJobs="[inputs.new_monthly_jobs.spectera.ng_tier_4,inputs.new_monthly_jobs.spectera.ng_tier_3,inputs.new_monthly_jobs.spectera.ng_tier_2,inputs.new_monthly_jobs.spectera.ng_tier_1]"
				:jobPct="100"
				:newTotalProfits="$store.getters.spectera_newTotalProfits" 
				:newTotalRebates="$store.getters.spectera_newTotalRebates" />
			<ResultsGrandTotalsSection :currentGrandTotalProfits="$store.getters.spectera_currentGrandTotalProfits" :newGrandTotalProfits="$store.getters.spectera_newGrandTotalProfits" />
			<ResultsSummarySection :totalMonthlyIncrease="$store.getters.spectera_totalMonthlyIncrease" :totalYearlyIncrease="$store.getters.spectera_totalYearlyIncrease" />
		</div>
    <div class="watermark" v-if="watermarked">{{$store.state.user_email}}</div>
	</div>
</template>

<script>
import ResultsInputGroup from '@/components/ResultsInputGroup.vue'
import ResultsTotalsSection from '@/components/ResultsTotalsSection.vue'
import ResultsGrandTotalsSection from '@/components/ResultsGrandTotalsSection.vue'
import ResultsSummarySection from '@/components/ResultsSummarySection.vue'
import ResultsSubheaders from '@/components/ResultsSubheaders.vue'
import ecpProfits from '@/lib/ecpProfits.js'
import sectionLabelling from '@/mixins/sectionLabelling.js'
import {mapState} from 'vuex'

export default {
	name: 'ResultsTable',
	extends: sectionLabelling,
	components: {
			ResultsInputGroup,
			ResultsTotalsSection,
			ResultsGrandTotalsSection,
			ResultsSummarySection,
			ResultsSubheaders
	},
	data: function () {
		return {
			ecpProfits: ecpProfits
		}
	},
	props: {
		expanded: Boolean,
    watermarked: {
      default: false,
      type: Boolean
    }
	},
	computed: {
		...mapState(['inputs']),
		totalSections: function () {
				var count = 0;
				if (this.$store.getters.hasVspChoice) {count++}
				if (this.$store.getters.hasVspSignature) {count++}
				if (this.$store.getters.hasEyemedAccess) {count++}
				if (this.$store.getters.hasEyemedInsight) {count++}
				if (this.$store.getters.hasVersantDavis) {count++}
				if (this.$store.getters.hasVersantSuperior) {count++}
				if (this.$store.getters.hasSpectera) {count++}
				return count;
		}
	},
	mounted: function () {
		window.scrollTo(0, 0)
		var sectionNums = document.getElementsByClassName('section-number');
		for (var i = 0; i < sectionNums.length; i++) {
			sectionNums[i].innerHTML = i+1 + " / " + this.totalSections;
		}
	},
	methods: {
		toggleProducts: function(e,tgt){
			e.target.classList.toggle("open");
			tgt.classList.toggle("open");
		}
	}
}
</script>

<style scoped>
  .watermark {
    font-size: 100px;
    font-weight: bold;
    position: absolute;
    margin: 0 auto;
    color: #fff;
    pointer-events: none;
    opacity: 0.2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    -moz-transform: translate(-50%, -50%) rotate(-45deg);
  }
	::v-deep(.inner-container) {
		width: 1024px;
		margin: 0 auto;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.section {
		width: 100%;
		position: relative;
	}
	::v-deep(.section-header) {
		width: 100%;
		background-color: #333333;
		color: white;
		height: 60px;
	}
	::v-deep(.section-header h2) {
		padding: 0;
		margin: 0;
		width: auto;
		border: none;
		color: white;
	}
	::v-deep(.section-header .section-number) {
		bottom: auto;
	}
	::v-deep(.section-header .toggle) {
		position: absolute;
		right: 0;
	}
	::v-deep(.section-content) {
		width: 1016px;
		height: auto;
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.2s ease-in-out;
	}
	::v-deep(.section-content.open) {
		max-height: 2500px;
	}
	::v-deep(.categories)	{
		width: 328px;
		padding-right: 7px;
		border-right: 2px solid #EDEDED;
		height: 100%;
		display: flex;
		align-items: flex-end;
		position: relative;
	}
	::v-deep(.current) {
		width: 242px;
		margin-left: 7px;
		justify-content: center;
		display: flex;
		background-color: #FFDF99;
		height: 100%;
		align-items: center;
		box-sizing: border-box;
	}
	::v-deep(.new) {
		width: 414px;
		margin-left: 16px;
		justify-content: center;
		display: flex;
		background-color: #99DBB5;
		height: 100%;
		align-items: center;
		box-sizing: border-box;
	}
	::v-deep(input[type=number]), ::v-deep(input[type=text].dollar) {
		width: 100px;
		margin: 0 10px;
		background-color: #F3F3F3;
		border-color: #E4E4E4;
		position: relative;
	}
	::v-deep(input[type=number].editable) {
		background-color: white;
		border-color: #444444;
	}	
	::v-deep(input[type=number].edited) {
		background-color: white;
		border: 3px solid #ED0000;
		font-weight: bold;
	}	
	::v-deep(input[type=number].smaller) {
		width: 80px;
	}	
	::v-deep(input.filler) {
		visibility: hidden;
		pointer-events: none;
	}	
	::v-deep(.input-group) {
		height: 59px;
	}
	::v-deep(.input-headers) {
		height: 60px;
		color: white;
		font-size: 14px;
		font-weight: bold;
		align-items: flex-end;
		margin-bottom: 0;
	}
	::v-deep(.input-headers .current) {
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #DF9A00;
		border-radius: 5px 5px 0 0;
	}
	::v-deep(.input-headers .new) {
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #008F3E;
		border-radius: 5px 5px 0 0;
	}
	::v-deep(.input-subheaders) {
		height: 50px;
		display: flex;
	}
	::v-deep(.input-subheaders .categories .title) {
		font-weight: bold;
		color: bold;
		margin-left: 20px;
		margin-bottom: 10px;
	}
	::v-deep(.input-subheaders .categories .subtitle) {
		font-weight: bold;
		color: bold;
		font-size: 12px;
		margin-bottom: 10px;
		position: absolute;
		right: 25px;
	}
	::v-deep(.input-subheaders .current) {
		background-color: #FFB000;
		color: white;
		font-size: 12px;
		font-weight: bold;
	}
	::v-deep(.input-subheaders .new) {
		background-color: #00A648;
		color: white;
		font-size: 12px;
		font-weight: bold;
	}
	::v-deep(.subhead) {
		width: 120px;
		padding: 10px;
		text-align: center;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	::v-deep(.subhead.smaller) {
		width: 100px;
	}
	::v-deep(.subhead.editable) {
		padding-right: 29px;
		background: url(../assets/editable.png) no-repeat 90px center;
	}
	::v-deep(.categories .label) {
		width: 328px;
		height: 55px;
		background-color: #333333;
		color: white;
		font-weight: bold;
		display: flex;
		align-items: center;
		position: relative;
		margin: 3px 0;
	}
	::v-deep(.categories .label.alternate) {
		background-color: black;
	}
	::v-deep(.categories .label .title) {
		margin-left: 20px;
	}
	::v-deep(.toggle) {
		position: absolute;
		right: 10px;
		background: transparent;
		color: white;
		font-weight: bold;
		border: none;
		text-decoration: underline;
		height: 32px;
		padding: 0;
		margin: 0;
		cursor: pointer;
	}
	::v-deep(.toggle:hover) {
		opacity:0.8;
	}
	::v-deep(.toggle span) {
		height: 100%;
		padding-right: 38px;
		align-items: center;
		pointer-events: none;
	}
	::v-deep(.toggle .show) {
		background: url(../assets/show.png) no-repeat right center;
		margin-right: 2px;
		display: flex;
	}
	::v-deep(.toggle .hide) {
		background: url(../assets/hide.png) no-repeat right center;
		margin-right: 2px;
		display: none;
	}
	::v-deep(.toggle.open .show) {
		display: none;
	}
	::v-deep(.toggle.open .hide) {
		display: flex;
	}
	::v-deep(.input-group .current) {
		border-bottom: 2px solid #FFB000;
	}
	::v-deep(.input-group .new) {
		border-bottom: 2px solid #00A648;
	}
	::v-deep(.products) {
		width: 100%;
		height: 0px;
		transition: height 0.15s ease-in-out;
		position: relative;
		overflow: hidden;
	}
	::v-deep(.product-list) {
		display: flex;
		align-items: center;
		border-left: 4px solid #FFB700;
		background-color: #333333;
		color: white;
		height: 100%;
		width: 100%;
		margin-left: 10px;
		padding-left: 16px;

	}
	::v-deep(.products.open) {
		height: 50px;
		padding-bottom: 10px;
	}
	::v-deep(.section-filler) {
		display: flex;
		height: 10px;
		width: 100%;
		position: relative;
		bottom: 0;
		left: 0;
		background-color: white;
	}
	::v-deep(.section-filler.x2) {
		height: 20px;
	}
</style>
