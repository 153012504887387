<template>
  <div class="grand-total-section">
    <div class="categories"></div>
    <div class="current">
      <div class="label">Current<br/>Grand Total ($)</div>
      <input type="number" class="smaller filler"/>
      <input class="dollar" type="text" :value="currentGrandTotalProfits.toLocaleString()" readonly/>
    </div>
    <div class="new">
      <div class="label">New<br/>Grand Total ($)</div>
      <input type="number" class="filler"/>
      <input type="number" class="filler"/>
      <input class="dollar" type="text" :value="newGrandTotalProfits.toLocaleString()" readonly/>
    </div>
  </div>
</template>

<script>

export default {
	name: 'ResultsGrandTotalsSection',
	props: ['currentGrandTotalProfits','newGrandTotalProfits']
}
</script>

<style scoped>
.grand-total-section {
	display: flex;
	align-items: center;
	height: 70px;
}
.grand-total-section .current {
	background-color: #FFB000;
	position: relative;
}
.grand-total-section .new {
	background-color: #00A648;
	position: relative;
}
.grand-total-section .label {
	color: white;
	font-size: 14px;
	text-align: right;
	font-weight: bold;
	position: absolute;
}
.grand-total-section .current .label {
	right: 132px;
}
.grand-total-section .new .label {
	right: 148px;
}
</style>