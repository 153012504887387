<template>
  <div class="section-content open">
    <div class="input-headers">
      <div class="categories"><div class="title">MVC Business:</div></div>
      <div class="monthly">Increase in Profitability<br/>/ month ($)</div>
      <div class="yearly">Increase in Profitability<br/>/ year ($)</div>
    </div>
    <div class="input-group" v-if="$store.getters.hasVspChoice || $store.getters.hasVspSignature">
      <div class="categories">
        <div class="label product-label">
          <div class="title"><b>VSP</b> (Choice + Signature)</div>
        </div>
      </div>
      <div class="monthly">
        <input class="dollar" type="text" :value="($store.getters.vspChoice_totalMonthlyIncrease + $store.getters.vspSignature_totalMonthlyIncrease).toLocaleString()" readonly />
      </div>
      <div class="yearly">
        <input class="dollar" type="text" :value="($store.getters.vspChoice_totalYearlyIncrease + $store.getters.vspSignature_totalYearlyIncrease).toLocaleString()" readonly />
      </div>
    </div>
    <div class="input-group" v-if="$store.getters.hasEyemedAccess || $store.getters.hasEyemedInsight">
      <div class="categories">
        <div class="label product-label">
          <div class="title"><b>EyeMed</b> (Access / Select + Insight)</div>
        </div>
      </div>
      <div class="monthly">
        <input class="dollar" type="text" :value="($store.getters.eyemedAccess_totalMonthlyIncrease + $store.getters.eyemedInsight_totalMonthlyIncrease).toLocaleString()" readonly />
      </div>
      <div class="yearly">
        <input class="dollar" type="text" :value="($store.getters.eyemedAccess_totalYearlyIncrease + $store.getters.eyemedInsight_totalYearlyIncrease).toLocaleString()" readonly />
      </div>
    </div>
    <div class="input-group" v-if="$store.getters.hasVersantDavis || $store.getters.hasVersantSuperior">
      <div class="categories">
        <div class="label product-label">
          <div class="title"><b>Versant</b> (Davis Vision + Superior Vision)</div>
        </div>
      </div>
      <div class="monthly">
        <input class="dollar" type="text" :value="($store.getters.versantDavis_totalMonthlyIncrease + $store.getters.versantSuperior_totalMonthlyIncrease).toLocaleString()" readonly />
      </div>
      <div class="yearly">
        <input class="dollar" type="text" :value="($store.getters.versantDavis_totalYearlyIncrease + $store.getters.versantSuperior_totalYearlyIncrease).toLocaleString()" readonly />
      </div>
    </div>
    <div class="input-group" v-if="$store.getters.hasSpectera">
      <div class="categories">
        <div class="label product-label">
          <div class="title"><b>Spectera</b></div>
        </div>
      </div>
      <div class="monthly">
        <input class="dollar" type="text" :value="$store.getters.spectera_totalMonthlyIncrease.toLocaleString()" readonly />
      </div>
      <div class="yearly">
        <input class="dollar" type="text" :value="$store.getters.spectera_totalYearlyIncrease.toLocaleString()" readonly />
      </div>
    </div>
    <div class="watermark" v-if="watermarked">{{$store.state.user_email}}</div>
  </div>
</template>

<script>

export default {
	name: 'SummaryTable',
  props: {
    watermarked: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style scoped>
  .section {
		width: 100%;
	}
  .section-content {
		width: 1016px;
		height: auto;
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.2s ease-in-out;
    margin: 0 auto;
    position: relative;
	}
  .watermark {
    font-size: 50px;
    font-weight: bold;
    position: absolute;
    margin: 0 auto;
    color: #fff;
    pointer-events: none;
    opacity: 0.2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    -moz-transform: translate(-50%, -50%) rotate(-45deg);
  }
  .section-content.open {
		max-height: 2000px;
	}
	.categories	{
		width: 328px;
		padding-right: 7px;
		border-right: 2px solid #EDEDED;
		height: 100%;
		display: flex;
		align-items: flex-end;
		position: relative;
	}
  input[type=text].dollar {
		width: 100px;
		margin: 0 10px;
		background-color: #F3F3F3;
		border-color: #E4E4E4;
		position: relative;
	}
  .input-headers {
    height: 100px;
    display: flex;
    align-items: flex-end;
		color: white;
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 0;
  }
  .input-headers .categories .title {
    color: black;
    margin: 0 0 10px 20px;
  }
  .input-headers .monthly, .input-headers .yearly {
    width: 328px;
    height: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px 5px 0 0;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
  .input-headers .monthly {
    margin-left: 7px;
    background-color: #007BC9;
  }
  .input-headers .yearly {
    margin-left: 16px;
    background-color: #00609D;
  }
  .input-group {
    height: 72px;
  }
  .input-group .categories {
    height: 70px;
  }
  .input-group .categories .label {
		width: 328px;
		background-color: #333333;
		color: white;
		display: flex;
		align-items: center;
		position: relative;
    height: 100%;
    margin: 0;
    font-weight: normal;
  }
  .categories .label.alternate {
		background-color: black;
	}
  .categories .label .title {
		margin-left: 20px;
	}
  .input-group .monthly, .input-group .yearly {
    width: 328px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input-group .monthly {
    margin-left: 7px;
    background-color: #5AA9DC;
    border-top: 2px solid #007BC9;
  }
  .input-group .yearly {
    margin-left: 16px;
    background-color: #5997BF;
    border-top: 2px solid #035488;
  }
  .input-group input {
    width: 164px;
    height: 58px;
    border: 4px solid #9BD8FF;
    border-radius: 5px;
    box-sizing: border-box;
  }
</style>