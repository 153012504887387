<template>
  <div class="step">
    <Header 
      :stepNum="9"
      stepTitle="<b>Q9:</b> Total Jobs"
    />
    <div class="main-content">
        <div class="note">
            View the Cheat Sheet PDF to see what products fall into which tiers and categories
            <a @click="modifyPdf" target="_blank">CHEAT SHEET</a>
        </div>
        <div class="section s1" @click="setActiveSection($refs.s1Label)" v-if="hasVSP">
            <div class="section-header">
                <div class="section-number" ref="s1Label"></div>
                <h2>VSP Jobs</h2>
                <button class="toggle" @click="toggleProducts($event,this.$refs.vspSection)"><span class="show">Maximize</span><span class="hide">Minimize</span></button>
            </div>
            <div class="section-content" ref="vspSection">
                <div class="subsection">
                    <div class="input-headers">
                            <div class="label-header">Please fill in the following table for PALs:</div>
                            <div class="number-header">Total Jobs</div>
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Category N</span></div>
                        <NumberInput v-model.number="inputs.pal_table_vsp.cat_n_jobs" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Category O</span></div>
                        <NumberInput v-model.number="inputs.pal_table_vsp.cat_o_jobs" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Category F</span></div>
                        <NumberInput v-model.number="inputs.pal_table_vsp.cat_f_jobs" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Category J</span></div>
                        <NumberInput v-model.number="inputs.pal_table_vsp.cat_j_jobs" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Category K</span></div>
                        <NumberInput v-model.number="inputs.pal_table_vsp.cat_k_jobs" />
                    </div>
                    <div class="input-group summary">
                        <div class="label"></div>
                        <div class="number-input"><input :value="vspPalTotalJobs" type="number" readonly/></div>
                    </div>
                </div>
                <div class="subsection">
                    <div class="input-headers">
                            <div class="label-header">Please fill in the following table for No-Glare:</div>
                            <div class="number-header">Total Jobs</div>
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Category D</span></div>
                        <NumberInput v-model.number="inputs.ng_table_vsp.cat_d_jobs" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Category C</span></div>
                        <NumberInput v-model.number="inputs.ng_table_vsp.cat_c_jobs" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Category B</span></div>
                        <NumberInput v-model.number="inputs.ng_table_vsp.cat_b_jobs" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Category A</span></div>
                        <NumberInput v-model.number="inputs.ng_table_vsp.cat_a_jobs" />
                    </div>
                    <div class="input-group summary">
                        <div class="label"></div>
                        <div class="number-input"><input :value="vspNGTotalJobs" type="number" readonly/></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section s2" @click="setActiveSection($refs.s2Label)" v-if="hasEyemed">
            <div class="section-header">
                <div class="section-number" ref="s2Label"></div>
                <h2>EyeMed Jobs</h2>
                <button class="toggle" @click="toggleProducts($event,this.$refs.eyemedSection)"><span class="show">Maximize</span><span class="hide">Minimize</span></button>
            </div>
            <div class="section-content" ref="eyemedSection">
                <div class="subsection longer">
                    <div class="input-headers">
                                        <div class="label-header">Please fill in the following table for PALs:</div>
                                        <div class="number-header">Total Jobs</div>
                                        <div class="price-header">Avg Retail Price ($)</div>
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Tier 4</span></div>
                        <NumberInput v-model.number="inputs.pal_table_eyemed.tier_4_jobs" />
                        <NumberInput v-model.number="inputs.pal_table_eyemed.tier_4_price" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Tier 3</span></div>
                        <NumberInput v-model.number="inputs.pal_table_eyemed.tier_3_jobs" />
                        <NumberInput v-model.number="inputs.pal_table_eyemed.tier_3_price" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Tier 2</span></div>
                        <NumberInput v-model.number="inputs.pal_table_eyemed.tier_2_jobs" />
                        <NumberInput v-model.number="inputs.pal_table_eyemed.tier_2_price" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Tier 1</span></div>
                        <NumberInput v-model.number="inputs.pal_table_eyemed.tier_1_jobs" />
                        <NumberInput v-model.number="inputs.pal_table_eyemed.tier_1_price" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Standard</span></div>
                        <NumberInput v-model.number="inputs.pal_table_eyemed.standard_jobs" />
                        <NumberInput v-model.number="inputs.pal_table_eyemed.standard_price" />
                    </div>
                    <div class="input-group summary">
                        <div class="label"></div>
                        <div class="number-input"><input :value="eyemedPalTotalJobs" type="number" readonly/></div>
                    </div>
                </div>
                <div class="subsection longer">
                    <div class="input-headers">
                            <div class="label-header">Please fill in the following table for No-Glare:</div>
                            <div class="number-header">Total Jobs</div>
                            <div class="price-header">Avg Retail Price ($)</div>
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Tier 3 (Lab Group H)</span></div>
                        <NumberInput v-model.number="inputs.ng_table_eyemed.lab_h_jobs" />
                        <NumberInput v-model.number="inputs.ng_table_eyemed.lab_h_price" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Tier 3 (Lab Group K)</span></div>
                        <NumberInput v-model.number="inputs.ng_table_eyemed.lab_k_jobs" />
                        <NumberInput v-model.number="inputs.ng_table_eyemed.lab_k_price" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Tier 2</span></div>
                        <NumberInput v-model.number="inputs.ng_table_eyemed.tier_2_jobs" />
                        <NumberInput v-model.number="inputs.ng_table_eyemed.tier_2_price" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Tier 1</span></div>
                        <NumberInput v-model.number="inputs.ng_table_eyemed.tier_1_jobs" />
                        <NumberInput v-model.number="inputs.ng_table_eyemed.tier_1_price" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Standard</span></div>
                        <NumberInput v-model.number="inputs.ng_table_eyemed.standard_jobs" />
                        <NumberInput v-model.number="inputs.ng_table_eyemed.standard_price" />
                    </div>
                    <div class="input-group summary">
                        <div class="label"></div>
                        <div class="number-input"><input :value="eyemedNGTotalJobs" type="number" readonly/></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section s3" @click="setActiveSection($refs.s3Label)" v-if="hasVersant">
            <div class="section-header">
                <div class="section-number" ref="s3Label"></div>
                <h2>Versant (Davis Vision/Superior Vision) Jobs</h2>
                <button class="toggle" @click="toggleProducts($event,this.$refs.versantSection)"><span class="show">Maximize</span><span class="hide">Minimize</span></button>
            </div>
            <div class="section-content" ref="versantSection">
                <div class="subsection">
                    <div class="input-headers">
                            <div class="label-header">Please fill in the following table for PALs:</div>
                            <div class="number-header">Total Jobs</div>
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Ultimate PALs</span></div>
                        <NumberInput v-model.number="inputs.pal_table_davis.ultimate_jobs" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Ultra PALs</span></div>
                        <NumberInput v-model.number="inputs.pal_table_davis.ultra_jobs" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Premium PALs</span></div>
                        <NumberInput v-model.number="inputs.pal_table_davis.premium_jobs" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Standard PALs</span></div>
                        <NumberInput v-model.number="inputs.pal_table_davis.standard_jobs" />
                    </div>
                    <div class="input-group summary">
                        <div class="label"></div>
                        <div class="number-input"><input :value="versantPalTotalJobs" type="number" readonly/></div>
                    </div>
                </div>
                <div class="subsection">
                    <div class="input-headers">
                            <div class="label-header">Please fill in the following table for No-Glare:</div>
                            <div class="number-header">Total Jobs</div>
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Ultimate No-Glare</span></div>
                        <NumberInput v-model.number="inputs.ng_table_davis.ultimate_jobs" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Ultra No-Glare</span></div>
                        <NumberInput v-model.number="inputs.ng_table_davis.ultra_jobs" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Premium No-Glare</span></div>
                        <NumberInput v-model.number="inputs.ng_table_davis.premium_jobs" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Standard No-Glare</span></div>
                        <NumberInput v-model.number="inputs.ng_table_davis.standard_jobs" />
                    </div>
                    <div class="input-group summary">
                        <div class="label"></div>
                        <div class="number-input"><input :value="versantNGTotalJobs" type="number" readonly/></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section s4" @click="setActiveSection($refs.s4Label)" v-if="hasSpectera">
            <div class="section-header">
                <div class="section-number" ref="s4Label"></div>
                <h2>Spectera Jobs</h2>
                <button class="toggle" @click="toggleProducts($event,this.$refs.specteraSection)"><span class="show">Maximize</span><span class="hide">Minimize</span></button>
            </div>
            <div class="section-content" ref="specteraSection">
                <div class="subsection">
                    <div class="input-headers">
                    <div class="label-header">Please fill in the following table for PALs:</div>
                    <div class="number-header">Total Jobs</div>
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Tier V Progressives</span></div>
                        <NumberInput v-model.number="inputs.pal_table_spectera.tier_5_jobs" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Tier IV Progressives</span></div>
                        <NumberInput v-model.number="inputs.pal_table_spectera.tier_4_jobs" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Tier III Progressives</span></div>
                        <NumberInput v-model.number="inputs.pal_table_spectera.tier_3_jobs" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Tier II Progressives</span></div>
                        <NumberInput v-model.number="inputs.pal_table_spectera.tier_2_jobs" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">Tier I Progressives</span></div>
                        <NumberInput v-model.number="inputs.pal_table_spectera.tier_1_jobs" />
                    </div>
                    <div class="input-group summary">
                        <div class="label"></div>
                        <div class="number-input"><input :value="specteraPalTotalJobs" type="number" readonly/></div>
                    </div>
                </div>
                <div class="subsection">
                    <div class="input-headers">
                            <div class="label-header">Please fill in the following table for No-Glare:</div>
                            <div class="number-header">Total Jobs</div>
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">AR Tier IV</span></div>
                        <NumberInput v-model.number="inputs.ng_table_spectera.tier_4_jobs" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">AR Tier III</span></div>
                        <NumberInput v-model.number="inputs.ng_table_spectera.tier_3_jobs" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">AR Tier II</span></div>
                        <NumberInput v-model.number="inputs.ng_table_spectera.tier_2_jobs" />
                    </div>
                    <div class="input-group">
                        <div class="label"><span class="title">AR Tier I</span></div>
                        <NumberInput v-model.number="inputs.ng_table_spectera.tier_1_jobs" />
                    </div>
                    <div class="input-group summary">
                        <div class="label"></div>
                        <div class="number-input"><input :value="specteraNGTotalJobs" type="number" readonly/></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import NumberInput from '@/components/NumberInput.vue'
import sectionLabelling from '@/mixins/sectionLabelling.js'
import {mapState} from 'vuex'

export default {
	name: 'Step9',
    extends: sectionLabelling,
	components: {
			Header,
            NumberInput
	},
	computed: {
		...mapState(['inputs']),
        hasVSP: function () {
            return (this.$store.state.inputs.business_pct_mvc.vsp > 0)
        },
        hasEyemed: function () {
            return (this.$store.state.inputs.business_pct_mvc.eyemed > 0)
        },
        hasVersant: function () {
            return (this.$store.state.inputs.business_pct_mvc.davis > 0)
        },
        hasSpectera: function () {
            return (this.$store.state.inputs.business_pct_mvc.spectera > 0)
        },
        totalSections: function () {
            var count = 0;
            if (this.hasVSP) {count++}
            if (this.hasEyemed) {count++}
            if (this.hasVersant) {count++}
            if (this.hasSpectera) {count++}
            return count;
        },
        vspPalTotalJobs: function () {
            return (
                this.inputs.pal_table_vsp.cat_n_jobs + this.inputs.pal_table_vsp.cat_o_jobs + this.inputs.pal_table_vsp.cat_f_jobs + this.inputs.pal_table_vsp.cat_j_jobs + this.inputs.pal_table_vsp.cat_k_jobs
            )
        },
        vspNGTotalJobs: function () {
            return (
                this.inputs.ng_table_vsp.cat_d_jobs + this.inputs.ng_table_vsp.cat_c_jobs + this.inputs.ng_table_vsp.cat_b_jobs + this.inputs.ng_table_vsp.cat_a_jobs
            )
        },
        eyemedPalTotalJobs: function () {
            return (
                this.inputs.pal_table_eyemed.tier_4_jobs + this.inputs.pal_table_eyemed.tier_3_jobs + this.inputs.pal_table_eyemed.tier_2_jobs + this.inputs.pal_table_eyemed.tier_1_jobs + this.inputs.pal_table_eyemed.standard_jobs
            )
        },
        eyemedNGTotalJobs: function () {
            return (
                this.inputs.ng_table_eyemed.lab_h_jobs + this.inputs.ng_table_eyemed.lab_k_jobs + this.inputs.ng_table_eyemed.tier_2_jobs + this.inputs.ng_table_eyemed.tier_1_jobs + this.inputs.ng_table_eyemed.standard_jobs
            )
        },
        versantPalTotalJobs: function () {
            return (
                this.inputs.pal_table_davis.ultimate_jobs + this.inputs.pal_table_davis.ultra_jobs + this.inputs.pal_table_davis.premium_jobs + this.inputs.pal_table_davis.standard_jobs
            )
        },
        versantNGTotalJobs: function () {
            return (
                this.inputs.ng_table_davis.ultimate_jobs + this.inputs.ng_table_davis.ultra_jobs + this.inputs.ng_table_davis.premium_jobs + this.inputs.ng_table_davis.standard_jobs
            )
        },
        specteraPalTotalJobs: function () {
            return (
                this.inputs.pal_table_spectera.tier_5_jobs + this.inputs.pal_table_spectera.tier_4_jobs + this.inputs.pal_table_spectera.tier_3_jobs + this.inputs.pal_table_spectera.tier_2_jobs + this.inputs.pal_table_spectera.tier_1_jobs
            )
        },
        specteraNGTotalJobs: function () {
            return (
                this.inputs.ng_table_spectera.tier_4_jobs + this.inputs.ng_table_spectera.tier_3_jobs + this.inputs.ng_table_spectera.tier_2_jobs + this.inputs.ng_table_spectera.tier_1_jobs
            )
        }
	},
    mounted: function () {
        window.scrollTo(0, 0)
        var sectionNums = document.getElementsByClassName('section-number');
        for (var i = 0; i < sectionNums.length; i++) {
            sectionNums[i].innerHTML = i+1 + " / " + this.totalSections;
        }
    },
    methods: {
		toggleProducts: function(e,tgt){
			e.target.classList.toggle("open");
			tgt.classList.toggle("open");
		},
        toTop: function() {
            window.scrollTo(0, 0);
        },
        modifyPdf: async function() {
            const url = './MVC Guidelines.pdf'
            const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())

            const pdfDoc = await window.PDFLib.PDFDocument.load(existingPdfBytes)
            const helveticaFont = await pdfDoc.embedFont(window.PDFLib.StandardFonts.Helvetica)

            const pages = pdfDoc.getPages()
            var userEmail = this.$store.state.user_email;
            pages.forEach ((page) => {
                page.drawText(userEmail + " " + userEmail, {
                    x: 350,
                    y: page.getSize().height / 2 + 300,
                    size: 50,
                    font: helveticaFont,
                    color: window.PDFLib.rgb(0.3, 0.3, 0.3),
                    rotate: window.PDFLib.degrees(-45),
                    opacity: 0.1
                })
                page.drawText(userEmail + " " + userEmail, {
                    x: 10,
                    y: page.getSize().height / 2 + 300,
                    size: 50,
                    font: helveticaFont,
                    color: window.PDFLib.rgb(0.3, 0.3, 0.3),
                    rotate: window.PDFLib.degrees(-45),
                    opacity: 0.1
                })
                page.drawText(userEmail + " " + userEmail, {
                    x: 0,
                    y: page.getSize().height / 2,
                    size: 50,
                    font: helveticaFont,
                    color: window.PDFLib.rgb(0.3, 0.3, 0.3),
                    rotate: window.PDFLib.degrees(-45),
                    opacity: 0.1
                })
            })
            const pdfBytes = await pdfDoc.save()
            console.log(pdfBytes);
            var blobUrl = URL.createObjectURL(new Blob([new Uint8Array(pdfBytes)], { type: 'application/pdf' }));
            window.open(blobUrl, '_blank');
        }
    },
    watch: {
        '$store.state.inputs.pal_table_vsp': {
            handler: function () {
                this.$store.commit('setJobNumbersChanged',true);
            }, deep: true
        },
        '$store.state.inputs.pal_table_eyemed': {
            handler: function () {
                this.$store.commit('setJobNumbersChanged',true);
            }, deep: true
        },
        '$store.state.inputs.ng_table_eyemed': {
            handler: function () {
                this.$store.commit('setJobNumbersChanged',true);
            }, deep: true
        },
        '$store.state.inputs.pal_table_davis': {
            handler: function () {
                this.$store.commit('setJobNumbersChanged',true);
            }, deep: true
        },
        '$store.state.inputs.ng_table_davis': {
            handler: function () {
                this.$store.commit('setJobNumbersChanged',true);
            }, deep: true
        },
        '$store.state.inputs.pal_table_spectera': {
            handler: function () {
                this.$store.commit('setJobNumbersChanged',true);
            }, deep: true
        },
        '$store.state.inputs.ng_table_spectera': {
            handler: function () {
                this.$store.commit('setJobNumbersChanged',true);
            }, deep: true
        },
    }
}
</script>

<style scoped>
    .section-header {
        position: relative;
    }
    .input-headers {
        margin-bottom: 20px;
        display: flex;
        align-items: flex-end;
    }
    .label-header {
        font-size: 16px;
        width: 440px;
        margin-right: 169px;
    }
    .number-header {
        margin-right: 32px;
    }
    .price-header {
        width: 62px;
        text-align: center;
    }
    .input-group {
        width: 691px;
        height: 60px;
        margin-bottom: 5px;
    }
    .input-group .label {
        width: 586px;
        height: 100%;
        display: flex;
        align-items: center;
        font-weight: bold;
        background-color: #F7F7F7;
        margin-right: 5px;
    }
    .input-group .label .title {
        margin-left: 30px;
    }
    .input-group .number-input {
        width: 100px;
        height: 100%;
        display: flex;
        margin-right: 0;
        justify-content: center;
        align-items: center;
        background-color: #F7F7F7;
    }
    .summary .label {
        background-color: transparent;
    }
    .summary .number-input {
        background-color: transparent;
    }
    .summary .number-input input[type=number] {        
        background-color: #F3F3F3;
        border-color: #E4E4E4;
    }
    .subsection {
        margin-bottom: 40px;
    }
    .subsection.longer {
        width: 691px;
    }
    .subsection.longer .input-headers, .subsection.longer .input-group {
        width: 781px;
    }
    .subsection.longer .number-input {
        width: 95px;
    }
    .note {
        position: relative;
        bottom: auto;
        width: 758px;
        margin-top: 20px;
    }
    .note a {
        width: 142px;
        height: 40px;
        color: black;
        text-decoration: none;
        font-weight: bold;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #E4E4E4;
        border-radius: 2px;
        position: absolute;
        right: 20px;
        cursor: pointer;
    }
    .note a:hover {
        border-color: rgb(189, 189, 189);
        background-color: rgb(243, 243, 243);
    }
    .toggle {
        position: absolute;
        right: 10px;
        bottom: 42px;
        background: transparent;
        color: black;
        font-weight: bold;
        border: none;
        text-decoration: underline;
        height: 32px;
        padding: 0;
        margin: 0;
        cursor: pointer;
        font-size: 12px;
    }
    .toggle:hover {
        opacity:0.8;
    }
    .toggle span {
        height: 100%;
        padding-right: 38px;
        align-items: center;
        pointer-events: none;
    }
    .toggle .show {
        background: url(../assets/show.png) no-repeat right 1px center;
        display: flex;
    }
    .toggle .hide {
        background: url(../assets/hide.png) no-repeat right 1px center;
        display: none;
    }
    .toggle.open .show {
        display: none;
    }
    .toggle.open .hide {
        display: flex;
    }
    .section-content {
        height: auto;
        width: 100%;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-in-out;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .section-content.open {
        max-height: 1000px;
    }
</style>
