import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'
import Home from '../views/Main.vue'
import Login from '../views/Login.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
        hideForAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!sessionStorage.getItem('token') && !sessionStorage.getItem('userId')) {
      next({ name: 'Login' })
    } else {
      console.log("authorizing...");
      var xmlhttp = new XMLHttpRequest();
      var response;
      xmlhttp.onreadystatechange = function() {
        if (xmlhttp.readyState == XMLHttpRequest.DONE) {   // XMLHttpRequest.DONE == 4          
          if (xmlhttp.status == 200) {
            response = JSON.parse(xmlhttp.responseText);
            if (response.success == true) {
              console.log("authorization success");
              store.commit('login', response.email);
              next();
            }
          } else {
            console.log("authorization failed");
            sessionStorage.removeItem('userId');
            sessionStorage.removeItem('token');
            next({ name: 'Login' })
          }
        } else if (xmlhttp.status >= 400){
          console.log("Authorization error: " + xmlhttp.status);
          sessionStorage.removeItem('userId');
          sessionStorage.removeItem('token');
          next({ name: 'Login' })
          xmlhttp.abort();
        }
      }; 
      xmlhttp.open("POST", "https://api.klprofitabilitycalculator.com/api/check");
			xmlhttp.setRequestHeader('Authorization', 'Bearer ' + sessionStorage.getItem('token'));
      xmlhttp.setRequestHeader("Content-type", "application/json");
      var data = JSON.stringify({"id": sessionStorage.getItem('userId')});
      xmlhttp.send(data);
    }
  } else if (to.matched.some(record => record.meta.hideForAuth)) {
    if (sessionStorage.getItem('token') && sessionStorage.getItem('userId')) {
      next({ name: 'Home' });
    } else {
      next();
    }
  } else {
      next();
  }
})

export default router