export default {
  methods: {
    setActiveSection: function(section) {
				var sections = document.getElementsByClassName('section-number');
				for (var i = 0; i < sections.length; i++) {
						sections[i].classList.remove('active');
				}
				section.classList.add('active');
		}
  },
  mounted() {
		if (document.getElementsByClassName('section-number').length > 0){
			document.getElementsByClassName('section-number')[0].classList.add("active");
		}
  }
}