const defaultInputs = {
    // Questions
    practice_name: "",
    pct_mvc: 70,
    business_pct_mvc: { vsp: 20, eyemed: 20, davis: 20, spectera: 20, other: 20 },
    pct_is_locked: { vsp: false, eyemed: false, davis: false, spectera: false, other: false },
    job_pct_vsp: { choice: 80, signature: 20 },
    job_pct_eyemed: { access: 70, insight: 30 },
    job_pct_versant: { davis: 70, superior: 30 },
    profit_pct_spectera: 10,
    // Totals of each lens type per month
    monthly_progressives: 0,
    monthly_singlevision: 0,
    monthly_bfft: 0,
    monthly_pct_ng: 50,
    // PALS and no-glare
    pal_table_vsp: { cat_n_jobs: 0, cat_o_jobs: 0, cat_f_jobs: 0, cat_j_jobs: 0, cat_k_jobs: 0 },
    ng_table_vsp: { cat_d_jobs: 0, cat_c_jobs: 0, cat_b_jobs: 0, cat_a_jobs: 0 },
    pal_table_eyemed: {
        tier_4_jobs: 0, tier_4_price: 425.00, tier_3_jobs: 0, tier_3_price: 375.00, tier_2_jobs: 0,
        tier_2_price: 299.00, tier_1_jobs: 0, tier_1_price: 235.00, standard_jobs: 0, standard_price: 209.00
    },
    ng_table_eyemed: {
        lab_h_jobs: 0, lab_h_price: 150.00, lab_k_jobs: 0, lab_k_price: 125.00, tier_2_jobs: 0,
        tier_2_price: 100.00, tier_1_jobs: 0, tier_1_price: 85.00, standard_jobs: 0, standard_price: 40.00
    },
    pal_table_davis: { ultimate_jobs: 0, ultra_jobs: 0, premium_jobs: 0, standard_jobs: 0 },
    ng_table_davis: { ultimate_jobs: 0, ultra_jobs: 0, premium_jobs: 0, standard_jobs: 0 },
    pal_table_spectera: { tier_5_jobs: 0, tier_4_jobs: 0, tier_3_jobs: 0, tier_2_jobs: 0, tier_1_jobs: 0 },
    ng_table_spectera: { tier_4_jobs: 0, tier_3_jobs: 0, tier_2_jobs: 0, tier_1_jobs: 0 },
    // Buying groups
    buying_groups: "none",
    //New total jobs per month
    new_monthly_jobs: {
        vsp_choice: {
            pal_cat_n: null, pal_cat_o: null, pal_cat_f: null, pal_cat_j: null, pal_cat_k: null,
            ng_cat_d_back: null, ng_cat_d: null, ng_cat_c_back: null, ng_cat_c: null, ng_cat_b: null, ng_cat_a: null
        },
        vsp_signature: {
            pal_cat_n: null, pal_cat_o: null, pal_cat_f: null, pal_cat_j: null, pal_cat_k: null,
            ng_cat_d_back: null, ng_cat_d: null, ng_cat_c_back: null, ng_cat_c: null, ng_cat_b: null, ng_cat_a: null
        },
        eyemed_access: {
            pal_tier_4: null, pal_tier_3: null, pal_tier_2: null, pal_tier_1: null, pal_tier_standard: null,
            ng_lab_h: null, ng_lab_k: null, ng_tier_2_back: null, ng_tier_2: null, ng_tier_1_back: null, ng_tier_1: null, ng_tier_standard: null
        },
        eyemed_insight: {
            pal_tier_4: null, pal_tier_3: null, pal_tier_2: null, pal_tier_1: null, pal_tier_standard: null,
            ng_lab_h: null, ng_lab_k: null, ng_tier_2_back: null, ng_tier_2: null, ng_tier_1_back: null, ng_tier_1: null, ng_tier_standard: null
        },
        davis: {
            pal_cat_ultimate: null, pal_cat_ultra: null, pal_cat_premium: null, pal_cat_standard: null,
            ng_cat_ultimate: null, ng_cat_ultra: null, ng_cat_premium: null, ng_cat_standard: null
        },
        superior: {
            pal_cat_ultimate: null, pal_cat_ultra: null, pal_cat_premium: null, pal_cat_standard: null,
            ng_cat_ultimate: null, ng_cat_ultra: null, ng_cat_premium: null, ng_cat_standard: null
        },
        spectera: {
            pal_tier_5: null, pal_tier_4: null, pal_tier_3: null, pal_tier_2: null, pal_tier_1: null,
            ng_tier_4: null, ng_tier_3: null, ng_tier_2: null, ng_tier_1: null
        }
    },
    rebates: {
        vsp : {},
        eyemed : {},
        davis: {},
        spectera: {}
    },
    jobNumbersChanged : true
}
export default defaultInputs