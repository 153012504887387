<template>
	<div class="progress" v-bind:class="{end: isEnd, completed: (stepNum > thisStep - 1), current: (stepNum == thisStep)}">
		<div class="progress-title" v-if="(stepNum == thisStep)"><span v-html="stepTitle"></span></div>
	</div>
</template>

<script>

export default {
    name: 'ProgressStep',
    props: {
      stepNum: Number,
			thisStep: Number,
      stepTitle: String,
			isEnd: Boolean
    }
}
</script>

<style scoped>
	.progress {
		width: 92px;
		height: 16px;
		background: url(../assets/progress_grey.svg) no-repeat center center;
		position: relative;
	}
	.progress.end {
		width: 16px;
		background-image: url(../assets/progress_end.svg);
	}
	.progress.completed {
		background-image: url(../assets/progress_completed.svg);
	}
	.progress.current::after {
		content: "\00a0";
		display: block;
		position: absolute;
		left: -2px;
		top: -2px;
		width: 20px;
		height: 25px;
		background: url(../assets/progress_current.svg) no-repeat center center;
		background-size: contain;
	}
	.progress-title {
		position: relative;
		bottom: -30px;
		width: 16px;
		display: flex;
		justify-content: center;
	}
	.progress-title span {
		font-size: 16px;
		white-space: nowrap;
	}
</style>
