<template>
  <div class="step">
    <Header 
      :stepNum="5"
      stepTitle="<b>Q5:</b> EyeMed"
    />
    <div class="main-content">
        <h2>What percentage of your EyeMed jobs are:</h2>
        <div class="input-headers">
                <div class="pct-header">Percentage (%)</div>
        </div>
        <div class="input-group slider">
            <div class="label">Access / Select</div>
            <RangeInput v-model.number="inputs.job_pct_eyemed.access" @input="rebalanceInputs('access')"/>
            <div class="number-input"><input v-model.number.lazy="inputs.job_pct_eyemed.access" type="number" min="0" max="100" step="5" @input="cleanSliderValue($event)" @change="rebalanceInputs('access')"/></div>
        </div>
        <div class="input-group slider end">
            <div class="label">Insight</div>
            <RangeInput v-model.number="inputs.job_pct_eyemed.insight" @input="rebalanceInputs('insight')"/>
            <div class="number-input"><input v-model.number.lazy="inputs.job_pct_eyemed.insight" type="number" min="0" max="100" step="5" @input="cleanSliderValue($event)" @change="rebalanceInputs('insight')"/></div>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import RangeInput from '@/components/RangeInput.vue'
import {mapState} from 'vuex'

export default {
	name: 'Step5',
	components: {
			Header,
			RangeInput
	},
  methods: {
    rebalanceInputs: function(brandEdited) {
      var accessVal = {
        'val' : this.inputs.job_pct_eyemed.access
      }
      var insightVal = {
        'val' : this.inputs.job_pct_eyemed.insight
      }
      var overage = {
        'val' : 0
      }
      var valueTotal = (accessVal.val + insightVal.val);
      overage.val = valueTotal - 100;
      this.rebalanceInput('access',brandEdited,this.inputs.job_pct_eyemed.access,accessVal,overage);
      this.rebalanceInput('insight',brandEdited,this.inputs.job_pct_eyemed.insight,insightVal,overage);
      this.$store.commit('rebalanceEyemedPct',[accessVal.val,insightVal.val])
    },
    rebalanceInput: function(tgtBrand,brandEdited,brandPct,valRef,overageRef) {
      if (brandEdited != tgtBrand) {
        valRef.val -= overageRef.val;
        overageRef.val = 0;
      }
    }
  },
	computed: {
			...mapState(['inputs'])
	},
  mounted: function () {
    window.scrollTo(0, 0)
  },
  watch: {
    '$store.state.inputs.job_pct_eyemed.access': function() {
      this.$store.commit('setJobNumbersChanged',true);
    }
  }
}
</script>

<style scoped>
	.input-group {
		width: 720px;
	}
	.input-headers::before {
		content: "\00a0";
		width: 767px;
	}
</style>